import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  UploaderOptions,
  UploadFile,
  UploadInput,
  UploadOutput,
} from 'ngx-uploader';
import { AuthService } from 'src/app/core/services/auth.service';
import { MediaType } from 'src/app/core/types/types';
import { TranslateService } from '@ngx-translate/core';
import { getToken, UserState } from 'src/app/login/state/user.reducers';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cm-media-library-upload',
  templateUrl: './media-library-upload.component.html',
  styleUrls: ['./media-library-upload.component.scss'],
})
export class MediaLibraryUploadComponent implements OnInit, OnChanges {
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  visibility: string = 'private';
  statusMessage: string = '';

  private translateStrings: any;
  private maxImageFileSize = 10000000;
  private maxVideoFileSize = 100000000;

  uploadOptions = {
    private: 'true',
    owner: null,
  };
  allowedImageExtensions = ['image/jpg', 'image/png', 'image/jpeg'];
  allowedVideoExtensions = [
    'video/mp4',
    'video/avi',
    'video/mkv',
    'video/x-m4v',
    'video/flv',
    'video/quicktime',
  ];

  @Input() mediaType: string;
  @Output() filesAdded: EventEmitter<UploadOutput> = new EventEmitter();
  @Output() progress: EventEmitter<UploadOutput> = new EventEmitter();
  @Output() complete: EventEmitter<UploadOutput> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private store: Store<UserState>
  ) {
    this.uploadInput = new EventEmitter<UploadInput>();
    this.files = [];
    this.options = {
      concurrency: 1,
      maxUploads: 3,
      maxFileSize: this.maxImageFileSize,
      allowedContentTypes: [...this.allowedImageExtensions],
    };

    const tokens = ['COMPONENTS.MEDIA_LIBRARY.UPLOAD.ERROR_MESSAGE'];
    this.translate
      .get(tokens)
      .subscribe((strings) => (this.translateStrings = strings));
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mediaType && changes.mediaType.currentValue) {
      this.mediaType = changes.mediaType.currentValue;
      if (this.mediaType === MediaType.PHOTOS) {
        this.options = {
          ...this.options,
          allowedContentTypes: this.allowedImageExtensions,
          maxFileSize: this.maxImageFileSize,
        };
      } else {
        this.options = {
          ...this.options,
          allowedContentTypes: this.allowedVideoExtensions,
          maxFileSize: this.maxVideoFileSize,
        };
      }
    }
  }

  buildUrl() {
    let uploadUrl = '';
    if (this.mediaType === MediaType.PHOTOS) {
      uploadUrl = environment.apiUrl + '/message-image';
    } else {
      uploadUrl = environment.apiUrl + '/message-video';
    }
    uploadUrl += this.uploadOptions.owner
      ? '?owner=' + this.uploadOptions.owner.id
      : '';
    return uploadUrl;
  }

  onUploadOutput(output: UploadOutput): void {
    let token: string;
    this.store
      .select(getToken)
      .pipe(take(1))
      .subscribe((t) => (token = t));

    switch (output.type) {
      case 'allAddedToQueue':
        const event: UploadInput = {
          type: 'uploadAll',
          url: this.buildUrl(),
          fieldName: this.mediaType === MediaType.PHOTOS ? 'image' : 'video',
          method: 'POST',
          data: { caption: 'upload', is_private: this.uploadOptions.private },
          headers: { Authorization: `Bearer ${token}` },
        };
        this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
        }
        this.filesAdded.emit(output);
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex(
            (file) =>
              typeof output.file !== 'undefined' && file.id === output.file.id
          );
          this.files[index] = output.file;
          //this.progress = output.file.progress.data.percentage / 100;
          this.progress.emit(output);
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter(
          (file: UploadFile) => file !== output.file
        );
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'rejected':
        console.log('file rejected!');
        this.statusMessage =
          this.translateStrings[
            'COMPONENTS.MEDIA_LIBRARY.UPLOAD.ERROR_MESSAGE'
          ];
        break;
      case 'done':
        console.info('upload done!');
        this.files = [];
        this.complete.emit(output);
        break;
    }
  }

  onVisibilityChanged() {
    if (this.visibility === 'private') {
      this.uploadOptions.private = 'true';
    } else {
      this.uploadOptions.private = 'false';
    }
  }
}
