import { createAction, props } from '@ngrx/store';
import { Resident } from '../../../core/models/resident.model';
import { Hardware } from '../../../core/models/hardware.model';

export const reload = createAction(
  '[Hardware] Reload',
  props<{ resident: Resident; force: boolean }>()
);
export const reset = createAction(
  '[Hardware] Reset',
  props<{ resident: Resident; force: boolean }>()
);
export const standby = createAction(
  '[Hardware] Standby',
  props<{ resident: Resident; force: boolean }>()
);
export const reboot = createAction(
  '[Hardware] Reboot',
  props<{ resident: Resident; force: boolean }>()
);

export const powerStandby = createAction(
  '[Hardware][Power] Standby',
  props<{ resident: Resident; force: boolean }>()
);

export const powerNormal = createAction(
  '[Hardware][Power] Normal',
  props<{ resident: Resident; force: boolean }>()
);

export const powerWarm = createAction(
  '[Hardware][Power] Warm',
  props<{ resident: Resident; force: boolean }>()
);

export const updateHardware = createAction(
  '[Hardware] Updated',
  props<{ hardware: Hardware }>()
);
