import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Resident } from '../models/resident.model';
import { AuthService } from './auth.service';
import { ApiResponse } from '../models/api-response';
import {
  getCurrentCustomer,
  UserState,
} from 'src/app/login/state/user.reducers';
import { Customer } from '../models/customer.model';
import { Store } from '@ngrx/store';
import { ResidentMetric } from '../../manager/residents/state/resident.reducers';

@Injectable()
export class ResidentService {
  endpointUrl: string = environment.apiUrl + '/resident';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private store$: Store<UserState>
  ) {}

  getResidents(params: HttpParams): Observable<Resident[]> {
    return this.http
      .get(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getResidentsForSite(siteId) {
    let customer: Customer;
    this.store$
      .select(getCurrentCustomer)
      .pipe(take(1))
      .subscribe((c: Customer) => (customer = c));

    let params = new HttpParams();
    params = params.set('customer', customer.id);
    params = params.set('site', siteId.toString());
    params = params.set('ordering', 'last_name');
    params = params.set('limit', '0');
    params = params.set('page_size', 1000);
    return this.http
      .get<ApiResponse<Resident>>(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getResidentsForCustomer(customerId: Number) {
    let customer: Customer;
    this.store$
      .select(getCurrentCustomer)
      .pipe(take(1))
      .subscribe((c: Customer) => (customer = c));

    let params = new HttpParams();
    params = params.set('customer', customer.id);
    params = params.set('ordering', 'last_name');
    params = params.set('page_size', 1000);
    return this.http
      .get<ApiResponse<Resident>>(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getResident(residentId) {
    return this.http.get<Resident>(this.endpointUrl + '/' + residentId);
  }

  updateResident(resident: Resident) {
    return this.http.patch(`${this.endpointUrl}/${resident.id}`, resident);
  }

  deleteResident(resident: Resident) {
    const url = `${this.endpointUrl}/${resident.id}`;
    return this.http.delete(url);
  }

  getOnlineResidents(customer: Customer): Observable<Resident[]> {
    let params = new HttpParams();
    params = params.set('online', 'true');
    params = params.set('customer', customer.id);
    return this.http
      .get(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getMetricsForResident(
    residentId: number,
    startDate: Date,
    endDate: Date
  ): Observable<ResidentMetric> {
    let params = new HttpParams();
    params = params.set('start_date', startDate.toISOString());
    params = params.set('end_date', endDate.toISOString());
    return this.http
      .get<ResidentMetric>(`${this.endpointUrl}/${residentId}/metrics`, {
        params,
      })
      .pipe(catchError(this.handleError));
  }

  createResident(resident: Resident) {
    return this.http.post(this.endpointUrl, resident);
  }

  getPinCode(id: number) {
    let url = `${this.endpointUrl}/${id}/get_pin_code`;
    return this.http.get(url);
  }

  setPassword(id: number, password: string) {
    let url = `${this.endpointUrl}/${id}/set_password`;
    return this.http.patch(url, { password });
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
