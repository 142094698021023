import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '../services/auth.service';
import * as fromUser from '../../login/state/user.reducers';
import * as UserActions from '../../login/state/user.actions';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private store$: Store
  ) {}

  canActivate(): Observable<boolean> {
    return this.store$.select(fromUser.getToken).pipe(
      map((token) => {
        if (
          token === undefined ||
          token === '' ||
          this.auth.isTokenExpired(token)
        ) {
          this.store$.dispatch(UserActions.loginRedirect());
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
