import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as StaffActions from './staff.actions';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Staff } from '../../../core/models/staff.model';
import { StaffService } from '../../../core/services/staff.service';
import { NotificationService } from '../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class StaffEffects {
  translateStrings: any;
  prefix = 'SUPERUSER.STAFF.NOTIFICATIONS.';

  constructor(
    private actions$: Actions,
    private staffService: StaffService,
    private router: Router,
    private store$: Store,
    private notification: NotificationService,
    private translate: TranslateService
  ) {
    const tokens = [
      `${this.prefix}CREATED_SUCCESS`,
      `${this.prefix}UPDATED_SUCCESS`,
      `${this.prefix}DELETED_SUCCESS`,
      `${this.prefix}PASSWORD_RESET_SUCCESS`,
    ];
    setTimeout(() => {
      this.translate.get(tokens).subscribe((translations) => {
        this.translateStrings = translations;
      });
    }, 1000);
  }

  getStaffs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffActions.getStaffs),
      concatMap((action) =>
        this.staffService.getStaffs().pipe(
          map((staffs: Staff[]) => {
            return StaffActions.getStaffsSuccess({ staffs });
          }),
          catchError((error) => of(StaffActions.getStaffsFailure({ error })))
        )
      )
    )
  );

  createStaff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffActions.createStaff),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.staffService.createStaff(action.staff).pipe(
          map((staff: Staff) => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.prefix}CREATED_SUCCESS`]
            );
            return StaffActions.createStaffSuccess({ staff });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(StaffActions.createStaffFailure({ error }));
          })
        );
      })
    )
  );

  updateStaff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffActions.updateStaff),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.staffService.updateStaff(action.staff).pipe(
          map(() => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.prefix}UPDATED_SUCCESS`]
            );
            return StaffActions.updateStaffSuccess({
              staff: action.staff,
            });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(StaffActions.updateStaffFailure({ error }));
          })
        );
      })
    )
  );

  deleteStaff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffActions.deleteStaff),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.staffService.deleteStaff(action.staff).pipe(
          map(() => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.prefix}DELETED_SUCCESS`]
            );
            return StaffActions.deleteStaffSuccess({
              staff: action.staff,
            });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(StaffActions.deleteStaffFailure({ error }));
          })
        );
      })
    )
  );

  createUpdateStaffSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          StaffActions.createStaffSuccess,
          StaffActions.updateStaffSuccess
        ),
        tap((action) => this.router.navigate(['/superuser/staff']))
      ),
    { dispatch: false }
  );

  refreshStaff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffActions.refreshStaff),
      exhaustMap((action) =>
        this.staffService.getStaff(action.id).pipe(
          map((staff: Staff) => {
            return StaffActions.refreshStaffSuccess({ staff });
          }),
          catchError((error) => of(StaffActions.refreshStaffFailure({ error })))
        )
      )
    )
  );

  setStaffPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaffActions.setStaffPassword),
      exhaustMap((action) => {
        this.notification.showProgressDialog();
        return this.staffService.setStaffPassword(action.staff).pipe(
          map((staff: Staff) => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.prefix}PASSWORD_RESET_SUCCESS`]
            );
            return StaffActions.setStaffPasswordSuccess();
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(StaffActions.setStaffPasswordFailure({ error }));
          })
        );
      })
    )
  );
}
