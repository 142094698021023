<div id="media-library-video-upload">
  <!-- <mat-progress-bar
    mode="determinate"
    [value]="output.file.progress.data.percentage"
  ></mat-progress-bar> -->
  <mat-progress-spinner
    mode="determinate"
    [value]="output.file.progress.data.percentage"
  ></mat-progress-spinner>
  <div id="encoding-message">{{ output.file.progress.data.etaHuman }}</div>
</div>
