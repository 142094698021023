import {Component, OnInit} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PreviousRouteService } from './core/services/previous-route.service';

@Component({
  selector: 'app-cm-manager',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isDarkTheme = false;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private previousRouteService: PreviousRouteService
  ) {
    this.iconRegistry.addSvgIconSetInNamespace(
      'cm',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/cm-icons.svg'
      )
    );
  }

  ngOnInit(): void {
  }
}
