import { createAction, props } from '@ngrx/store';
import { Staff } from '../../../core/models/staff.model';

export const getStaffs = createAction('[Staff] Get Staffs');

export const getStaffsSuccess = createAction(
  '[Staff] Get Staffs Success',
  props<{ staffs: Staff[] }>()
);

export const getStaffsFailure = createAction(
  '[Staff] Get Staffs Failure',
  props<{ error: any }>()
);

export const createStaff = createAction(
  '[Staff] Create Staff',
  props<{ staff: Staff }>()
);

export const createStaffSuccess = createAction(
  '[Staff] Create Staff Success',
  props<{ staff: Staff }>()
);

export const createStaffFailure = createAction(
  '[Staff] Create Staff Failure',
  props<{ error: Error }>()
);

export const updateStaff = createAction(
  '[Staff] Update Staff',
  props<{ staff: Staff }>()
);

export const updateStaffSuccess = createAction(
  '[Staff] Update Staff Success',
  props<{ staff: Staff }>()
);

export const updateStaffFailure = createAction(
  '[Staff] Update Staff Failure',
  props<{ error: Error }>()
);

export const deleteStaff = createAction(
  '[Staff] Delete Staff',
  props<{ staff: Staff }>()
);

export const deleteStaffSuccess = createAction(
  '[Staff] Delete Staff Success',
  props<{ staff: Staff }>()
);

export const deleteStaffFailure = createAction(
  '[Staff] Delete Staff Failure',
  props<{ error: Error }>()
);

export const refreshStaff = createAction(
  '[Staff] Refresh Staff',
  props<{ id: number }>()
);

export const refreshStaffSuccess = createAction(
  '[Staff] Refresh Staff Success',
  props<{ staff: Staff }>()
);

export const refreshStaffFailure = createAction(
  '[Staff] Refresh Staff Failure',
  props<{ error: Error }>()
);

export const setStaffPassword = createAction(
  '[Staff] Set Staff Password',
  props<{ staff: Staff }>()
);

export const setStaffPasswordSuccess = createAction(
  '[Staff] Set Staff Password Success'
);

export const setStaffPasswordFailure = createAction(
  '[Staff] Set Staff Password Failure',
  props<{ error: any }>()
);
