import { MessageImage } from '../models/message-image.model';
import { MessageVideo } from '../models/message-video.model';

/**
 * Permits a type T to be null.
 */
export type Nullable<T> = T | null;

export enum MediaType {
  PHOTOS = 'photos',
  VIDEOS = 'videos',
}

export type ImageOrVideo = MessageImage | MessageVideo | number;
