import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressDialogComponent } from '../../shared/components/progress-dialog/progress-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  snackBarConfig: MatSnackBarConfig = { duration: 2000 };

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  showSnackBar(
    message: string,
    action: string = 'Close',
    config: MatSnackBarConfig = this.snackBarConfig
  ): void {
    this.snackBar.open(message, action, config);
  }

  showProgressDialog(): MatDialogRef<any> {
    return this.dialog.open(ProgressDialogComponent);
  }

  closeProgressDialog(): void {
    this.dialog.closeAll();
  }
}
