import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  residentFeatureKey,
  ResidentMetric,
  ResidentState,
  ResidentSupporterMap,
} from './resident.reducers';
import { Resident } from '../../../core/models/resident.model';
import { Site } from '../../../core/models/site.model';
import { SiteMetric } from '../../sites/state/site.reducers';

// Selector functions
const getResidentFeatureState =
  createFeatureSelector<ResidentState>(residentFeatureKey);

export const selectResidents = createSelector(
  getResidentFeatureState,
  (state) => state.residents
);

export const getResident = (id: number) =>
  createSelector(getResidentFeatureState, (state) => {
    return state.residents.find((resident) => resident.id === id);
  });

export const selectResidentsForSite = (siteId: number) =>
  createSelector(getResidentFeatureState, (state) => {
    return state.residents.filter(
      (resident: any) => resident.site.id === siteId
    );
  });

export const residentSupportersLoaded = (residentId: number) =>
  createSelector(getResidentFeatureState, (state) => {
    const residentSuppporterMap = state.supporters.find(
      (residentSupporterMap) => residentSupporterMap.id === residentId
    );
    return (
      residentSuppporterMap !== undefined &&
      residentSuppporterMap.supporters !== undefined
    );
  });

export const selectSupportersForResident = (id: number) =>
  createSelector(getResidentFeatureState, (state) => {
    return state.supporters.find(
      (residentSupporterMap: ResidentSupporterMap) =>
        residentSupporterMap.id === id
    );
  });

export const selectResidentMetric = (id: number) =>
  createSelector(getResidentFeatureState, (state) => {
    return state.residentMetrics.find(
      (residentMetric: ResidentMetric) => residentMetric.resident_id === id
    );
  });
