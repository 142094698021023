import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { PresenceState } from 'src/app/state/presence.reducer';
import * as fromPresence from '../../../state/presence.reducer';

@Component({
  selector: 'app-avatar-thumbnail',
  templateUrl: './avatar-thumbnail.component.html',
  styleUrls: ['./avatar-thumbnail.component.scss'],
})
export class AvatarThumbnailComponent implements OnInit {
  @Input() user;
  @Input() size = 20;
  @Input() hidePresence = false;
  @Input() includeMdList = true;

  @Output() clicked = new EventEmitter();

  presence$;

  constructor(private store: Store<PresenceState>) {}

  ngOnInit(): void {
    this.presence$ = this.store.select(
      fromPresence.isCustomerUserOnline(this.user)
    );
  }

  onClicked(): void {
    this.clicked.emit(this.user);
  }
}
