<div>
  <button
    mat-icon-button
    color="primary"
    *ngIf="!isVisible"
    (click)="showPincode()"
  >
    <mat-icon>lock</mat-icon>
  </button>
  <span class="pincode" *ngIf="isVisible">{{ pincode }}</span>
  <button
    mat-stroked-button
    color="accent"
    *ngIf="isVisible"
    (click)="hidePincode()"
  >
    {{ "COMPONENTS.PINCODE.HIDE" | translate }}
  </button>
</div>
