import { LoggerService } from './logger.service';
import { Customer } from './../models/customer.model';
import { Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable, Subject, of, timer, interval, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';

import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Site } from '../models/site.model';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@sentry/types';
import { LoginResponse } from 'src/app/login/state/user.reducers';

export class AuthServiceConfig {
  enabled: boolean = false;
}

@Injectable()
export class AuthService {
  private CUSTOMER_URL: string = environment.apiUrl + '/customer';
  private SITE_URL: string = environment.apiUrl + '/site';

  private loggedInSource = new Subject();
  private loggedOutSource = new Subject();

  loggedIn$ = this.loggedInSource.asObservable();
  loggedOut$ = this.loggedOutSource.asObservable();

  constructor(
    @Optional() config: AuthServiceConfig,
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private logger: LoggerService,
    private localStorage: LocalStorageService,
    private translate: TranslateService
  ) {}

  public isTokenExpired(token: string) {
    return this.jwtHelper.isTokenExpired(token);
  }

  public setSuperUserCustomer(customer: Customer) {
    //this.user.customer = customer.id;
    this.localStorage.store('customer', JSON.stringify(customer));
  }

  // public scheduleRefresh() {
  //   // If the user is authenticated, flatMap the token
  //   let source = of(this.idToken).pipe(
  //     flatMap((token) => {
  //       // The delay to generate in this case is the difference
  //       // between the expiry time and the issued at time
  //       let jwtIat = this.jwtHelper.decodeToken(token).orig_iat;
  //       let jwtExp = this.jwtHelper.decodeToken(token).exp;
  //       let iat = new Date(0);
  //       let exp = new Date(0);

  //       let delay = (exp.setUTCSeconds(jwtExp) - iat.setUTCSeconds(jwtIat)) / 2;
  //       console.log('scheduling refresh: ', delay);
  //       return interval(delay);
  //     })
  //   );
  //   this.refreshSubscription = source.subscribe(() => {
  //     this.getNewJwt();
  //   });
  // }

  // public startupTokenRefresh() {
  //   this.idToken = this.localStorage.retrieve('access_token');
  //   // If the user is authenticated, use the token stream
  //   // provided by angular2-jwt and flatMap the token
  //   if (this.loggedIn()) {
  //     let source = of(this.idToken).pipe(
  //       flatMap((token) => {
  //         // Get the expiry time to generate
  //         // a delay in milliseconds
  //         let now: number = new Date().valueOf();
  //         let jwtExp: number = this.jwtHelper.decodeToken(token).exp;
  //         let exp: Date = new Date(0);
  //         exp.setUTCSeconds(jwtExp);
  //         let delay: number = exp.valueOf() - now;

  //         console.info(`token refresh in ${delay / 1000} seconds`);
  //         // Use the delay in a timer to
  //         // run the refresh at the proper time
  //         return timer(delay);
  //       })
  //     );

  //     // Once the delay time from above is
  //     // reached, get a new JWT and schedule
  //     // additional refreshes
  //     source.subscribe(() => {
  //       this.getNewJwt();
  //       this.scheduleRefresh();
  //     });

  //     // Raise logged in
  //     this.loggedInSource.next();
  //   }
  // }

  // public getNewJwt() {
  //   // Get a new JWT using the token saved in local storage
  //   let url = environment.apiUrl.slice(0, -3) + '/api-token-refresh/';
  //   let headers = new HttpHeaders();
  //   this.localStorage.retrieve('access_token').then((token) => {
  //     headers.append('Content-Type', 'application/json');
  //     let body = JSON.stringify({ token: token });
  //     return this.http.post(url, body).pipe(
  //       map((res) => {
  //         const token = (res as any).token;
  //         this.localStorage.store('access_token', token);
  //         this.idToken = token;
  //         return res || {};
  //       }),
  //       catchError(this.handleError)
  //     );
  //   });
  // }

  public login(email: string, password: string) {
    let url = environment.apiUrl.slice(0, -3) + '/api-token-auth/';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    let body = JSON.stringify({ username: email, password: password });

    return this.http.post(url, body, { headers }).pipe(
      map(this.extractAndStoreToken, this),
      map((user) => {
        setTimeout(() => this.loggedInSource.next(), 2000);
        return user;
      }),
      catchError(this.handleError)
    );
  }

  getNewJwt(token: string) {
    // Get a new JWT using the token saved in local storage
    let url = environment.apiUrl.slice(0, -3) + '/api-token-refresh/';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    let body = JSON.stringify({ token });
    return this.http.post(url, body, { headers }).pipe(
      map((response: LoginResponse) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  private extractAndStoreToken(data: any) {
    // set language
    this.translate.use(data.locale);
    return data || {};
  }

  // private extractAndStoreRefreshToken(data: any) {
  //   this.localStorage.store('token', data.token);
  //   return data || {};
  // }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
