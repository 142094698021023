import { createAction, props } from '@ngrx/store';
import { Administrator } from 'src/app/core/models/administrator.model';
import { Customer } from 'src/app/core/models/customer.model';
import { Hardware } from 'src/app/core/models/hardware.model';
import { Resident } from 'src/app/core/models/resident.model';
import { Site } from 'src/app/core/models/site.model';
import { Credentials, LoginResponse } from './user.reducers';

/**
 * Actions
 *
 * [Auth] Login
 * [Auth] Login Success
 * [Auth] Login Failure
 * [Auth] Redirect
 */

export const login = createAction('[User] Login', props<Credentials>());

export const loginSuccess = createAction(
  '[User] Login Success',
  props<LoginResponse>()
);

export const loginFailure = createAction(
  '[User] Login Failure',
  props<{ error: any }>()
);

export const logout = createAction('[User] Logout');

export const loginRedirect = createAction('[User] Login Redirect');

export const getCustomer = createAction('[User] Get Customer', props<Number>());

export const getCustomerSuccess = createAction(
  '[User] Get Customer Success',
  props<Customer>()
);

export const getCustomerFailure = createAction(
  '[User] Get Customer Failure',
  props<{ error: any }>()
);

export const getCustomerPermissions = createAction(
  '[User] Get Customer Permissions',
  props<Number>()
);

export const getCustomerPermissionsSuccess = createAction(
  '[User] Get Customer Permissions Success',
  props<{ permissions: string[] }>()
);

export const getCustomerPermissionsFailure = createAction(
  '[User] Get Customer Permissions Failure',
  props<{ error: any }>()
);

export const getAdministratorsSuccess = createAction(
  '[User] Get Administrators Success',
  props<{ administrators: Administrator[] }>()
);

export const getAdministratorsFailure = createAction(
  '[User] Get Administrators Failure',
  props<{ error: any }>()
);

export const refreshToken = createAction('[User] Refresh Token');

export const refreshTokenSuccess = createAction(
  '[User] Refresh Token Success',
  props<{ token: string }>()
);

export const refreshTokenFailure = createAction(
  '[User] Refresh Token Failure',
  props<{ error: any }>()
);
