import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { CustomerAdminService } from '../core/services/customer-admin.service';
import { ResidentService } from '../core/services/resident.service';
import { SupporterService } from '../core/services/supporter.service';
import {
  getCurrentCustomer,
  getCurrentUser,
} from '../login/state/user.reducers';
import * as PresenceActions from './presence.actions';

@Injectable()
export class PresenceEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private customerAdminService: CustomerAdminService,
    private residentService: ResidentService,
    private supporterService: SupporterService
  ) {}

  getOnlineCustomerAdmins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresenceActions.getOnlineCustomerUsers),
      withLatestFrom(
        this.store$.select(getCurrentUser),
        this.store$.select(getCurrentCustomer)
      ),
      concatMap(([action, currentUser, currentCustomer]) => {
        return this.customerAdminService
          .getOnlineAdministrators(currentCustomer)
          .pipe(
            map((administrators) => {
              administrators = administrators.filter(
                (administrator) => administrator.id !== currentUser.id
              );
              return PresenceActions.getOnlineCustomerUsersSuccess({
                users: administrators,
              });
            }),
            catchError((error) =>
              of(PresenceActions.getOnlineCustomerUsersFailure())
            )
          );
      })
    )
  );

  getOnlineResidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresenceActions.getOnlineCustomerUsers),
      withLatestFrom(
        this.store$.select(getCurrentUser),
        this.store$.select(getCurrentCustomer)
      ),
      concatMap(([action, currentUser, currentCustomer]) => {
        return this.residentService.getOnlineResidents(currentCustomer).pipe(
          map((residents) => {
            residents = residents.filter(
              (resident) => resident.id !== currentUser.id
            );
            return PresenceActions.getOnlineCustomerUsersSuccess({
              users: residents,
            });
          }),
          catchError((error) =>
            of(PresenceActions.getOnlineCustomerUsersFailure())
          )
        );
      })
    )
  );

  getOnlineSupporters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresenceActions.getOnlineCustomerUsers),
      withLatestFrom(
        this.store$.select(getCurrentUser),
        this.store$.select(getCurrentCustomer)
      ),
      concatMap(([action, currentUser, currentCustomer]) => {
        return this.supporterService.getOnlineSupporters(currentCustomer).pipe(
          map((supporters) => {
            supporters = supporters.filter(
              (supporter) => supporter.id !== currentUser.id
            );
            return PresenceActions.getOnlineCustomerUsersSuccess({
              users: supporters,
            });
          }),
          catchError((error) =>
            of(PresenceActions.getOnlineCustomerUsersFailure())
          )
        );
      })
    )
  );
}
