import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'online'
})
export class OnlinePipe implements PipeTransform {
    transform(value: any, args: any[]): any {
        return value.filter((user) => user.online);
    }
}
