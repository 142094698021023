import { Component, Input, OnInit } from '@angular/core';
import { UploadOutput } from 'ngx-uploader';

@Component({
  selector: 'cm-media-library-image-upload',
  templateUrl: './media-library-image-upload.component.html',
  styleUrls: ['./media-library-image-upload.component.scss'],
})
export class MediaLibraryImageUploadComponent implements OnInit {
  constructor() {}

  @Input() output: UploadOutput;

  ngOnInit(): void {}
}
