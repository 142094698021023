import { Customer } from '../../../core/models/customer.model';
import { createReducer, on } from '@ngrx/store';
import * as CustomerActions from './customer.actions';
import * as ResidentActions from '../../../manager/residents/state/resident.actions';

export const customerFeatureKey = 'customers';

export interface CustomerState {
  customers: Customer[];
  createCustomerFailureMessage: string;
  updateCustomerFailureMessage: string;
  deleteCustomerFailureMessage: string;
  refreshCustomerFailureMessage: string;
}

const initialState: CustomerState = {
  customers: [],
  createCustomerFailureMessage: '',
  updateCustomerFailureMessage: '',
  deleteCustomerFailureMessage: '',
  refreshCustomerFailureMessage: '',
};

export const customerReducer = createReducer<CustomerState>(
  initialState,
  on(CustomerActions.getCustomersSuccess, (state, action) => ({
    ...state,
    customers: action.customers,
  })),
  on(CustomerActions.getCustomersFailure, (state) => ({
    ...state,
    failureMessage: 'Failed to get customers',
  })),
  on(CustomerActions.createCustomerSuccess, (state, action) => ({
    ...state,
    customers: [...state.customers, action.customer],
  })),
  on(CustomerActions.createCustomerFailure, (state, action) => ({
    ...state,
    createCustomerFailureMessage: action.error.message,
  })),
  on(CustomerActions.updateCustomerSuccess, (state, action) => {
    const idx = state.customers.findIndex(
      (customer) => customer.id === action.customer.id
    );
    const customers = [...state.customers];
    customers[idx] = action.customer;
    return { ...state, customers };
  }),
  on(CustomerActions.updateCustomerFailure, (state, action) => ({
    ...state,
    updateCustomerFailureMessage: action.error.message,
  })),
  on(CustomerActions.deleteCustomerSuccess, (state, action) => ({
    ...state,
    customers: [
      ...state.customers.filter(
        (customer) => customer.id !== action.customer.id
      ),
    ],
  })),
  on(CustomerActions.deleteCustomerFailure, (state, action) => ({
    ...state,
    deleteCustomerFailureMessage: action.error.message,
  })),
  on(CustomerActions.refreshCustomerSuccess, (state, action) => {
    const idx = state.customers.findIndex(
      (customer) => customer.id === action.customer.id
    );
    const customers = [...state.customers];
    customers[idx] = action.customer;
    return { ...state, customers };
  }),
  on(CustomerActions.refreshCustomerFailure, (state, action) => ({
    ...state,
    refreshResidentFailureMessage: action.error.message,
  }))
);
