<div id="media-library-container">
  <div id="header-container">
    <mat-radio-group
      aria-label="Select photos or videos"
      [(ngModel)]="state.mediaType"
      (change)="onMediaTypeChanged()"
      [disabled]="state.busy"
    >
      <mat-radio-button value="photos" color="primary">
        <!-- <mat-icon>video_library</mat-icon> -->
        {{ "COMPONENTS.MEDIA_LIBRARY.HEADER.PHOTOS_RADIO" | translate }}
      </mat-radio-button>
      <mat-radio-button value="videos" color="primary">
        <!-- <mat-icon>perm_media</mat-icon> -->
        {{ "COMPONENTS.MEDIA_LIBRARY.HEADER.VIDEOS_RADIO" | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <div id="selected-items-container">
      <button
        mat-button
        mat-flat-button
        color="warn"
        *ngIf="selectedItems.length > 0"
        (click)="onDeleteSelected()"
      >
        <mat-icon>delete_forever</mat-icon>
        {{ "COMPONENTS.MEDIA_LIBRARY.HEADER.DELETE_SELECTED" | translate }}
      </button>
      <div id="selected-total">
        {{ selectedItems?.length }}
      </div>
      <div id="selected-label">
        {{ "COMPONENTS.MEDIA_LIBRARY.HEADER.ITEMS_SELECTED" | translate }}
      </div>
    </div>
  </div>
  <div id="body-container">
    <div id="upload-container">
      <cm-media-library-upload
        [mediaType]="state.mediaType"
        (filesAdded)="onUploadFilesAdded($event)"
        (progress)="onUploadProgress($event)"
        (complete)="onUploadComplete($event)"
      ></cm-media-library-upload>
    </div>
    <div
      id="media-container"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      (scrolled)="getNextPage()"
    >
      <div>
        <cm-media-library-image-upload
          [output]="output"
          *ngFor="let output of imageUploadOutputs"
        ></cm-media-library-image-upload>

        <cm-media-library-image
          *ngFor="let image of images"
          [image]="image"
          (selected)="onImageSelected($event)"
          (unselected)="onImageUnselected($event)"
        ></cm-media-library-image>
      </div>

      <div>
        <cm-media-library-video-upload
          [output]="output"
          *ngFor="let output of videoUploadOutputs"
        ></cm-media-library-video-upload>
        <cm-media-library-video
          *ngFor="let video of videos"
          [video]="video"
          (selected)="onVideoSelected($event)"
          (unselected)="onVideoUnselected($event)"
        >
        </cm-media-library-video>
      </div>
    </div>
  </div>
  <div id="footer-container">
    <button mat-flat-button color="accent" (click)="cancel()">
      {{ "COMPONENTS.MEDIA_LIBRARY.BUTTONS.CANCEL" | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="addSelected()"
      [disabled]="selectedItems.length < 1"
    >
      {{ "COMPONENTS.MEDIA_LIBRARY.BUTTONS.ADD_SELECTED" | translate }}
    </button>
  </div>
</div>
