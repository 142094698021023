import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MessageVideo } from '../models/message-video.model';

@Injectable({
  providedIn: 'root',
})
export class MessageVideoService {
  endpointUrl: string = environment.apiUrl + '/message-video';

  constructor(private http: HttpClient) {}

  deleteVideo(image: MessageVideo) {
    return this.http
      .delete<MessageVideo>(`${this.endpointUrl}/${image.id}`)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
