import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as ConferencingHistoryActions from './conferencing.actions';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { getCurrentCustomer } from '../../../login/state/user.reducers';
import { ApiResponse } from '../../../core/models/api-response';
import { of } from 'rxjs';
import { ConferencingHistoryState } from './conferencing.reducers';
import { TwilioVideoRoomService } from '../../../core/services/twilio-video-room.service';
import { TwilioVideoRoom } from '../../../core/models/twilio-video-room.model';
import { TwilioVideoCompositionService } from '../../../core/services/twilio-video-composition.service';
import { TwilioVideoComposition } from '../../../core/models/twilio-video-composition.model';

@Injectable()
export class ConferencingHistoryEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<ConferencingHistoryState>,
    private twilioVideoRoomService: TwilioVideoRoomService,
    private twilioVideoCompositionService: TwilioVideoCompositionService,
    private router: Router
  ) {}

  getTwilioVideoRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConferencingHistoryActions.getTwilioVideoRooms),
      withLatestFrom(this.store$.select(getCurrentCustomer)),
      concatMap(([action, customer]) =>
        this.twilioVideoRoomService.getTwilioVideoRooms().pipe(
          map((apiResponse: ApiResponse<TwilioVideoRoom>) => {
            return ConferencingHistoryActions.getTwilioVideoRoomsSuccess({
              twilioVideoRooms: apiResponse.results,
            });
          }),
          catchError((error) =>
            of(
              ConferencingHistoryActions.getTwilioVideoRoomsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  getTwilioVideoCompositions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConferencingHistoryActions.getTwilioVideoCompositions),
      withLatestFrom(this.store$.select(getCurrentCustomer)),
      concatMap(([action, customer]) =>
        this.twilioVideoCompositionService.getTwilioVideoCompositions().pipe(
          map((apiResponse: ApiResponse<TwilioVideoComposition>) => {
            return ConferencingHistoryActions.getTwilioVideoCompositionsSuccess(
              {
                twilioVideoCompositions: apiResponse.results,
              }
            );
          }),
          catchError((error) =>
            of(
              ConferencingHistoryActions.getTwilioVideoCompositionsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );
}
