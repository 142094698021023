import { createReducer, on } from '@ngrx/store';
import { Resident } from 'src/app/core/models/resident.model';
import { Supporter } from 'src/app/core/models/supporter.model';
import * as ResidentActions from './resident.actions';
import * as HardwareActions from './hardware.actions';

export const residentFeatureKey = 'residents';

export interface ResidentSupporterMap {
  id: number;
  supporters: Supporter[];
}

export interface ResidentMetric {
  resident_id: number;
  message_set_count: number;
  conferencing_count: number;
  start_date: Date;
  end_date: Date;
}

export interface ResidentState {
  residents: Resident[];
  supporters: ResidentSupporterMap[];
  residentMetrics: ResidentMetric[];
  createResidentFailureMessage: string;
  createHardwareFailureMessage: string;
  deleteHardwareFailureMessage: string;
}

const initialState: ResidentState = {
  residents: [],
  supporters: [],
  residentMetrics: [],
  createResidentFailureMessage: '',
  createHardwareFailureMessage: '',
  deleteHardwareFailureMessage: '',
};

export const residentReducer = createReducer<ResidentState>(
  initialState,
  on(ResidentActions.getResidentsSuccess, (state, action) => ({
    ...state,
    residents: action.residents,
  })),
  on(ResidentActions.getResidentsFailure, (state) => ({
    ...state,
    failureMessage: 'Failed to get residents',
  })),
  on(ResidentActions.createResidentSuccess, (state, action) => ({
    ...state,
    residents: [...state.residents, action.resident],
  })),
  on(ResidentActions.createResidentFailure, (state, action) => ({
    ...state,
    createResidentFailureMessage: action.error.message,
  })),
  on(ResidentActions.updateResidentSuccess, (state, action) => {
    const idx = state.residents.findIndex(
      (resident) => resident.id === action.resident.id
    );
    const residents = [...state.residents];
    residents[idx] = action.resident;
    return { ...state, residents };
  }),
  on(ResidentActions.updateResidentFailure, (state, action) => ({
    ...state,
    createResidentFailureMessage: action.error.message,
  })),
  on(ResidentActions.deleteResidentSuccess, (state, action) => ({
    ...state,
    residents: [
      ...state.residents.filter(
        (resident) => resident.id !== action.resident.id
      ),
    ],
  })),
  on(ResidentActions.deleteResidentFailure, (state, action) => ({
    ...state,
    createResidentFailureMessage: action.error.message,
  })),
  on(ResidentActions.refreshResidentSuccess, (state, action) => {
    const idx = state.residents.findIndex(
      (resident) => resident.id === action.resident.id
    );
    const residents = [...state.residents];
    residents[idx] = action.resident;
    return { ...state, residents };
  }),
  on(ResidentActions.refreshResidentFailure, (state, action) => ({
    ...state,
    createResidentFailureMessage: action.error.message,
  })),
  on(ResidentActions.createHardwareSuccess, (state, action) => {
    const idx = state.residents.findIndex(
      (resident) => resident.id === action.hardware.resident
    );
    const residents = [...state.residents];
    residents[idx] = { ...residents[idx], set_top_box: action.hardware };
    return { ...state, residents };
  }),
  on(ResidentActions.createHardwareFailure, (state, action) => ({
    ...state,
    createHardwareFailureMessage: action.error.message,
  })),
  on(ResidentActions.deleteHardwareSuccess, (state, action) => {
    const idx = state.residents.findIndex(
      (resident) => resident.id === action.resident.id
    );
    const residents = [...state.residents];
    residents[idx].set_top_box = null;
    return { ...state, residents };
  }),
  on(ResidentActions.deleteHardwareFailure, (state, action) => ({
    ...state,
    deleteHardwareFailureMessage: action.error.message,
  })),
  on(ResidentActions.getSupportersForResidentSuccess, (state, action) => ({
    ...state,
    supporters: [
      ...state.supporters,
      { id: action.id, supporters: action.supporters },
    ],
  })),
  on(ResidentActions.getSupportersForResidentFailure, (state) => ({
    ...state,
    failureMessage: 'Failed to get supporters for resident',
  })),
  on(ResidentActions.getResidentMetricsSuccess, (state, action) => {
    let residentMetrics = [...state.residentMetrics];
    const idx = residentMetrics.findIndex(
      (metric) => metric.resident_id === action.residentMetric.resident_id
    );
    if (idx === -1) {
      residentMetrics.push(action.residentMetric);
    } else {
      residentMetrics = residentMetrics.map((metric) =>
        metric.resident_id === action.residentMetric.resident_id
          ? action.residentMetric
          : metric
      );
    }
    return { ...state, residentMetrics };
  }),
  on(HardwareActions.updateHardware, (state, action) => {
    const residentId: number = action.hardware.resident as number;
    const idx = state.residents.findIndex(
      (resident) => resident.id === residentId
    );
    const residents = [...state.residents];
    residents[idx] = { ...residents[idx], set_top_box: action.hardware };
    return { ...state, residents };
  })
);
