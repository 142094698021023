import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Administrator } from 'src/app/core/models/administrator.model';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MustMatch } from 'src/app/core/validators/must-match.validator';
import * as AdministratorActions from 'src/app/manager/administrators/state/administrator.actions';
import * as StaffActions from 'src/app/superuser/staff/state/staff.actions';
import { AdministratorState } from 'src/app/manager/administrators/state/administrator.reducers';
import { Staff } from '../../../core/models/staff.model';

@Component({
  selector: 'cm-set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.scss'],
})
export class SetPasswordFormComponent implements OnInit {
  dialogRef: MatDialogRef<SetPasswordFormComponent>;
  userType: string;
  user: any;
  setPasswordForm: FormGroup;

  constructor(
    private builder: FormBuilder,
    private logger: LoggerService,
    private store$: Store<AdministratorState>
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.setPasswordForm = this.builder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirm_password: ['', [Validators.required, Validators.minLength(8)]],
      },
      { validator: MustMatch('password', 'confirm_password') }
    );
  }

  onSubmit(): void {
    if (this.setPasswordForm.valid) {
      switch (this.userType) {
        case 'administrator':
          let administrator: Administrator = this.user as Administrator;
          administrator = {
            ...administrator,
            password: this.setPasswordForm.get('password').value,
          };
          this.store$.dispatch(
            AdministratorActions.setAdministratorPassword({ administrator })
          );
          break;
        case 'staff':
          let staff: Staff = this.user as Staff;
          staff = {
            ...staff,
            password: this.setPasswordForm.get('password').value,
          };
          this.store$.dispatch(StaffActions.setStaffPassword({ staff }));
          break;
      }

      this.dialogRef.close({ success: true });
    }
  }
}
