import { Component, Input, OnInit } from '@angular/core';
import { Resident } from 'src/app/core/models/resident.model';
import { ResidentService } from 'src/app/core/services/resident.service';

@Component({
  selector: 'cm-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss'],
})
export class PincodeComponent implements OnInit {
  @Input()
  resident: Resident;
  pincode: number;
  isVisible: boolean;

  constructor(private residentService: ResidentService) {}

  ngOnInit(): void {}

  showPincode() {
    if (this.pincode) {
      this.isVisible = true;
    } else {
      this.residentService
        .getPinCode(this.resident.id)
        .subscribe((response: any) => {
          this.pincode = response.pin_code;
          this.isVisible = true;
        });
    }
  }

  hidePincode() {
    this.isVisible = false;
  }
}
