import { createAction, props } from '@ngrx/store';
import { Hardware } from 'src/app/core/models/hardware.model';
import { Resident } from 'src/app/core/models/resident.model';
import { Supporter } from 'src/app/core/models/supporter.model';
import { ResidentMetric } from './resident.reducers';

export const getResidents = createAction('[Resident] Get Residents');

export const getResidentsSuccess = createAction(
  '[Resident] Get Residents Success',
  props<{ residents: Resident[] }>()
);

export const getResidentsFailure = createAction(
  '[Resident] Get Residents Failure',
  props<{ error: any }>()
);

export const createResident = createAction(
  '[Resident] Create Resident',
  props<{ resident: Resident }>()
);

export const createResidentSuccess = createAction(
  '[Resident] Create Resident Success',
  props<{ resident: Resident }>()
);

export const createResidentFailure = createAction(
  '[Resident] Create Resident Failure',
  props<{ error: Error }>()
);

export const updateResident = createAction(
  '[Resident] Update Resident',
  props<{ resident: Resident }>()
);

export const updateResidentSuccess = createAction(
  '[Resident] Update Resident Success',
  props<{ resident: Resident }>()
);

export const updateResidentFailure = createAction(
  '[Resident] Update Resident Failure',
  props<{ error: Error }>()
);

export const deleteResident = createAction(
  '[Resident] Delete Resident',
  props<{ resident: Resident }>()
);

export const deleteResidentSuccess = createAction(
  '[Resident] Delete Resident Success',
  props<{ resident: Resident }>()
);

export const deleteResidentFailure = createAction(
  '[Resident] Delete Resident Failure',
  props<{ error: Error }>()
);

export const refreshResident = createAction(
  '[Resident] Refresh Resident',
  props<{ id: number }>()
);

export const refreshResidentSuccess = createAction(
  '[Resident] Refresh Resident Success',
  props<{ resident: Resident }>()
);

export const refreshResidentFailure = createAction(
  '[Resident] Refresh Resident Failure',
  props<{ error: Error }>()
);

export const createHardware = createAction(
  '[Resident] Create Hardware',
  props<{ hardware: Hardware }>()
);

export const createHardwareSuccess = createAction(
  '[Resident] Create Hardware Success',
  props<{ hardware: Hardware }>()
);

export const createHardwareFailure = createAction(
  '[Resident] Create Hardware Failure',
  props<{ error: Error }>()
);

export const deleteHardware = createAction(
  '[Resident] Delete Hardware',
  props<{ resident: Resident }>()
);

export const deleteHardwareSuccess = createAction(
  '[Resident] Delete Hardware Success',
  props<{ resident: Resident }>()
);

export const deleteHardwareFailure = createAction(
  '[Resident] Delete Hardware Failure',
  props<{ error: Error }>()
);

export const loadSupportersForResident = createAction(
  '[Resident] Load Supporters For Resident',
  props<{ id: number }>()
);

export const getSupportersForResident = createAction(
  '[Resident] Get Supporters For Resident',
  props<{ id: number }>()
);

export const getSupportersForResidentSuccess = createAction(
  '[Resident] Get Supporters For Resident Success',
  props<{ id: number; supporters: Supporter[] }>()
);

export const getSupportersForResidentFailure = createAction(
  '[Resident] Get Supporters For Resident Failure',
  props<{ error: any }>()
);

export const getResidentMetrics = createAction(
  '[Resident] Get Resident Metrics',
  props<{ residentId: number; startDate: Date; endDate: Date }>()
);

export const getResidentMetricsSuccess = createAction(
  '[Resident] Get Resident Metrics Success',
  props<{ residentMetric: ResidentMetric }>()
);

export const getResidentMetricsFailure = createAction(
  '[Resident] Get Resident Metrics Failure',
  props<{ error: any }>()
);
