import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { map, withLatestFrom } from 'rxjs/operators';
import { BaseUser } from '../core/models/base-user.model';
import { ConferencingService } from '../core/services/conferencing.service';

import { AcceptCallDialogComponent } from '../shared/components/accept-call-dialog/accept-call-dialog.component';
import * as ConferencingActions from '../state/conferencing.actions';
import { CallOptions, CallType } from './conferencing.reducer';
import { selectConferencingState } from './conferencing.selectors';
import { State } from './reducer';
import { TwilioVideoRoomChannelService } from '../core/services/twilio-video-room-channel.service';
import { TwilioVideoCompositionChannelService } from '../core/services/twilio-video-composition-channel.service';

@Injectable()
export class ConferencingEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private conferencingService: ConferencingService,
    private twilioVideoRoomChannelService: TwilioVideoRoomChannelService,
    private twilioVideoCompositionChannelService: TwilioVideoCompositionChannelService,
    private router: Router,
    public dialog: MatDialog // public vcr: ViewContainerRef
  ) {}

  // Subscribe to conferencing channel
  subscribeConferencingChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.subscribeConferencingChannel),
        map((action) => {
          this.conferencingService.subscribe(action.customer, action.user);
        })
      ),
    { dispatch: false }
  );

  // Subscribe to twilio video room channel
  subscribeTwilioVideoRoomChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.subscribeConferencingRoomChannel),
        map((action) => {
          this.twilioVideoRoomChannelService.subscribe(action.customer);
        })
      ),
    { dispatch: false }
  );

  // Subscribe to twilio video composition channel
  subscribeTwilioVideoCompositionChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.subscribeConferencingCompositionChannel),
        map((action) => {
          this.twilioVideoCompositionChannelService.subscribe(action.customer);
        })
      ),
    { dispatch: false }
  );

  // Outbound Actions - these call conferencing service to
  // trigger events over ws channel to remote party.

  requestCall$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.requestCall),
        withLatestFrom(this.store$.select(selectConferencingState)),
        map(([action, state]) =>
          this.conferencingService.requestCall(
            state.subscription.channelName,
            action.callOptions
          )
        )
      ),
    { dispatch: false }
  );

  acceptCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConferencingActions.acceptCall),
      withLatestFrom(this.store$.select(selectConferencingState)),
      map(([action, state]) => {
        this.conferencingService.acceptCall(
          state.subscription.channelName,
          state.inboundCallOptions
        );
        return ConferencingActions.showCallViewer({
          callType: CallType.INBOUND,
          remoteUser: state.inboundCallOptions.initiator,
        });
      })
    )
  );

  declineCall$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.declineCall),
        withLatestFrom(this.store$.select(selectConferencingState)),
        map(([action, state]) =>
          this.conferencingService.declineCall(
            state.subscription.channelName,
            state.inboundCallOptions
          )
        )
      ),
    { dispatch: false }
  );

  endCall$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.endCall),
        withLatestFrom(this.store$.select(selectConferencingState)),
        map(([action, state]) => {
          let target: BaseUser;
          if (action.callType === CallType.INBOUND) {
            target = state.inboundCallOptions.initiator;
          } else {
            target = state.outboundCallOptions.target;
          }

          const callOptions: CallOptions = {
            initiator: state.outboundCallOptions.initiator,
            target,
            roomSid:
              state.inboundCallOptions &&
              true &&
              state.inboundCallOptions.roomSid !== undefined
                ? state.inboundCallOptions.roomSid
                : state.outboundCallOptions.roomSid,
            roomName:
              state.inboundCallOptions &&
              true &&
              state.inboundCallOptions.roomName !== undefined
                ? state.inboundCallOptions.roomName
                : state.outboundCallOptions.roomName,
          };

          this.conferencingService.endCall(
            state.subscription.channelName,
            callOptions
          );
        })
      ),
    { dispatch: false }
  );

  // Inbound actions - mayby call UI components?

  callrequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.callRequested),
        map((action) => {
          console.log(`call requested: ${JSON.stringify(action)}`);
          const config = new MatDialogConfig();
          // config.viewContainerRef = this.vcr;
          const dialogRef = this.dialog.open(AcceptCallDialogComponent, config);
          dialogRef.componentInstance.dialogRef = dialogRef;
          dialogRef.componentInstance.callOptions = action.callOptions;
        })
      ),
    { dispatch: false }
  );

  showCallViewer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.showCallViewer),
        withLatestFrom(this.store$.select(selectConferencingState)),
        map(([action, state]) => {
          // const config = new MatDialogConfig();
          // config.panelClass = 'call-viewer-container';
          // config.maxWidth = '100vw';
          // config.width = '100vw';
          // config.height = '100vh';
          // // config.viewContainerRef = this.vcr;
          // let dialogRef = this.dialog.open(TwilioCallViewerComponent, config);
          // dialogRef.componentInstance.dialogRef = dialogRef;
          // if (action.callType === 'inbound') {
          //   dialogRef.componentInstance.inboundCallOptions =
          //     state.inboundCallOptions;
          // }
          // dialogRef.componentInstance.callType = action.callType;
          // dialogRef.componentInstance.remoteUser = action.remoteUser;
          this.router.navigate(
            [`/conferencing/call/viewer/${action.callType}`],
            {
              state: {
                callType: action.callType,
                remoteUser: action.remoteUser,
              },
            }
          );
        })
      ),
    { dispatch: false }
  );

  callAccepted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConferencingActions.callAccepted),
        withLatestFrom(this.store$.select(selectConferencingState)),
        map(([action, state]) => {
          console.log(`call accepted effect fired!`);
          // ConferencingActions.acceptCall();
          // this.conferencingService.acceptCall(
          //   state.subscription.channelName,
          //   state.requestedCallOptions
          // );

          // let url = `/conferencing/call/viewer/incoming/${state.inboundCallOptions.initiator.user_type}/
          //            ${state.inboundCallOptions.initiator.id}`;
          // if (state.inboundCallOptions.sessionId) {
          //   url = `${url}?sessionId=${state.inboundCallOptions}.sessionId}`;
          // }
          // if (state.inboundCallOptions.audioOnly) {
          //   url = `${url}&audioOnly=true`;
          // }
          // (<any>window).open(
          //   url,
          //   '',
          //   'width=800, height=450, menubar=yes, status=yes, titlebar=no'
          // );
        })
      ),
    { dispatch: false }
  );
}
