import { createAction, props } from '@ngrx/store';
import { AdministratorPermission } from 'src/app/core/models/administrator-permissions.model';
import { Administrator } from 'src/app/core/models/administrator.model';

export const getAdministrators = createAction(
  '[Administrators] Get Administrators'
);

export const getAdministratorsSuccess = createAction(
  '[Administrators] Get Administrators Success',
  props<{ administrators: Administrator[] }>()
);

export const getAdministratorsFailure = createAction(
  '[Administrators] Get Administrators Failure',
  props<{ error: any }>()
);

export const createAdministrator = createAction(
  '[Administrators] Create Administrator',
  props<{ administrator: Administrator }>()
);

export const createAdministratorSuccess = createAction(
  '[Administrators] Create Administrator Success',
  props<{ administrator: Administrator }>()
);

export const createAdministratorFailure = createAction(
  '[Administrators] Create Administrator Failure',
  props<{ error: any }>()
);

export const getPermissionsForAdministrator = createAction(
  '[Administrators] Get Permissions For Administrator',
  props<{ administrator: Administrator }>()
);

export const loadPermissionsForAdministrator = createAction(
  '[Administrators] Load Permissions For Administrator',
  props<{ administrator: Administrator }>()
);

export const loadPermissionsForAdministratorSuccess = createAction(
  '[Administrators] Load Permissions For Administrator Success',
  props<{ administratorId: number; permissions: AdministratorPermission }>()
);

export const loadPermissionsForAdministratorFailure = createAction(
  '[Administrators] Load Permissions For Administrator Failure',
  props<{ error: any }>()
);

export const updateAdministrator = createAction(
  '[Administrators] Update Administrator',
  props<{ administrator: Administrator }>()
);

export const updateAdministratorSuccess = createAction(
  '[Administrators] Update Administrator Success',
  props<{ administrator: Administrator }>()
);

export const updateAdministratorFailure = createAction(
  '[Administrators] Update Administrator Failure',
  props<{ error: any }>()
);

export const updateAdministratorPermissions = createAction(
  '[Administrators] Update Administrator Permissions',
  props<{ administrator: Administrator }>()
);

export const updateAdministratorPermissionsSuccess = createAction(
  '[Administrators] Update Administrator Permissions Success',
  props<{ administratorId: number; permissions: AdministratorPermission }>()
);

export const updateAdministratorPermissionsFailure = createAction(
  '[Administrators] Update Administrator Permissions Failure',
  props<{ error: any }>()
);

export const deleteAdministrator = createAction(
  '[Administrators] Delete Administrator',
  props<{ administrator: Administrator }>()
);

export const deleteAdministratorSuccess = createAction(
  '[Administrators] Delete Administrator Success',
  props<{ administrator: Administrator }>()
);

export const deleteAdministratorFailure = createAction(
  '[Administrators] Delete Administrator Failure',
  props<{ error: any }>()
);

export const refreshAdministrator = createAction(
  '[Administrator] Refresh Administrator',
  props<{ id: Number }>()
);

export const refreshAdministratorSuccess = createAction(
  '[Administrator] Refresh Administrator Success',
  props<{ administrator: Administrator }>()
);

export const refreshAdministratorFailure = createAction(
  '[Administrator] Refresh Administrator Failure',
  props<{ error: Error }>()
);

export const setAdministratorPassword = createAction(
  '[Administrators] Set Administrator Password',
  props<{ administrator: Administrator }>()
);

export const setAdministratorPasswordSuccess = createAction(
  '[Administrators] Set Administrator Password Success'
);

export const setAdministratorPasswordFailure = createAction(
  '[Administrators] Set Administrator Password Failure',
  props<{ error: any }>()
);
