import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageImage } from 'src/app/core/models/message-image.model';

@Component({
  selector: 'cm-media-library-image',
  templateUrl: './media-library-image.component.html',
  styleUrls: ['./media-library-image.component.scss'],
})
export class MediaLibraryImageComponent implements OnInit {
  isSelected: boolean = false;

  @Input() image: MessageImage;
  @Output() selected: EventEmitter<MessageImage> = new EventEmitter();
  @Output() unselected: EventEmitter<MessageImage> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  toggleSelection() {
    this.isSelected = !this.isSelected;
    if (this.isSelected) {
      this.selected.emit(this.image);
    } else {
      this.unselected.emit(this.image);
    }
  }
}
