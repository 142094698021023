<div>
  <span id="resident-wrapper">
    <app-avatar-thumbnail
      [user]="resident"
      [size]="50"
    >
    </app-avatar-thumbnail>
    <div>{{ resident.username }}</div>
    <div id="metrics-wrapper" *ngIf="residentMetric$ | async as residentMetric">
      <div class="metric-wrapper">
        <div class="label">Messages</div>
        <span class="metric messages">{{ residentMetric.message_set_count }}</span>
      </div>
      <div class="metric-wrapper">
        <div class="label">Calls</div>
        <span class="metric calls">{{ residentMetric.conferencing_count }}</span>
      </div>
    </div>
  </span>
</div>
