import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CustomerActions from './customer.actions';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Customer } from '../../../core/models/customer.model';
import { CustomerService } from '../../../core/services/customer.service';
import { Administrator } from '../../../core/models/administrator.model';
import { CustomerAdminService } from '../../../core/services/customer-admin.service';
import { NotificationService } from '../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomerEffects {
  translateStrings: any;
  customerPrefix = 'SUPERUSER.CUSTOMER.NOTIFICATIONS.';
  administratorPrefix = 'MANAGER.ADMINISTRATORS.NOTIFICATIONS.';

  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private router: Router,
    private store$: Store,
    private administratorService: CustomerAdminService,
    private notification: NotificationService,
    private translate: TranslateService
  ) {
    const tokens = [
      `${this.customerPrefix}CREATED_SUCCESS`,
      `${this.customerPrefix}UPDATED_SUCCESS`,
      `${this.customerPrefix}DELETED_SUCCESS`,
      `${this.administratorPrefix}CREATED_SUCCESS`,
    ];
    setTimeout(() => {
      this.translate.get(tokens).subscribe((translations) => {
        this.translateStrings = translations;
      });
    }, 1000);
  }

  getCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.getCustomers),
      concatMap((action) =>
        this.customerService.getCustomers().pipe(
          map((customers: Customer[]) => {
            return CustomerActions.getCustomersSuccess({ customers });
          }),
          catchError((error) =>
            of(CustomerActions.getCustomersFailure({ error }))
          )
        )
      )
    )
  );

  createCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.createCustomer),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.customerService.createCustomer(action.customer).pipe(
          map((customer: Customer) => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.customerPrefix}CREATED_SUCCESS`]
            );
            return CustomerActions.createCustomerSuccess({ customer });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(CustomerActions.createCustomerFailure({ error }));
          })
        );
      })
    )
  );

  updateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.updateCustomer),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.customerService.updateCustomer(action.customer).pipe(
          map(() => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.customerPrefix}UPDATED_SUCCESS`]
            );
            return CustomerActions.updateCustomerSuccess({
              customer: action.customer,
            });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(CustomerActions.updateCustomerFailure({ error }));
          })
        );
      })
    )
  );

  deleteCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.deleteCustomer),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.customerService.deleteCustomer(action.customer).pipe(
          map(() => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.customerPrefix}DELETED_SUCCESS`]
            );
            return CustomerActions.deleteCustomerSuccess({
              customer: action.customer,
            });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(CustomerActions.deleteCustomerFailure({ error }));
          })
        );
      })
    )
  );

  createUpdateCustomerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CustomerActions.createCustomerSuccess,
          CustomerActions.updateCustomerSuccess
        ),
        tap((action) => this.router.navigate(['/superuser/customers']))
      ),
    { dispatch: false }
  );

  refreshCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.refreshCustomer),
      exhaustMap((action) =>
        this.customerService.getCustomer(action.id).pipe(
          map((customer: Customer) => {
            return CustomerActions.refreshCustomerSuccess({ customer });
          }),
          catchError((error) =>
            of(CustomerActions.refreshCustomerFailure({ error }))
          )
        )
      )
    )
  );

  createAdministrator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.createAdministrator),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.administratorService
          .createAdministrator(action.administrator)
          .pipe(
            map((administrator: Administrator) => {
              administrator.password = action.administrator.password;
              administrator.permissions = {
                ...action.administrator.permissions,
                customer_admin_id: administrator.id,
                sites: [],
              };
              this.notification.closeProgressDialog();
              this.notification.showSnackBar(
                this.translateStrings[
                  `${this.administratorPrefix}CREATED_SUCCESS`
                ]
              );
              return CustomerActions.createAdministratorSuccess({
                administrator,
              });
            }),
            catchError((error) => {
              this.notification.closeProgressDialog();
              return of(CustomerActions.createAdministratorFailure({ error }));
            })
          );
      })
    )
  );

  createUpdateAdministratorSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerActions.createAdministratorSuccess),
        tap((action) => this.router.navigate(['/superuser/customers/list']))
      ),
    { dispatch: false }
  );
}
