import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AvatarThumbnailComponent } from '../avatar-thumbnail/avatar-thumbnail.component';
import { PresenceState } from 'src/app/state/presence.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseUser } from 'src/app/core/models/base-user.model';
import * as fromPresence from '../../../state/presence.reducer';
import * as ConferencingActions from '../../../state/conferencing.actions';
import { CallType } from 'src/app/state/conferencing.reducer';
import { Resident } from '../../../core/models/resident.model';

@Component({
    selector: 'cm-request-call-dialog',
    templateUrl: 'request-call-dialog.component.html',
    styleUrls: ['request-call-dialog.component.scss']
})
export class RequestCallDialogComponent implements OnInit {
  public dialogRef: MatDialogRef<RequestCallDialogComponent>;

  onlineResidents$: Observable<Resident[]>;

  constructor(private store$: Store<PresenceState>) {}

  ngOnInit(): void {
    this.onlineResidents$ = this.store$.select(
      fromPresence.selectAvailableConferencingResidents
    );
  }

  requestCall(user): void {
    this.store$.dispatch(
      ConferencingActions.showCallViewer({
        callType: CallType.OUTBOUND,
        remoteUser: user,
      })
    );
    this.dialogRef.close();
  }
}
