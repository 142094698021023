import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageVideo } from 'src/app/core/models/message-video.model';

@Component({
  selector: 'cm-media-library-video',
  templateUrl: './media-library-video.component.html',
  styleUrls: ['./media-library-video.component.scss'],
})
export class MediaLibraryVideoComponent implements OnInit {
  @Input() isSelected: boolean = false;
  @Input() video: MessageVideo;
  @Output() selected: EventEmitter<MessageVideo> = new EventEmitter();
  @Output() unselected: EventEmitter<MessageVideo> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  toggleSelection() {
    this.isSelected = !this.isSelected;
    if (this.isSelected) {
      this.selected.emit(this.video);
    } else {
      this.unselected.emit(this.video);
    }
  }
}
