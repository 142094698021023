import { createAction, props } from '@ngrx/store';
import { TwilioVideoRoom } from '../../../core/models/twilio-video-room.model';
import { TwilioVideoComposition } from '../../../core/models/twilio-video-composition.model';

export const getTwilioVideoRooms = createAction(
  '[Conferencing] Get Twilio Video Rooms'
);

export const getTwilioVideoRoomsSuccess = createAction(
  '[Conferencing] Get Twilio Video Rooms Success',
  props<{ twilioVideoRooms: TwilioVideoRoom[] }>()
);

export const getTwilioVideoRoomsFailure = createAction(
  '[Conferencing] Get Twilio Video Rooms Failure',
  props<{ error: Error }>()
);

export const getTwilioVideoCompositions = createAction(
  '[Conferencing] Get Twilio Video Compositions'
);

export const getTwilioVideoCompositionsSuccess = createAction(
  '[Conferencing] Get Twilio Video Compositions Success',
  props<{ twilioVideoCompositions: TwilioVideoComposition[] }>()
);

export const getTwilioVideoCompositionsFailure = createAction(
  '[Conferencing] Get Twilio Video Compositions Failure',
  props<{ error: Error }>()
);

export const getTwilioVideoRoomSuccess = createAction(
  '[Conferencing] Get Twilio Video Room Success',
  props<{ twilioVideoRoom: TwilioVideoRoom }>()
);

export const getTwilioVideoCompositionSuccess = createAction(
  '[Conferencing] Get Twilio Video Composition Success',
  props<{ twilioVideoComposition: TwilioVideoComposition }>()
);
