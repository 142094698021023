import { MessageRecipient } from '../../../core/models/message-recipient.model';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MessageRecipientService } from 'src/app/core/services/message-recipient.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import {Store} from '@ngrx/store';
import * as MessagesActions from '../../../manager/messages/state/messages.actions';

@Component({
  selector: 'cm-message-recipient-status',
  templateUrl: './message-recipient-status.component.html',
  styleUrls: ['./message-recipient-status.component.scss'],
})
export class MessageRecipientStatusComponent implements OnInit, OnChanges {
  @Input() messageRecipient: MessageRecipient;

  revoking = false;

  constructor(
    private messageRecipientService: MessageRecipientService,
    private logger: LoggerService,
    private store: Store
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // this.logger.info(`recipient-status: ${JSON.stringify(changes)}`);
  }

  revoke(messageRecipient): void {
    this.revoking = true;
    this.store.dispatch(MessagesActions.revokeMessageRecipient({messageRecipient}));
  }
}
