<div class="read-counts" *ngIf="readCounts">
  <div class="count-wrapper">
    <div class="label">
      {{ "COMPONENTS.READ_COUNTS.RECIPIENTS" | translate }}
    </div>
    <div class="count recipients">{{ readCounts.total() }}</div>
  </div>
  <div class="count-wrapper">
    <div class="label">{{ "COMPONENTS.READ_COUNTS.PENDING" | translate }}</div>
    <div class="count pending">{{ readCounts.pending }}</div>
  </div>
  <div class="count-wrapper">
    <div class="label">
      {{ "COMPONENTS.READ_COUNTS.DELIVERED" | translate }}
    </div>
    <div class="count delivered">{{ readCounts.delivered }}</div>
  </div>
  <div class="count-wrapper">
    <div class="label">{{ "COMPONENTS.READ_COUNTS.READ" | translate }}</div>
    <div class="count read">{{ readCounts.read }}</div>
  </div>
  <div class="count-wrapper">
    <div class="label">{{ "COMPONENTS.READ_COUNTS.REVOKED" | translate }}</div>
    <div class="count revoked">{{ readCounts.revoked }}</div>
  </div>
  <div class="count-wrapper">
    <div class="label">{{ "COMPONENTS.READ_COUNTS.EXPIRED" | translate }}</div>
    <div class="count expired">{{ readCounts.expired }}</div>
  </div>
</div>
