import { Staff } from '../../../core/models/staff.model';
import { createReducer, on } from '@ngrx/store';
import * as StaffActions from './staff.actions';

export const staffFeatureKey = 'staffs';

export interface StaffState {
  staffs: Staff[];
  createStaffFailureMessage: string;
  updateStaffFailureMessage: string;
  deleteStaffFailureMessage: string;
  refreshStaffFailureMessage: string;
}

const initialState: StaffState = {
  staffs: [],
  createStaffFailureMessage: '',
  updateStaffFailureMessage: '',
  deleteStaffFailureMessage: '',
  refreshStaffFailureMessage: '',
};

export const staffReducer = createReducer<StaffState>(
  initialState,
  on(StaffActions.getStaffsSuccess, (state, action) => ({
    ...state,
    staffs: action.staffs,
  })),
  on(StaffActions.getStaffsFailure, (state) => ({
    ...state,
    failureMessage: 'Failed to get staffs',
  })),
  on(StaffActions.createStaffSuccess, (state, action) => ({
    ...state,
    staffs: [...state.staffs, action.staff],
  })),
  on(StaffActions.createStaffFailure, (state, action) => ({
    ...state,
    createStaffFailureMessage: action.error.message,
  })),
  on(StaffActions.updateStaffSuccess, (state, action) => {
    const idx = state.staffs.findIndex((staff) => staff.id === action.staff.id);
    const staffs = [...state.staffs];
    staffs[idx] = action.staff;
    return { ...state, staffs };
  }),
  on(StaffActions.updateStaffFailure, (state, action) => ({
    ...state,
    updateStaffFailureMessage: action.error.message,
  })),
  on(StaffActions.deleteStaffSuccess, (state, action) => ({
    ...state,
    staffs: [...state.staffs.filter((staff) => staff.id !== action.staff.id)],
  })),
  on(StaffActions.deleteStaffFailure, (state, action) => ({
    ...state,
    deleteStaffFailureMessage: action.error.message,
  })),
  on(StaffActions.refreshStaffSuccess, (state, action) => {
    const idx = state.staffs.findIndex((staff) => staff.id === action.staff.id);
    const staffs = [...state.staffs];
    staffs[idx] = action.staff;
    return { ...state, staffs };
  }),
  on(StaffActions.refreshStaffFailure, (state, action) => ({
    ...state,
    refreshResidentFailureMessage: action.error.message,
  }))
);
