import { createAction, props } from '@ngrx/store';
import { Site } from 'src/app/core/models/site.model';
import { SiteMetric } from './site.reducers';
import { Customer } from '../../../core/models/customer.model';

export const getSites = createAction('[Sites] Get Sites');

export const getSitesSuccess = createAction(
  '[Sites] Get Sites Success',
  props<{ sites: Site[] }>()
);

export const getSitesFailure = createAction(
  '[Sites] Get Sites Failure',
  props<{ error: any }>()
);

export const getMyPermissionsForSite = createAction(
  '[Sites] Get My Permissions For Site',
  props<{ site: Site }>()
);

export const getMyPermissionsForSiteSuccess = createAction(
  '[Sites] Get My Permissions For Site Success',
  props<{ siteId: number; permissions: string[] }>()
);

export const getMyPermissionsForSiteFailure = createAction(
  '[Sites] Get My Permissions For Site Failure',
  props<{ error: any }>()
);

export const createSite = createAction(
  '[Sites] Create Site',
  props<{ site: Site }>()
);

export const createSiteSuccess = createAction(
  '[Sites] Create Site Success',
  props<{ site: Site }>()
);

export const createSiteFailure = createAction(
  '[Sites] Create Site Failure',
  props<{ error: any }>()
);

export const updateSite = createAction(
  '[Sites] Update Site',
  props<{ site: Site }>()
);

export const updateSiteSuccess = createAction(
  '[Sites] Update Site Success',
  props<{ site: Site }>()
);

export const updateSiteFailure = createAction(
  '[Sites] Update Site Failure',
  props<{ error: any }>()
);

export const deleteSite = createAction(
  '[Sites] Delete Site',
  props<{ site: Site }>()
);

export const deleteSiteSuccess = createAction(
  '[Sites] Delete Site Success',
  props<{ site: Site }>()
);

export const deleteSiteFailure = createAction(
  '[Sites] Delete Site Failure',
  props<{ error: any }>()
);

export const subscribeSiteChannel = createAction(
  '[Site Channel] Subscribe',
  props<{ customer: Customer; site: Site }>()
);

export const subscribeSiteChannelSuccess = createAction(
  '[Site Channel] Subscribe Success',
  props<{ channelName: string }>()
);

export const subscribeSiteChannelFailure = createAction(
  '[Site Channel] Subscribe Failure'
);

export const getSiteMetrics = createAction(
  '[Sites] Get Site Metrics',
  props<{ siteId: number; startDate: Date; endDate: Date }>()
);

export const getSiteMetricsSuccess = createAction(
  '[Sites] Get Site Metrics Success',
  props<{ siteMetric: SiteMetric }>()
);

export const getSiteMetricsFailure = createAction(
  '[Sites] Get Site Metrics Failure',
  props<{ error: any }>()
);

// get date that represents first day of current month
export const getFirstDayOfCurrentMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

// get date that represents last day of current month
export const getLastDayOfCurrentMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};
