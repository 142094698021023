import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { PusherService } from './pusher.service';
import { LoggerService } from './logger.service';

import { Channel } from 'pusher-js';
import { UserState } from 'src/app/login/state/user.reducers';
import { BaseUser } from '../models/base-user.model';
import { Store } from '@ngrx/store';
import * as PusherActions from '../../state/pusher.actions';

@Injectable()
export class UserChannelService {
  channelName: string;
  channel: Channel;

  private callRequestedSource = new Subject();

  callRequested$ = this.callRequestedSource.asObservable();

  constructor(
    private logger: LoggerService,
    private pusher: PusherService,
    private store$: Store<UserState>
  ) {}

  public subscribe(user: BaseUser) {
    this.channelName = `private-user-${user.id}`;
    this.channel = this.pusher.subscribe(this.channelName);

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_succeeded',
      (data) => {
        this.logger.info('Private user channel subscription succeeded: ', data);
        this.store$.dispatch(PusherActions.subscribeUserChannelSuccess());
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_error',
      (status) => {
        this.logger.error(
          'Private user channel subscription FAILED: ' + this.channel.name
        );
        this.logger.error(status);
        this.store$.dispatch(PusherActions.subscribeUserChannelFailure());
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'conferencing:call_request',
      this.handleCallRequest
    );
  }

  public unsubscribe() {
    this.pusher.unsubscribe(this.channelName);
    this.logger.info('Unsubscribed private user channel');
  }

  public handleCallRequest(callRequest: any) {
    this.logger.info(`Call request data received: ${callRequest}`);
    this.store$.dispatch(PusherActions.userChannelCallRequest({ callRequest }));
    this.callRequestedSource.next(callRequest);
  }
}
