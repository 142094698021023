import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from '../models/api-response';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { TwilioVideoRoom } from '../models/twilio-video-room.model';

@Injectable({
  providedIn: 'root',
})
export class TwilioVideoRoomService {
  endpointUrl: string = environment.apiUrl + '/conferencing-room-history';

  constructor(private http: HttpClient) {}

  getTwilioVideoRooms(
    filter: string = '',
    sortField: string = 'created',
    sortDirection: string = 'desc',
    pageIndex: number = 1,
    pageSize: number = 200
  ): Observable<ApiResponse<TwilioVideoRoom>> {
    let params = new HttpParams();
    params = params.set('page_size', pageSize.toString());
    params = params.set('ordering', sortField + '__' + sortDirection);
    params = params.set('page', pageIndex.toString());
    params = params.set('search', filter);
    return this.http
      .get<ApiResponse<TwilioVideoRoom>>(this.endpointUrl, { params })
      .pipe(catchError(this.handleError));
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
