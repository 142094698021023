import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userFeatureKey, UserState } from './user.reducers';

// Selector functions
const getUserFeatureState = createFeatureSelector<UserState>(userFeatureKey);

// selectors
export const selectCurrentUser = createSelector(
  getUserFeatureState,
  (state) => state.user
);

export const selectCurrentCustomer = createSelector(
  getUserFeatureState,
  (state) => state.customer
);

export const getAdministrators = createSelector(
  getUserFeatureState,
  (state) => state.administrators
);

export const selectCustomerPermissions = createSelector(
  getUserFeatureState,
  (state) => state.customerPermissions
);

export const selectToken = createSelector(
  getUserFeatureState,
  (state) => state.token
);

export const selectTokenRefreshInterval = createSelector(
  getUserFeatureState,
  (state) => state.tokenRefreshInterval
);

export const selectTokenRefreshFailureMessage = createSelector(
  getUserFeatureState,
  (state) => state.tokenRefreshFailureMessage
);
