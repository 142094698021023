import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  withLatestFrom,
  concatMap,
  map,
  catchError,
  tap,
  mergeMap,
} from 'rxjs/operators';
import { SiteService } from 'src/app/core/services/site.service';
import { getCurrentCustomer } from 'src/app/login/state/user.reducers';
import { SiteMetric, SiteState } from './site.reducers';
import * as SiteActions from './site.actions';
import { Site } from 'src/app/core/models/site.model';
import { NotificationService } from '../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { selectSites } from './site.selectors';
import { getSiteMetricsFailure } from './site.actions';
import { SitePresenceService } from '../../../core/services/site-presence.service';
import * as PusherActions from '../../../state/pusher.actions';

@Injectable()
export class SiteEffects {
  translateStrings: any;
  prefix = 'MANAGER.SITES.NOTIFICATIONS.';

  constructor(
    private actions$: Actions,
    private siteService: SiteService,
    private router: Router,
    private store$: Store<SiteState>,
    private notification: NotificationService,
    private translate: TranslateService,
    private sitePresenceService: SitePresenceService
  ) {
    const tokens = [
      `${this.prefix}CREATED_SUCCESS`,
      `${this.prefix}UPDATED_SUCCESS`,
      `${this.prefix}DELETED_SUCCESS`,
    ];
    setTimeout(() => {
      this.translate.get(tokens).subscribe((translations) => {
        this.translateStrings = translations;
      });
    }, 1000);
  }

  getSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getSites),
      withLatestFrom(this.store$.select(getCurrentCustomer)),
      concatMap(([action, customer]) =>
        this.siteService.getSitesForCustomer(customer.id).pipe(
          map((sites: Site[]) => {
            return SiteActions.getSitesSuccess({ sites });
          }),
          catchError((error) => of(SiteActions.getSitesFailure({ error })))
        )
      )
    )
  );

  getSitesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getSitesSuccess),
      withLatestFrom(this.store$.select(getCurrentCustomer)),
      mergeMap(([action, customer]) =>
        action.sites.map((site) =>
          SiteActions.subscribeSiteChannel({ customer, site })
        )
      )
    )
  );

  getMyPermissionsForSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getMyPermissionsForSite),
      concatMap((action) =>
        this.siteService.getMyPermissionsForSite(action.site.id).pipe(
          map((permissions: string[]) => {
            return SiteActions.getMyPermissionsForSiteSuccess({
              siteId: action.site.id,
              permissions,
            });
          }),
          catchError((error) =>
            of(SiteActions.getMyPermissionsForSiteFailure({ error }))
          )
        )
      )
    )
  );

  getMyPermissionsForSites$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteActions.getSitesSuccess),
        map((action) =>
          action.sites.map((site) =>
            this.store$.dispatch(SiteActions.getMyPermissionsForSite({ site }))
          )
        )
      ),
    { dispatch: false }
  );

  getMetricsForSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getSiteMetrics),
      concatMap((action) =>
        this.siteService
          .getMetricsForSite(action.siteId, action.startDate, action.endDate)
          .pipe(
            map((siteMetric: SiteMetric) => {
              return SiteActions.getSiteMetricsSuccess({ siteMetric });
            }),
            catchError((error) =>
              of(SiteActions.getSiteMetricsFailure({ error }))
            )
          )
      )
    )
  );

  getMetricsForSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.getSitesSuccess),
      mergeMap((action) => {
        const date = new Date();
        return action.sites.map((site) =>
          SiteActions.getSiteMetrics({
            siteId: site.id,
            startDate: new Date(date.getFullYear(), date.getMonth(), 1),
            endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
          })
        );
      })
    )
  );

  createSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.createSite),
      withLatestFrom(this.store$.select(getCurrentCustomer)),
      concatMap(([action, customer]) => {
        this.notification.showProgressDialog();
        return this.siteService.createSite(action.site, customer).pipe(
          map((site: Site) => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.prefix}CREATED_SUCCESS`]
            );
            return SiteActions.createSiteSuccess({ site });
          }),
          catchError((error) => {
            return of(SiteActions.createSiteFailure({ error }));
          })
        );
      })
    )
  );

  updateSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.updateSite),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.siteService.updateSite(action.site).pipe(
          map(() => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.prefix}UPDATED_SUCCESS`]
            );
            return SiteActions.updateSiteSuccess({
              site: action.site,
            });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(SiteActions.updateSiteFailure({ error }));
          })
        );
      })
    )
  );

  deleteSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteActions.deleteSite),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.siteService.deleteSite(action.site).pipe(
          map(() => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.prefix}DELETED_SUCCESS`]
            );
            return SiteActions.deleteSiteSuccess({
              site: action.site,
            });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(SiteActions.deleteSiteFailure({ error }));
          })
        );
      })
    )
  );

  createUpdateSiteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SiteActions.createSiteSuccess,
          SiteActions.updateSiteSuccess,
          SiteActions.deleteSiteSuccess
        ),
        tap((action) => this.router.navigate(['/manager/sites/list']))
      ),
    { dispatch: false }
  );

  subscribeSitePresenceChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteActions.subscribeSiteChannel),
        map((action) => {
          this.sitePresenceService.subscribe(action.customer, action.site);
        })
      ),
    {
      dispatch: false,
    }
  );
}
