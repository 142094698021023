import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessageVideo } from 'src/app/core/models/message-video.model';
import { VideoViewerComponent } from '../../video-viewer/video-viewer.component';

@Component({
  selector: 'cm-video-thumbnails',
  templateUrl: './video-thumbnails.component.html',
  styleUrls: ['./video-thumbnails.component.scss'],
})
export class VideoThumbnailsComponent implements OnInit {
  @Input() video: MessageVideo;

  constructor(
    private dialog: MatDialog,
    private viewContainerReference: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  onVideoClicked(video: MessageVideo) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerReference;
    let videoViewerDialog = this.dialog.open(VideoViewerComponent, config);
    videoViewerDialog.componentInstance.dialogRef = videoViewerDialog;
    videoViewerDialog.componentInstance.video = video;
  }
}
