import { Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { Customer } from '../models/customer.model';
import { CustomerService } from '../services/customer.service';
import { Store } from '@ngrx/store';
import {
  getCurrentCustomer,
  UserState,
} from 'src/app/login/state/user.reducers';

@Injectable()
export class CustomerResolve implements Resolve<Customer> {
  constructor(
    private cs: CustomerService,
    private router: Router,
    private store$: Store<UserState>
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Customer | Observable<Customer> {
    // let user = JSON.parse(localStorage.getItem('user'));
    return this.store$.select(getCurrentCustomer);
  }
}
