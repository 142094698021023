import { ReadCounts } from './../../../core/models/read-counts.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-read-counts',
    templateUrl: './read-counts.component.html',
    styleUrls: ['./read-counts.component.scss']
})
export class ReadCountsComponent implements OnInit {

    @Input() readCounts: ReadCounts;

    constructor() { }

    ngOnInit() {
    }

}
