import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-set-overview-card',
  templateUrl: './message-set-overview-card.component.html',
  styleUrls: ['./message-set-overview-card.component.scss']
})
export class MessageSetOverviewCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
