import { createAction, props } from '@ngrx/store';
import { AssistanceProvider } from '../../../core/models/assistance-provider.model';
import { Site } from '../../../core/models/site.model';

export const getAssistanceProvidersForCustomer = createAction(
  '[Assistance] Get Assistance Providers For Customer'
);

export const getAssistanceProvidersForCustomerSuccess = createAction(
  '[Assistance] Get Assistance Providers For Customer Success',
  props<{ assistanceProviders: AssistanceProvider[] }>()
);

export const getAssistanceProvidersForCustomerFailure = createAction(
  '[Assistance] Get Assistance Providers For Customer Failure',
  props<{ error: Error }>()
);

export const createAssistanceProvider = createAction(
  '[Assistance] Create Assistance Provider',
  props<{ assistanceProvider: AssistanceProvider }>()
);

export const createAssistanceProviderSuccess = createAction(
  '[Assistance] Create Assistance Provider Success',
  props<{ assistanceProvider: AssistanceProvider }>()
);

export const createAssistanceProviderFailure = createAction(
  '[Assistance] Create Assistance Provider Failure',
  props<{ error: any }>()
);

export const updateAssistanceProvider = createAction(
  '[Assistance] Update Assistance Provider',
  props<{ assistanceProvider: AssistanceProvider }>()
);

export const updateAssistanceProviderSuccess = createAction(
  '[Assistance] Update Assistance Provider Success',
  props<{ assistanceProvider: AssistanceProvider }>()
);

export const updateAssistanceProviderFailure = createAction(
  '[Assistance] Update Assistance Provider Failure',
  props<{ error: any }>()
);

export const deleteAssistanceProvider = createAction(
  '[Assistance] Delete Assistance Provider',
  props<{ assistanceProvider: AssistanceProvider }>()
);

export const deleteAssistanceProviderSuccess = createAction(
  '[Assistance] Delete Assistance Provider Success',
  props<{ assistanceProvider: AssistanceProvider }>()
);

export const deleteAssistanceProviderFailure = createAction(
  '[Assistance] Delete Assistance Provider Failure',
  props<{ error: Error }>()
);
