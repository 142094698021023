import { createReducer, on } from '@ngrx/store';
import * as AssistanceActions from './assistance.actions';
import { AssistanceProvider } from '../../../core/models/assistance-provider.model';

export const assistanceFeatureKey = 'assistance';

export interface AssistanceState {
  assistanceProviders: AssistanceProvider[];
  getAssistanceProvidersFailureMessage: string;
  createAssistanceProviderFailureMessage: string;
  updateAssistanceProviderFailureMessage: string;
  deleteAssistanceProvidersFailureMessage: string;
}

const initialState: AssistanceState = {
  assistanceProviders: [],
  getAssistanceProvidersFailureMessage: '',
  createAssistanceProviderFailureMessage: '',
  updateAssistanceProviderFailureMessage: '',
  deleteAssistanceProvidersFailureMessage: '',
};

export const assistanceReducer = createReducer<AssistanceState>(
  initialState,
  on(
    AssistanceActions.getAssistanceProvidersForCustomerSuccess,
    (state, action) => ({
      ...state,
      assistanceProviders: action.assistanceProviders,
    })
  ),
  on(
    AssistanceActions.getAssistanceProvidersForCustomerFailure,
    (state, action) => ({
      ...state,
      getAssistanceProvidersFailureMessage: action.error.message,
    })
  ),
  on(AssistanceActions.createAssistanceProviderSuccess, (state, action) => ({
    ...state,
    assistanceProviders: [
      ...state.assistanceProviders,
      action.assistanceProvider,
    ],
  })),
  on(AssistanceActions.createAssistanceProviderFailure, (state, action) => ({
    ...state,
    createAssistanceProviderFailureMessage: action.error.message,
  })),
  on(AssistanceActions.updateAssistanceProviderSuccess, (state, action) => {
    const idx = state.assistanceProviders.findIndex(
      (assistanceProvider) =>
        assistanceProvider.id === action.assistanceProvider.id
    );
    const assistanceProviders = [...state.assistanceProviders];
    assistanceProviders[idx] = action.assistanceProvider;
    return { ...state, assistanceProviders };
  }),
  on(AssistanceActions.updateAssistanceProviderFailure, (state, action) => ({
    ...state,
    updateAssistanceProviderFailureMessage: action.error.message,
  })),
  on(AssistanceActions.deleteAssistanceProviderSuccess, (state, action) => ({
    ...state,
    assistanceProviders: [
      ...state.assistanceProviders.filter(
        (assistanceProvider) =>
          assistanceProvider.id !== action.assistanceProvider.id
      ),
    ],
  })),
  on(AssistanceActions.deleteAssistanceProviderFailure, (state, action) => ({
    ...state,
    deleteAssistanceProviderFailureMessage: action.error.message,
  }))
);
