import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MessageRecipient } from './../models/message-recipient.model';
import { environment } from '../../../environments/environment';
import { LoggerService } from './logger.service';
import { Injectable } from '@angular/core';
import {RevocationResponse} from '../models/revocation-response';

@Injectable()
export class MessageRecipientService {
  endpointUrl: string = environment.apiUrl + '/message-recipient';

  constructor(private logger: LoggerService, private http: HttpClient) {}

  getMessageRecipients() {
    return this.http
      .get<MessageRecipient[]>(this.endpointUrl)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getMessageRecipient(messageRecipientId) {
    return this.http.get<MessageRecipient>(
      `${this.endpointUrl}/${messageRecipientId}`
    );
  }

  revokeMessageRecipient(messageRecipient: MessageRecipient): Observable<RevocationResponse> {
    return this.http.patch<RevocationResponse>(
      `${this.endpointUrl}/${messageRecipient.id}/revoke`, {}
    );
  }

  getMessageRecipientsForMessageSet(
    messageSetId: number
  ): Observable<MessageRecipient[]> {
    let params = new HttpParams();
    params = params.set('message_set', messageSetId);
    params = params.set('limit', '0');
    params = params.set('page_size', 1000);
    return this.http
      .get<MessageRecipient[]>(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getMessageRecipientsForCustomer(
    customerId: number
  ): Observable<MessageRecipient[]> {
    let params = new HttpParams();
    params = params.set('customer', customerId);
    params = params.set('limit', '0');
    return this.http
      .get<MessageRecipient[]>(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
