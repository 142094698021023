import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from 'src/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';

import { UserEffects } from './login/state/user.effects';

import { PresenceEffects } from './state/presence.effects';

import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ROOT_REDUCERS, metaReducers, PusherEffects } from './state';
import { ConferencingEffects } from './state/conferencing.effects';
import { SiteEffects } from './manager/sites/state/site.effects';
import { ResidentEffects } from './manager/residents/state/resident.effects';
import * as Sentry from '@sentry/angular';
import { Replay } from '@sentry/replay';
import { HardwareEffects } from './manager/residents/state/hardware.effects';
import { AssistanceEffects } from './manager/assistance/state/assistance.effects';
import { BrowserTracing } from '@sentry/tracing';
import { CustomerEffects } from './superuser/customers/state/customer.effects';
import { StaffEffects } from './superuser/staff/state/staff.effects';
import { ConferencingHistoryEffects } from './manager/conferencing/state/conferencing.effects';

Sentry.init({
  dsn: 'https://2d7f431a5a234e93a08871dfe6511325@o409495.ingest.sentry.io/5454935',
  environment: environment.env,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        'localhost',
        'https://manager.caremessenger.co.uk',
        'https://manager-staging.caremessenger.co.uk',
        'https://manager-dev.caremessenger.co.uk',
        'https://manager-demo.caremessenger.co.uk',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Replay(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.env === 'prod' ? 0.4 : 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: environment.env === 'prod' ? 0.4 : 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

export function tokenGetter(): string {
  return localStorage.getItem('access_token');
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

// ngx-translate loader
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: ['localhost', 'caremessenger.co.uk'],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
    }),
    EffectsModule.forRoot([
      PusherEffects,
      UserEffects,
      PresenceEffects,
      ConferencingEffects,
      SiteEffects,
      ResidentEffects,
      HardwareEffects,
      AssistanceEffects,
      CustomerEffects,
      StaffEffects,
      ConferencingHistoryEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
