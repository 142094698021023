import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageVideo } from 'src/app/core/models/message-video.model';

@Component({
  selector: 'cm-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss'],
})
export class VideoViewerComponent implements OnInit {
  dialogRef: MatDialogRef<VideoViewerComponent>;
  @Input() video: MessageVideo;

  constructor() {}

  ngOnInit(): void {
    // console.log(JSON.stringify(this.video));
  }
}
