import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Site } from '../models/site.model';
import { ApiResponse } from '../models/api-response';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import {
  getCurrentCustomer,
  getCurrentUser,
  UserState,
} from 'src/app/login/state/user.reducers';
import { Customer } from '../models/customer.model';
import { ScheduledMessageSet } from '../models/scheduled-message-set.model';
import { SiteMetric } from '../../manager/sites/state/site.reducers';

@Injectable()
export class SiteService {
  endpointUrl: string = environment.apiUrl + '/site';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<UserState>
  ) {}

  getSites() {
    let params = new HttpParams();
    params = params.set('page_size', '50');
    params = params.set('ordering', 'name');
    return this.http
      .get<ApiResponse<Site>>(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getSitesForCustomer(customerId: number) {
    let params = new HttpParams();
    params = params.set('page_size', '50');
    params = params.set('customer', customerId);
    params = params.set('ordering', 'name');
    return this.http
      .get<ApiResponse<Site>>(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getSite(siteId) {
    return this.http.get<Site>(this.endpointUrl + '/' + siteId);
  }

  getMyPermissionsForSite(siteId: number) {
    return this.http
      .get<string[]>(`${this.endpointUrl}/${siteId}/perms`)
      .pipe(catchError(this.handleError));
  }

  getMetricsForSite(
    siteId: number,
    startDate: Date,
    endDate: Date
  ): Observable<SiteMetric> {
    let params = new HttpParams();
    params = params.set('start_date', startDate.toISOString());
    params = params.set('end_date', endDate.toISOString());
    return this.http
      .get<SiteMetric>(`${this.endpointUrl}/${siteId}/metrics`, { params })
      .pipe(catchError(this.handleError));
  }

  createSite(site: Site, customer: Customer) {
    site = { ...site, customer: customer.id.toString(), firmware_release: '1' };
    let params = new HttpParams();
    params = params.set('customer', customer.id);
    return this.http.post<Site>(this.endpointUrl, site, { params });
  }

  updateSite(site: Site) {
    let customer: Customer;
    this.store
      .select(getCurrentCustomer)
      .pipe(take(1))
      .subscribe((c: Customer) => (customer = c));
    site.customer = customer.id.toString();
    site.firmware_release = '1';
    let params = new HttpParams();
    params = params.set('customer', customer.id);
    return this.http
      .put<Site>(`${this.endpointUrl}/${site.id}`, site, { params })
      .pipe(catchError(this.handleError));
  }

  deleteSite(site: Site) {
    return this.http
      .delete<Site>(`${this.endpointUrl}/${site.id}`)
      .pipe(catchError(this.handleError));
  }

  getScheduledMessageSetsForSite(
    siteId: number,
    start: Date,
    end: Date
  ): Observable<ScheduledMessageSet[]> {
    let params = new HttpParams();
    params = params.set('siteId', siteId.toString());
    params = params.set('start', start.toISOString());
    params = params.set('end', end.toISOString());
    return this.http
      .get<ScheduledMessageSet[]>(
        `${this.endpointUrl}/${siteId}/scheduled_messagesets`,
        { params }
      )
      .pipe(catchError(this.handleError));
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any): Observable<any> {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
