import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { PusherService } from './pusher.service';
import { Store } from '@ngrx/store';
import { UserState } from '../../login/state/user.reducers';
import { PresenceChannel } from 'pusher-js';
import { Customer } from '../models/customer.model';
import * as SiteActions from '../../manager/sites/state/site.actions';
import * as HardwareActions from '../../manager/residents/state/hardware.actions';
import { Site } from '../models/site.model';
import { Hardware } from '../models/hardware.model';

@Injectable({
  providedIn: 'root',
})
export class SitePresenceService {
  channel: PresenceChannel;
  channelName: string;

  constructor(
    private logger: LoggerService,
    private pusher: PusherService,
    private store$: Store<UserState>
  ) {}

  public subscribe(customer: Customer, site: Site): void {
    this.channelName = `presence-${customer.slug}-${site.slug}`;
    this.channel = this.pusher.subscribe(this.channelName);

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_succeeded',
      (channel) => {
        this.logger.info(
          'Site presence channel subscription succeeded: ' + this.channel.name
        );
        this.store$.dispatch(
          SiteActions.subscribeSiteChannelSuccess({
            channelName: this.channel.name,
          })
        );
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_error',
      (status) => {
        this.logger.error(
          'Site presence channel subscription FAILED: ' + this.channel.name
        );
        this.logger.error(status);
        this.store$.dispatch(SiteActions.subscribeSiteChannelFailure());
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'set_top_box_updated',
      (hardware: Hardware) => {
        this.logger.info(
          `hardware updated over ws: ${JSON.stringify(hardware.id)}`
        );
        this.logger.info(
          `hardware updated over ws: ${JSON.stringify(hardware)}`
        );

        this.store$.dispatch(HardwareActions.updateHardware({ hardware }));
      }
    );

    this.pusher.bindAllChannelEvent(this.channel, (event, data) => {
      console.warn('uncaught event: ' + event);
    });
  }

  public unsubscribe(): void {
    this.pusher.unsubscribe(this.channelName);
    this.logger.info('Unsubscribed site presence channel');
  }
}
