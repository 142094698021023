import { createAction, props } from '@ngrx/store';
import { BaseUser } from '../core/models/base-user.model';

// Global
export const setGlobalUsers = createAction(
  '[Global Presence] Set Users',
  props<{ users: BaseUser[] }>()
);

export const globalUserOnline = createAction(
  '[Global Presence] User Online',
  props<BaseUser>()
);

export const globalUserOffline = createAction(
  '[Global Presence] User Offline',
  props<BaseUser>()
);

// Customer
export const getOnlineCustomerUsers = createAction(
  '[Customer Presence] Get Online Users'
);

export const getOnlineCustomerUsersSuccess = createAction(
  '[Customer Presence] Get Online Users Success',
  props<{ users: BaseUser[] }>()
);

export const getOnlineCustomerUsersFailure = createAction(
  '[Customer Presence] Get Online Users Failure'
);

export const customerUserOnline = createAction(
  '[Customer Presence] User Online',
  props<BaseUser>()
);

export const customerUserOffline = createAction(
  '[Customer Presence] User Offline',
  props<BaseUser>()
);
