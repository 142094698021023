import { Injectable } from '@angular/core';
import { Channel } from 'pusher-js';
import { LoggerService } from './logger.service';
import { PusherService } from './pusher.service';
import { Store } from '@ngrx/store';
import { Customer } from '../models/customer.model';
import * as PusherConferencingActions from '../../state/conferencing.actions';
import * as ConferencingActions from '../../manager/conferencing/state/conferencing.actions';
import { TwilioVideoComposition } from '../models/twilio-video-composition.model';

@Injectable({
  providedIn: 'root',
})
export class TwilioVideoCompositionChannelService {
  channelName: string;
  channel: Channel;

  constructor(
    private logger: LoggerService,
    private pusher: PusherService,
    private store: Store
  ) {}

  public subscribe(customer: Customer): void {
    this.channelName = `private-conferencing-composition-${customer.id}`;
    this.channel = this.pusher.subscribe(this.channelName);

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_succeeded',
      (data) => {
        this.logger.info(
          'Private conferencing composition channel subscription succeeded: ',
          data
        );
        this.store.dispatch(
          PusherConferencingActions.subscribeConferencingCompositionChannelSuccess()
        );
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_error',
      (status) => {
        this.logger.error(
          'Private conferencing composition channel subscription FAILED: ' +
            this.channel.name
        );
        this.logger.error(status);
        this.store.dispatch(
          PusherConferencingActions.subscribeConferencingCompositionChannelFailure()
        );
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'video_composition_updated',
      (twilioVideoComposition: TwilioVideoComposition) => {
        this.logger.info(
          `video_composition_updated: ${JSON.stringify(twilioVideoComposition)}`
        );
        this.store.dispatch(
          ConferencingActions.getTwilioVideoCompositionSuccess({
            twilioVideoComposition,
          })
        );
      }
    );
  }

  public unsubscribe(): void {
    this.pusher.unsubscribe(this.channelName);
    this.logger.info('Unsubscribed private conferencing room channel');
  }
}
