import { TwilioVideoRoom } from '../../../core/models/twilio-video-room.model';
import { TwilioVideoComposition } from '../../../core/models/twilio-video-composition.model';
import { createReducer, on } from '@ngrx/store';
import * as ConferencingActions from './conferencing.actions';

export const conferencingHistoryFeatureKey = 'conferencingHistory';

export interface ConferencingHistoryState {
  twilioVideoRooms: TwilioVideoRoom[];
  twilioVideoCompositions: TwilioVideoComposition[];
  getTwilioVideoRoomsFailureMessage: string;
  getTwilioVideoCompositionsFailureMessage: string;
}

const initialState: ConferencingHistoryState = {
  twilioVideoRooms: [],
  twilioVideoCompositions: [],
  getTwilioVideoRoomsFailureMessage: '',
  getTwilioVideoCompositionsFailureMessage: '',
};

export const conferencingHistoryReducer =
  createReducer<ConferencingHistoryState>(
    initialState,
    on(ConferencingActions.getTwilioVideoRoomsSuccess, (state, action) => {
      return { ...state, twilioVideoRooms: action.twilioVideoRooms };
    }),
    on(ConferencingActions.getTwilioVideoRoomsFailure, (state, action) => ({
      ...state,
      getTwilioVideoRoomsFailureMessage: action.error.message,
    })),
    on(
      ConferencingActions.getTwilioVideoCompositionsSuccess,
      (state, action) => ({
        ...state,
        twilioVideoCompositions: action.twilioVideoCompositions,
      })
    ),
    on(
      ConferencingActions.getTwilioVideoCompositionsFailure,
      (state, action) => ({
        ...state,
        getTwilioVideoCompositionsFailureMessage: action.error.message,
      })
    ),
    on(ConferencingActions.getTwilioVideoRoomSuccess, (state, action) => {
      return {
        ...state,
        twilioVideoRooms: [
          action.twilioVideoRoom,
          ...state.twilioVideoRooms.filter(
            (twilioVideoRoom) =>
              twilioVideoRoom.id !== action.twilioVideoRoom.id
          ),
        ],
      };
    }),
    on(
      ConferencingActions.getTwilioVideoCompositionSuccess,
      (state, action) => {
        return {
          ...state,
          twilioVideoCompositions: [
            action.twilioVideoComposition,
            ...state.twilioVideoCompositions.filter(
              (twilioVideoComposition) =>
                twilioVideoComposition.id !== action.twilioVideoComposition.id
            ),
          ],
        };
      }
    )
  );
