import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import * as UserActions from './user.actions';
import { Customer } from 'src/app/core/models/customer.model';
import { BaseUser } from 'src/app/core/models/base-user.model';
import { Administrator } from 'src/app/core/models/administrator.model';

export const userFeatureKey = 'profile';

export interface Credentials {
  username: string;
  password: string;
}

export interface LoginResponse {
  user: any;
  token: string;
}

export interface UserState {
  user: BaseUser;
  token: string;
  tokenRefreshInterval: number;
  tokenRefreshFailureMessage: string;
  authenticated: boolean;
  failureMessage: string;
  customer: Customer;
  customerPermissions: string[];
  administrators: Administrator[];
}

const initialState: UserState = {
  user: undefined,
  token: '',
  tokenRefreshInterval: 360000,
  tokenRefreshFailureMessage: '',
  authenticated: false,
  failureMessage: '',
  customer: undefined,
  customerPermissions: [],
  administrators: [],
};

export const userReducer = createReducer<UserState>(
  initialState,
  on(UserActions.loginSuccess, (state, loginResponse: LoginResponse) => ({
    ...state,
    authenticated: true,
    user: loginResponse.user,
    token: loginResponse.token,
    failureMessage: '',
  })),
  on(UserActions.loginFailure, (state) => ({
    ...state,
    failureMessage: 'Incorrect username or password',
  })),
  on(UserActions.refreshTokenSuccess, (state, action) => {
    return { ...state, token: action.token };
  }),
  on(UserActions.refreshTokenFailure, (state, action) => {
    return { ...state, tokenRefreshFailureMessage: action.error };
  }),
  on(UserActions.getCustomerSuccess, (state, customer: Customer) => ({
    ...state,
    customer,
  })),
  on(UserActions.getCustomerFailure, (state) => ({
    ...state,
    failureMessage: 'Failed to get customer',
  })),
  on(UserActions.getCustomerPermissionsSuccess, (state, action) => ({
    ...state,
    customerPermissions: [...action.permissions],
  })),
  on(UserActions.getCustomerPermissionsFailure, (state) => ({
    ...state,
    failureMessage: 'Failed to get customer permissions',
  })),
  on(UserActions.getAdministratorsSuccess, (state, action) => ({
    ...state,
    administrators: action.administrators,
  })),
  on(UserActions.getAdministratorsFailure, (state) => ({
    ...state,
    failureMessage: 'Failed to get administrators',
  }))
);

// Selectors

const getUserFeatureState = createFeatureSelector<UserState>('profile');

export const getCurrentUser = createSelector(
  getUserFeatureState,
  (state) => state.user
);

export const getCurrentCustomer = createSelector(
  getUserFeatureState,
  (state) => state.customer
);

export const getAdministrators = createSelector(
  getUserFeatureState,
  (state) => state.administrators
);

export const getToken = createSelector(
  getUserFeatureState,
  (state) => state.token
);

export const getProfile = createSelector(getUserFeatureState, (state) => state);

export const getFailureMessage = createSelector(
  getUserFeatureState,
  (state) => state.failureMessage
);
