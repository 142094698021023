import { element } from 'protractor';
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  NgZone,
  EventEmitter,
  ViewEncapsulation,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import {
  UploadOutput,
  UploadInput,
  UploadFile,
  humanizeBytes,
  UploaderOptions,
} from 'ngx-uploader';

import { AuthService } from '../../../core/services/auth.service';
import { LoggerService } from '../../../core/services/logger.service';
import { environment } from '../../../../environments/environment';
import { take } from 'rxjs/operators';
import { getToken, UserState } from 'src/app/login/state/user.reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageUploadComponent implements OnInit, OnChanges {
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  @Input() resourceName: string;
  @Input() resourceId: number;
  @Input() fieldName: string;
  @Input() existingFileUrl: string;

  @Output() uploadComplete: EventEmitter<any> = new EventEmitter();

  public uploadEvents: EventEmitter<any> = new EventEmitter();
  resourceUrl: string;

  private previewData: any;
  public previewUrl: any;

  private zone: NgZone;
  private response: any;
  private uploadFile: any;
  public progress = 0;

  constructor(
    private authService: AuthService,
    private logger: LoggerService,
    private sanitizer: DomSanitizer,
    private store: Store<UserState>
  ) {
    this.options = {
      concurrency: 1,
      maxUploads: 3,
      maxFileSize: 1000000,
      allowedContentTypes: ['image/png', 'image/jpg', 'image/jpeg'],
    };
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.zone = new NgZone({ enableLongStackTrace: false });
    this.resourceUrl = `${environment.apiUrl}/${this.resourceName}/${this.resourceId}`;

    if (this.existingFileUrl) {
      this.previewUrl = this.sanitizer.bypassSecurityTrustStyle(
        'url(' + this.existingFileUrl + ')'
      );
    } else {
      this.previewUrl = this.sanitizer.bypassSecurityTrustStyle(
        'url(assets/images/no-avatar.png)'
      );
    }
  }

  ngOnChanges(changes) {
    this.logger.debug('changes called: ', changes);
    if (changes && changes.hasOwnProperty('existingFileUrl')) {
      this.previewUrl = this.sanitizer.bypassSecurityTrustStyle(
        'url(' + changes.existingFileUrl.currentValue + ')'
      );
    }
  }

  handlePreviewData(data: any): void {
    this.previewData = data;
    this.previewUrl = this.sanitizer.bypassSecurityTrustStyle(
      'url(' + data + ')'
    );
    this.logger.info('preview data received');
  }

  onUploadOutput(output: UploadOutput): void {
    let token: string;
    this.store
      .select(getToken)
      .pipe(take(1))
      .subscribe((t) => (token = t));

    switch (output.type) {
      case 'allAddedToQueue':
        this.progress = 0;
        const event: UploadInput = {
          type: 'uploadAll',
          url: this.resourceUrl,
          fieldName: this.fieldName,
          method: 'PATCH',
          headers: { Authorization: `Bearer ${token}` },
        };
        this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex(
            (file) =>
              typeof output.file !== 'undefined' && file.id === output.file.id
          );
          this.progress = output.file.progress.data.percentage / 100;
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter(
          (file: UploadFile) => file !== output.file
        );
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        this.uploadComplete.emit(this.resourceId);
        this.previewUrl = this.sanitizer.bypassSecurityTrustStyle(
          'url(' + output.file.response.avatar_large + ')'
        );
        console.info('upload done!');
        break;
    }
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }
}
