import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Site } from './../../../core/models/site.model';
import { ReadCounts } from './../../../core/models/read-counts.model';
import { AuthService } from './../../../core/services/auth.service';
import { BaseUser } from './../../../core/models/base-user.model';
import { Staff } from './../../../core/models/staff.model';
import { Resident } from './../../../core/models/resident.model';
import { Administrator } from '../../../core/models/administrator.model';
import { MessageSet } from './../../../core/models/message-set.model';
import { MessageRecipientService } from './../../../core/services/message-recipient.service';
import { MessageSetService } from './../../../core/services/message-set.service';
import { LoggerService } from './../../../core/services/logger.service';
import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/app/core/models/api-response';
import { PusherService } from 'src/app/core/services/pusher.service';
import { MessageRecipient } from 'src/app/core/models/message-recipient.model';
import { Store } from '@ngrx/store';
import {
  getCurrentCustomer,
  UserState,
} from 'src/app/login/state/user.reducers';
import { take } from 'rxjs/operators';
import { Customer } from 'src/app/core/models/customer.model';
import { MessageBoxSubscription } from '../state/messages.reducer';
import * as MessagesActions from '../state/messages.actions';
import * as SiteSelectors from '../../sites/state/site.selectors';
import { Channel } from 'pusher-js';

export interface MessageSetNotification {
  message_set_id: number;
}

export interface MessageRecipientNotification {
  message_recipient_id: number;
  message_set_id: number;
  recipient_id: number;
}

@Injectable()
export class MessageboxService {
  messageSets: MessageSet[];
  currentMessageSet: MessageSet;

  administrators: Administrator[];
  residents: Resident[];
  staff: Staff[];
  senders: BaseUser[];

  busy: boolean = false;
  loading: boolean = false;
  loadingRecipients: boolean = false;
  totalMessageCount: number;
  next: string = null;

  readCounts: ReadCounts = new ReadCounts();

  searchQuery: string = '';
  searchExecuted: boolean = false;
  filterSite: Site;
  filterRecipient: Resident;
  filterSender: BaseUser;
  filterFromDate: Date;
  filterToDate: Date;

  constructor(
    private logger: LoggerService,
    private messageSetService: MessageSetService,
    private messageRecipientService: MessageRecipientService,
    private pusher: PusherService,
    private auth: AuthService,
    private http: HttpClient,
    private store$: Store<UserState>
  ) {}

  getChannelName(customer: Customer, site: Site): string {
    return `presence-messagebox-${customer.slug}-${site.slug}`;
  }

  subscribe(customer: Customer, site: Site) {
    const channelName = this.getChannelName(customer, site);
    const channel = this.pusher.subscribe(channelName);
    this.pusher.bindChannelEvent(
      channel,
      'pusher:subscription_succeeded',
      (channel: Channel) =>
        this.store$.dispatch(
          MessagesActions.subscribeMessageBoxChannelSuccess({
            subscription: { channelName, siteId: site.id },
          })
        )
    );

    this.pusher.bindChannelEvent(
      channel,
      'pusher:subscription_error',
      (error: Error) =>
        this.store$.dispatch(
          MessagesActions.subscribeMessageBoxChannelFailure(error)
        )
    );

    this.pusher.bindChannelEvent(
      channel,
      'message_set_created',
      this.onMessageSetCreated.bind(this)
    );

    this.pusher.bindChannelEvent(
      channel,
      'message_set_updated',
      this.onMessageSetUpdated.bind(this)
    );

    this.pusher.bindChannelEvent(
      channel,
      'message_recipient_updated',
      this.onMessageRecipientUpdated.bind(this)
    );
  }

  unsubscribe(subscription: MessageBoxSubscription) {
    const channel = this.pusher.channel(subscription.channelName);
    if (channel && channel.subscribed) {
      this.pusher.unsubscribe(channel.name);
      this.logger.info(`unsubscribed: ${channel.name}`);
    }
  }

  onMessageSetCreated(notification: MessageSetNotification) {
    this.logger.info(`messageSetCreated: ${JSON.stringify(notification)}`);
    this.store$.dispatch(
      MessagesActions.getMessageSet({
        messageSetId: notification.message_set_id,
      })
    );
  }

  onMessageSetUpdated(notification: MessageSetNotification) {
    this.logger.info(`messageSetUpdated: ${JSON.stringify(notification)}`);
  }

  onMessageRecipientUpdated(notification: MessageRecipientNotification) {
    this.logger.info(
      `messageRecipientUpdated: ${JSON.stringify(notification)}`
    );

    this.store$.dispatch(
      MessagesActions.pusherMessageRecipientUpdated({ notification })
    );

    // this.store$.dispatch(
    //   MessagesActions.getRecipientsForMessageSet({
    //     messageSetId: notification.message_set_id,
    //   })
    // );

    // this.store$.dispatch(
    //   MessagesActions.pusherMessageRecipientUpdated({ messageRecipient })
    // );

    // this.messageRecipientService
    //   .getMessageRecipient(data.message_recipient_id)
    //   .subscribe(
    //     (updatedMessageRecipient: MessageRecipient) => {
    //       const messageSetIndex = this.messageSets.findIndex(
    //         (element) => element.id === updatedMessageRecipient.message_set.id
    //       );
    //       if (messageSetIndex !== -1) {
    //         if (
    //           !this.messageSets[messageSetIndex].hasOwnProperty(
    //             'messageRecipients'
    //           )
    //         ) {
    //           // no messageRecipients present
    //           this.messageRecipientService
    //             .getMessageRecipientsForMessageSet(
    //               updatedMessageRecipient.message_set.id
    //             )
    //             .subscribe(
    //               (messageRecipients: MessageRecipient[]) => {
    //                 this.messageSets[messageSetIndex].messageRecipients =
    //                   messageRecipients;
    //                 this.messageSets[messageSetIndex].recipients = [];
    //                 messageRecipients.forEach(
    //                   (messageRecipient: MessageRecipient) => {
    //                     this.messageSets[messageSetIndex].recipients.push(
    //                       messageRecipient.recipient
    //                     );
    //                   }
    //                 );
    //               },
    //               (error) =>
    //                 this.logger.error(
    //                   `Failed to get messageRecipients for message set: ${updatedMessageRecipient.id}`
    //                 )
    //             );
    //         } else {
    //           // recipients already present
    //           // update with newly received update
    //           const messageRecipientIndex = this.messageSets[
    //             messageSetIndex
    //           ].messageRecipients.findIndex(
    //             (messageRecipient: MessageRecipient) =>
    //               messageRecipient.id === updatedMessageRecipient.id
    //           );
    //           this.messageSets[messageSetIndex].messageRecipients[
    //             messageRecipientIndex
    //           ] = updatedMessageRecipient;
    //         }

    //         this.updateReadCounts();
    //       } else {
    //         this.logger.warn(
    //           `Couldn't find message set id: ${updatedMessageRecipient.message_set.id}`
    //         );
    //       }

    //       // Is the message set being updated the currentMessageSet
    //       if (
    //         this.currentMessageSet &&
    //         this.currentMessageSet.id === updatedMessageRecipient.message_set.id
    //       ) {
    //         this.currentMessageSet = this.messageSets[messageSetIndex];
    //       }
    //     },
    //     (error) =>
    //       this.logger.error(
    //         `Failed to get message recipient: ${data.messge_recipient_id}`
    //       )
    //   );
  }

  loadInitialMessageSets() {
    this.loading = true;

    let customer: Customer;
    this.store$
      .select(getCurrentCustomer)
      .pipe(take(1))
      .subscribe((c: Customer) => (customer = c));

    this.messageSetService.getMessageSetsForCustomer(customer.id).subscribe(
      (response: ApiResponse<MessageSet>) => {
        this.messageSets = response.results;
        this.setTotalMessageCount(response.count);

        // Save next page if present
        if (response.next) {
          this.next = response.next;
        }

        this.loading = false;
        if (this.messageSets.length > 0) {
          this.setCurrentMessageSet(this.messageSets[0]);
        }
      },
      (error) => {
        this.logger.error(error);
        this.loading = false;
      }
    );
  }

  setTotalMessageCount(value) {
    if (value !== this.totalMessageCount) {
      this.totalMessageCount = value;
    }
  }

  setCurrentMessageSet(messageSet: MessageSet) {
    this.loadingRecipients = true;
    // Get index of the messageSet and see if it has recipients yet?
    let messageSetIndex = this.messageSets.findIndex(
      (ms) => ms.id === messageSet.id
    );
    if (messageSetIndex !== -1) {
      if (
        !this.messageSets[messageSetIndex].hasOwnProperty('messageRecipients')
      ) {
        this.messageRecipientService
          .getMessageRecipientsForMessageSet(messageSet.id)
          .subscribe((messageRecipients) => {
            this.messageSets[messageSetIndex].messageRecipients =
              messageRecipients;
            this.logger.info('messageRecipeints:' + messageRecipients);
            this.messageSets[messageSetIndex].recipients = [];
            messageRecipients.forEach((mr) =>
              this.messageSets[messageSetIndex].recipients.push(mr.recipient)
            );
            this.currentMessageSet = this.messageSets[messageSetIndex];
            this.updateReadCounts();
            this.loadingRecipients = false;
          });
      } else {
        // recipients already present
        this.currentMessageSet = this.messageSets[messageSetIndex];
        this.updateReadCounts();
        this.loadingRecipients = false;
      }
    } else {
      this.logger.warn("couldn't find message set id: " + messageSet.id);
      this.loadingRecipients = false;
    }
  }

  updateReadCounts() {
    this.readCounts.reset();
    if (
      this.currentMessageSet &&
      this.currentMessageSet.hasOwnProperty('messageRecipients')
    ) {
      this.currentMessageSet.messageRecipients.forEach((mr) => {
        switch (mr.status) {
          case 'unread' || 'pending':
            this.readCounts.pending += 1;
            break;
          case 'delivered':
            this.readCounts.delivered += 1;
            break;
          case 'read':
            this.readCounts.read += 1;
            break;
          case 'revoked':
            this.readCounts.revoked += 1;
            break;
          case 'expired':
            this.readCounts.expired += 1;
            break;
        }
      });
    }
  }

  addMoreMessages() {
    if (this.busy) {
      return;
    }
    this.busy = true;

    if (this.next) {
      this.http.get(this.next).subscribe((responseJson: any) => {
        this.messageSets.push.apply(this.messageSets, responseJson.results);
        if (responseJson.next) {
          this.next = responseJson.next;
        } else {
          responseJson.next = null;
        }
        // reset busy flag
        this.busy = false;
      });
    } else {
      // No next return
      this.busy = false;
      return;
    }
  }

  doSearch() {
    this.loading = true;
    this.messageSets = [];
    this.currentMessageSet = null;

    let customer: Customer;
    this.store$
      .select(getCurrentCustomer)
      .pipe(take(1))
      .subscribe((c: Customer) => (customer = c));

    let params = new HttpParams();
    params = params.set('search', this.searchQuery);
    params = params.set('customer', customer.id);

    // params = params.set(
    //   'site',
    //   this.filterSite ? this.filterSite.id.toString() : null
    // );
    // params = params.set(
    //   'recipient',
    //   this.filterRecipient ? this.filterRecipient.id.toString() : null
    // );
    // params = params.set(
    //   'sender',
    //   this.filterSender ? this.filterSender.id.toString() : null
    // );
    // params = params.set(
    //   'frm',
    //   this.filterFromDate ? this.filterFromDate.toString() : null
    // );
    // params = params.set(
    //   'to',
    //   this.filterToDate ? this.filterToDate.toString() : null
    // );

    this.messageSetService.doSearch(params).subscribe(
      (response: any) => {
        if (response.count > 0) {
          this.messageSets = response.results;
          this.setTotalMessageCount(response.count);
          // Save next page if present
          if (response.next) {
            this.next = response.next;
          } else {
            this.next = null;
          }
          this.setCurrentMessageSet(this.messageSets[0]);
          this.loading = false;
        } else {
          // No results found
          this.loading = false;
          this.messageSets.length = 0;
          this.setTotalMessageCount(0);
        }
      },
      (failure) => {
        this.logger.error('Failed to execute search!');
      }
    );
  }

  clearSearch() {
    this.searchQuery = '';
    this.searchExecuted = false;
    this.loadInitialMessageSets();
  }

  clearFilterRecipient() {
    this.filterRecipient = null;
    this.doSearch();
  }

  clearFilterSite() {
    this.filterSite = null;
    this.doSearch();
  }

  clearFilterSender() {
    this.filterSender = null;
    this.doSearch();
  }

  clearFilterFromDate() {
    this.filterFromDate = null;
    this.doSearch();
  }

  clearFilterToDate() {
    this.filterToDate = null;
    this.doSearch();
  }

  setFilterRecipient(recipient) {
    this.filterRecipient = recipient;
    this.doSearch();
  }

  setFilterSite(site) {
    this.filterSite = site;
    this.doSearch();
  }

  setFilterSender(sender) {
    this.filterSender = sender;
    this.doSearch();
  }

  setFilterFromDate(fromDate) {
    this.filterFromDate = fromDate;
    this.doSearch();
  }

  setFilterToDate(toDate) {
    this.filterToDate = toDate;
    this.doSearch();
  }
}
