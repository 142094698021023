<div id="preview-container" *ngIf="messageSet">
  <div id="background">
    <div id="screen">
      <div id="overlay" [ngStyle]="{ 'width.px': width, 'height.px': height }">
        <div
          class="overlay-header-avatar"
          *ngIf="$any(messageSet.sender)?.avatar_large"
        >
          <app-avatar-thumbnail
            [user]="$any(messageSet.sender)"
            size="140"
            [hidePresence]="true"
          ></app-avatar-thumbnail>
        </div>
        <div class="overlay-header" [ngClass]="{ center: headerCenter }">
          {{ $any(messageSet.sender)?.first_name }}
          {{ $any(messageSet.sender)?.last_name }}
          <!-- <cm-message-count
            [totalMessages]="totalMessages"
            [currentMessage]="currentMessage"
            *ngIf="showMessageCount"
          ></cm-message-count> -->
        </div>
        <div class="overlay-content" [ngClass]="{ 'justify-top': justifyTop }">
          <div
            class="message-set-wrapper"
            [ngClass]="{
              center:
                currentMessage?.images.length === 0 &&
                currentMessage?.videos.length === 0
            }"
          >
            <div
              class="thumbnail-container"
              *ngIf="
                currentMessage?.images.length > 0 ||
                currentMessage?.videos.length > 0
              "
            >
              <div
                class="image-thumbnail"
                *ngIf="currentMessage?.images.length > 0"
              >
                <img [src]="currentMessage?.images[0].large_url" />
              </div>
              <div
                class="video-thumbnail"
                *ngIf="currentMessage?.videos.length > 0"
              >
                <img
                  [src]="
                    currentMessage?.videos[0].encodings[0].thumbnails[0].file
                  "
                  *ngIf="currentMessage?.videos[0].encodings.length > 0"
                />
                <svg
                  class="icon grid"
                  viewBox="0 0 32 32"
                  *ngIf="currentMessage?.videos[0].encodings.length == 0"
                >
                  <use xlink:href="#icon-video"></use>
                </svg>
              </div>
              <div class="key">
                <div class="zoom-icon"></div>
                <span>{{
                  currentMessage.images.length > 0 ? "zoom" : "play"
                }}</span>
              </div>
            </div>

            <div class="message-content">
              <div class="subject">
                <h2
                  *ngIf="currentMessage?.images[0] || currentMessage?.videos[0]"
                >
                  {{ currentMessage?.subject }}
                </h2>
                <h2
                  *ngIf="
                    !currentMessage?.images[0] && !currentMessage?.videos[0]
                  "
                >
                  {{ currentMessage?.subject }}
                </h2>
              </div>
              <div class="body">
                <p
                  *ngIf="currentMessage?.images[0] || currentMessage?.videos[0]"
                >
                  {{ currentMessage?.body }}
                </p>
                <p
                  *ngIf="
                    !currentMessage?.images[0] && !currentMessage?.videos[0]
                  "
                >
                  {{ currentMessage?.body }}
                </p>
              </div>
            </div>
          </div>
          <div class="choices-wrapper">
            <table class="choice-selector">
              <tr *ngFor="let choice of currentMessage?.choices">
                <td>
                  <div class="choice">
                    <h4 class="label">
                      {{ choice.choice_text }}
                    </h4>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
