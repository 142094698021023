import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Customer } from '../models/customer.model';
import { ApiResponse } from '../models/api-response';

@Injectable()
export class CustomerService {
  endpointUrl: string = environment.apiUrl + '/customer';

  constructor(private http: HttpClient) {}

  getCustomers(
    filter: string = '',
    sortField: string = 'name',
    sortDirection: string = 'asc',
    pageIndex: number = 1,
    pageSize: number = 200
  ) {
    let params = new HttpParams();
    params = params.set('page_size', pageSize.toString());
    params = params.set('ordering', sortField + '__' + sortDirection);
    params = params.set('page', pageIndex.toString());
    params = params.set('search', filter);
    return this.http
      .get<ApiResponse<Customer>>(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  createCustomer(customer) {
    return this.http.post<Customer>(this.endpointUrl, customer);
  }

  updateCustomer(customer: Customer) {
    return this.http
      .patch<Customer>(this.endpointUrl + '/' + customer.id, customer)
      .pipe(catchError(this.handleError));
  }

  deleteCustomer(customer: Customer) {
    const url = `${this.endpointUrl}/${customer.id}`;
    return this.http.delete(url);
  }

  getCustomer(customerId: number) {
    return this.http
      .get<Customer>(this.endpointUrl + '/' + customerId)
      .pipe(catchError(this.handleError));
  }

  getMyPermissionsOnCustomer(customerId: number) {
    return this.http
      .get<string[]>(`${this.endpointUrl}/${customerId}/perms`)
      .pipe(catchError(this.handleError));
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
