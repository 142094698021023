import { MessageRecipient } from './../../../core/models/message-recipient.model';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MessageRecipientService } from 'src/app/core/services/message-recipient.service';
import { LoggerService } from 'src/app/core/services/logger.service';

@Component({
  selector: 'cm-message-recipient-status',
  templateUrl: './message-recipient-status.component.html',
  styleUrls: ['./message-recipient-status.component.scss'],
})
export class MessageRecipientStatusComponent implements OnInit, OnChanges {
  @Input() messageRecipient: MessageRecipient;

  revoking: boolean = false;

  constructor(
    private messageRecipientService: MessageRecipientService,
    private logger: LoggerService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // this.logger.info(`recipient-status: ${JSON.stringify(changes)}`);
  }

  revoke(messageRecipient) {
    var that = this;
    this.revoking = true;
    // this.messageRecipientService.revoke(messageRecipient)
    //     .then(function(result){
    //         that.revoking = false;
    //     }, function(error){
    //         alert('Error revoking messageset: ' + error);
    //         that.revoking = false;
    //     });
  }
}
