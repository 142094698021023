import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../models/api-response';
import { AssistanceProvider } from '../models/assistance-provider.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssistanceProviderService {
  endpointUrl: string = environment.apiUrl + '/assistance-provider';

  constructor(private http: HttpClient) {}

  getAssistanceProvidersForCustomer(
    customerId: number
  ): Observable<ApiResponse<AssistanceProvider>> {
    let params = new HttpParams();
    params = params.set('content_type', 'customer');
    params = params.set('object_id', customerId);
    return this.http.get<ApiResponse<AssistanceProvider>>(this.endpointUrl, {
      params,
    });
  }

  createAssistanceProvider(
    assistanceProvider: AssistanceProvider
  ): Observable<AssistanceProvider> {
    return this.http.post<AssistanceProvider>(
      this.endpointUrl,
      assistanceProvider
    );
  }

  updateAssistanceProvider(
    assistanceProvider: AssistanceProvider
  ): Observable<AssistanceProvider> {
    return this.http.patch<AssistanceProvider>(
      `${this.endpointUrl}/${assistanceProvider.id}`,
      assistanceProvider
    );
  }

  deleteAssistanceProvider(
    assistanceProvider: AssistanceProvider
  ): Observable<any> {
    return this.http.delete<AssistanceProvider>(
      `${this.endpointUrl}/${assistanceProvider.id}`
    );
  }
}
