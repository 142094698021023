import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageImage } from 'src/app/core/models/message-image.model';

@Component({
  selector: 'cm-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  public dialogRef: MatDialogRef<ImageViewerComponent>;
  public image: MessageImage;

  constructor() {}

  ngOnInit(): void {
    console.log(JSON.stringify(this.image));
  }
}
