import { AcceptCallDialogComponent } from './../../shared/components/accept-call-dialog/accept-call-dialog.component';
import { ConferencingService } from './conferencing.service';
import { UserChannelService } from './user-channel.service';
import { CustomerPresenceService } from './customer-presence.service';
import { GlobalPresenceService } from './global-presence.service';
import { PusherService } from './pusher.service';
import { LoggerService } from './logger.service';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { MessageboxService } from 'src/app/manager/messages/messagebox/messagebox.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class OrchestratorService {
  private loggedInSubscription: Subscription;
  private loggedOutSubscription: Subscription;

  constructor(
    private auth: AuthService,
    private logger: LoggerService,
    private pusher: PusherService,
    private globalPresence: GlobalPresenceService,
    private customerPresence: CustomerPresenceService,
    private userChannel: UserChannelService,
    private conferencing: ConferencingService,
    private messageboxService: MessageboxService,
    private translate: TranslateService
  ) {
    // subscribe to login/logout events
    this.loggedInSubscription = this.auth.loggedIn$.subscribe(
      this.onLoggedIn.bind(this),
      this.onLoggedInError.bind(this)
    );

    this.loggedOutSubscription = this.auth.loggedOut$.subscribe(
      this.onLoggedOut.bind(this),
      this.onLoggedOutError.bind(this)
    );

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en-GB');
  }

  onLoggedIn() {
    // this.logger.info(`orchestrator: user logged in`);
    // this.pusher.init();
    // this.globalPresence.subscribe();
    // this.customerPresence.subscribe();
    // this.conferencing.subscribe();
    // this.messageboxService.subscribe();
    // this.userChannel.subscribe();
    //this.translate.use(this.auth.user.locale);
  }

  onLoggedInError(err) {
    this.logger.error(
      `orchestrator: user logged in error - ${JSON.stringify(err)}`
    );
  }

  onLoggedOut() {
    this.logger.info(`orchestrator: user logged in`);
    this.globalPresence.unsubscribe();
    this.customerPresence.unsubscribe();
    //this.conferencing.unsubscribe();
    //this.messageboxService.unsubscribe();
    this.userChannel.unsubscribe();
    this.pusher.disconnect();
  }

  onLoggedOutError(err) {
    this.logger.error(
      `orchestrator: user logged out error - ${JSON.stringify(err)}`
    );
  }
}
