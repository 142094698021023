import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  exhaustMap,
  map,
  catchError,
  withLatestFrom,
  filter,
  concatMap,
  tap,
} from 'rxjs/operators';
import { State } from './reducer';
import * as PusherActions from './pusher.actions';
import * as ConfererencingActions from './conferencing.actions';
import * as UserActions from '../login/state/user.actions';
import { getProfile, UserState } from '../login/state/user.reducers';
import { GlobalPresenceService } from '../core/services/global-presence.service';
import { CustomerPresenceService } from '../core/services/customer-presence.service';
import { PusherService } from '../core/services/pusher.service';
import { ConferencingService } from '../core/services/conferencing.service';
import { UserChannelService } from '../core/services/user-channel.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../core/services/auth.service';

const ROOT_EFFECTS_INIT = '@ngrx/effects/init';

@Injectable()
export class PusherEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private pusherService: PusherService,
    private globalPresenceService: GlobalPresenceService,
    private customerPresenceService: CustomerPresenceService,
    private conferencingService: ConferencingService,
    private userChannelService: UserChannelService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        tap((action: Action) => {
          console.log(`ROOT EFFECTS INIT`);
        }),
        withLatestFrom(this.store$.select(getProfile)),
        map(([action, profile]) => {
          if (!this.authService.isTokenExpired(profile.token)) {
            this.store$.dispatch(
              UserActions.loginSuccess({
                user: profile.user,
                token: profile.token,
              })
            );
          } else {
            console.log('User not authenticated');
            this.store$.dispatch(UserActions.logout());
          }
        })
      ),
    { dispatch: false }
  );

  connectToPusher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PusherActions.connectToPusher),
      withLatestFrom(this.store$.select(getProfile)),
      map(([action, profile]) => {
        this.pusherService.init(profile.token);
        return PusherActions.connectToPusherSuccess();
      })
    )
  );

  subscribeToChannels$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PusherActions.connectToPusherSuccess),
        withLatestFrom(this.store$.select(getProfile)),
        map(([action, profile]) => {
          this.store$.dispatch(PusherActions.subscribeGlobalChannel());
          this.store$.dispatch(PusherActions.subscribeCustomerChannel());
          this.store$.dispatch(PusherActions.subscribeUserChannel());
          this.store$.dispatch(
            ConfererencingActions.subscribeConferencingChannel({
              customer: profile.customer,
              user: profile.user,
            })
          );
          this.store$.dispatch(
            ConfererencingActions.subscribeConferencingRoomChannel({
              customer: profile.customer,
            })
          );
          this.store$.dispatch(
            ConfererencingActions.subscribeConferencingCompositionChannel({
              customer: profile.customer,
            })
          );
        })
      ),
    { dispatch: false }
  );

  globalPresence$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PusherActions.subscribeGlobalChannel),
        map((action: Action) => {
          this.globalPresenceService.subscribe();
        })
      ),
    {
      dispatch: false,
    }
  );

  customerPresence$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PusherActions.subscribeCustomerChannel),
        withLatestFrom(this.store$.select(getProfile)),
        map(([action, profile]) => {
          this.customerPresenceService.subscribe(profile.customer);
        })
      ),
    {
      dispatch: false,
    }
  );

  userChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PusherActions.subscribeUserChannel),
        withLatestFrom(this.store$.select(getProfile)),
        map(([action, profile]) => {
          this.userChannelService.subscribe(profile.user);
        })
      ),
    {
      dispatch: false,
    }
  );

  userChannelCallRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PusherActions.userChannelCallRequest),
        map((callRequest: any) =>
          console.log(`CALL REQUEST: ${JSON.stringify(callRequest)}`)
        )
      ),
    { dispatch: false }
  );
}
