import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MessageSet } from 'src/app/core/models/message-set.model';
import { Message } from 'src/app/core/models/message.model';
import { User } from 'src/app/core/models/user.model';

@Component({
  selector: 'cm-message-set-preview',
  templateUrl: './message-set-preview.component.html',
  styleUrls: ['./message-set-preview.component.scss'],
})
export class MessageSetPreviewComponent implements OnInit, OnChanges {
  @Input() messageSet: MessageSet;

  currentMessage: Message = null;
  currentMessageIndex: number = 0;

  public header: boolean = true;
  public headerCenter: boolean = false;
  public headerAvatar: boolean = true;
  public justifyTop: boolean = false;
  public width: 900;
  public height: 700;

  constructor() {}

  ngOnInit(): void {
    if (this.messageSet) {
      this.currentMessage = this.messageSet.messages[this.currentMessageIndex];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.messageSet && changes.messageSet.currentValue) {
      this.messageSet = changes.messageSet.currentValue;
      this.currentMessage = this.messageSet.messages[this.currentMessageIndex];
    }
  }

  currentMessageHasMedia() {
    if (this.currentMessage) {
      return (
        this.currentMessage.images.length || this.currentMessage.videos.length
      );
    } else {
      return false;
    }
  }

  currentMessageHasImages() {
    if (this.currentMessage) {
      return this.currentMessage.images.length;
    } else {
      return false;
    }
  }

  currentMessageHasVideos() {
    if (this.currentMessage) {
      return this.currentMessage.videos.length;
    } else {
      return false;
    }
  }

  currentMessageIsFirst() {
    if (this.messageSet) {
      return this.messageSet.messages[0] == this.currentMessage;
    } else {
      return null;
    }
  }

  currentMessageIsLast() {
    if (this.messageSet) {
      return (
        this.messageSet.messages[this.messageSet.messages.length - 1] ==
        this.currentMessage
      );
    } else {
      return null;
    }
  }

  next() {
    if (
      this.messageSet.messages[this.messageSet.messages.length - 1] !==
      this.currentMessage
    ) {
      this.currentMessageIndex += 1;
      this.currentMessage = this.messageSet.messages[this.currentMessageIndex];
    }
  }

  previous() {
    if (this.messageSet.messages[0] !== this.currentMessage) {
      this.currentMessageIndex -= 1;
      this.currentMessage = this.messageSet.messages[this.currentMessageIndex];
    }
  }
}
