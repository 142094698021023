import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import * as fromUser from '../../login/state/user.reducers';
import * as UserActions from '../../login/state/user.actions';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { BaseUser } from '../models/base-user.model';

@Injectable()
export class StaffOnlyGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate() {
    return this.store.select(fromUser.getCurrentUser).pipe(
      map((user: BaseUser) => {
        if (user.user_type !== 'staff') {
          this.store.dispatch(UserActions.loginRedirect());
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
