<mat-toolbar>{{
    "COMPONENTS.REQUEST_CALL_DIALOG.HEADER" | translate
  }}
</mat-toolbar>
<mat-list class="user-list">
  <h3 matSubheader>
    {{ "COMPONENTS.REQUEST_CALL_DIALOG.SUB_HEADER" | translate }}
  </h3>
  <mat-list-item
    *ngFor="let resident of onlineResidents$ | async"
    (click)="requestCall(resident)"
  >
    <app-avatar-thumbnail
      matListAvatar
      [user]="resident"
      [size]="40"
    ></app-avatar-thumbnail>
    <h3 matLine>{{ resident.first_name }} {{ resident.last_name }}</h3>
    <p matLine>
      <span>{{ resident.username }}</span>
    </p>
    <mat-icon id="screen-icon" [style.color]="resident.set_top_box.is_screen_on ? 'rgb(124, 200, 0)' : 'red'">{{
        resident.set_top_box.is_screen_on ? "tv" : "tv_off"
      }}
    </mat-icon>
    <mat-chip-list>
      <mat-chip color="primary">{{ resident.user_type }}</mat-chip>
    </mat-chip-list>
  </mat-list-item>
</mat-list>
