import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Staff } from '../models/staff.model';

@Injectable()
export class StaffService {
  endpointUrl: string = environment.apiUrl + '/staff';

  constructor(private http: HttpClient) {}

  getStaffs(): Observable<Staff[]> {
    return this.http
      .get(this.endpointUrl)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getStaff(staffId) {
    return this.http
      .get<Staff>(this.endpointUrl + '/' + staffId)
      .pipe(catchError(this.handleError));
  }

  createStaff(staff: Staff) {
    return this.http.post<Staff>(this.endpointUrl, staff);
  }

  updateStaff(staff: Staff) {
    return this.http
      .put(this.endpointUrl + '/' + staff.id, staff)
      .pipe(catchError(this.handleError));
  }

  deleteStaff(staff: Staff) {
    return this.http
      .delete(this.endpointUrl + '/' + staff.id)
      .pipe(catchError(this.handleError));
  }

  setStaffPassword(staff: Staff) {
    const url = `${this.endpointUrl}/${staff.id}/set_password`;
    return this.http.patch(url, { password: staff.password });
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
