import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  exhaustMap,
  map,
  catchError,
  withLatestFrom,
  concatMap,
  tap,
  filter,
  first,
  mergeMap,
} from 'rxjs/operators';
import { Hardware } from 'src/app/core/models/hardware.model';
import { Resident } from 'src/app/core/models/resident.model';
import { HardwareService } from 'src/app/core/services/hardware.service';
import { ResidentService } from 'src/app/core/services/resident.service';
import { getCurrentCustomer } from 'src/app/login/state/user.reducers';
import * as ResidentActions from './resident.actions';
import { State } from 'src/app/state';
import { Router } from '@angular/router';
import { SupporterService } from 'src/app/core/services/supporter.service';
import { Supporter } from 'src/app/core/models/supporter.model';
import { residentSupportersLoaded } from './resident.selectors';
import { NotificationService } from '../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import * as SiteActions from '../../sites/state/site.actions';
import { ResidentMetric } from './resident.reducers';

@Injectable()
export class ResidentEffects {
  translateStrings: any;
  residentPrefix = 'MANAGER.RESIDENTS.NOTIFICATIONS.';
  hardwarePrefix = 'MANAGER.RESIDENTS.HARDWARE.NOTIFICATIONS.';

  constructor(
    private actions$: Actions,
    private residentService: ResidentService,
    private hardwareService: HardwareService,
    private supporterService: SupporterService,
    private router: Router,
    private store$: Store<State>,
    private notification: NotificationService,
    private translate: TranslateService
  ) {
    const tokens = [
      `${this.residentPrefix}CREATED_SUCCESS`,
      `${this.residentPrefix}UPDATED_SUCCESS`,
      `${this.residentPrefix}DELETED_SUCCESS`,
      `${this.hardwarePrefix}CREATED_SUCCESS`,
      `${this.hardwarePrefix}DELETED_SUCCESS`,
    ];
    setTimeout(() => {
      this.translate.get(tokens).subscribe((translations) => {
        this.translateStrings = translations;
      });
    }, 1000);
  }

  getResidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.getResidents),
      withLatestFrom(this.store$.select(getCurrentCustomer)),
      concatMap(([action, customer]) =>
        this.residentService.getResidentsForCustomer(customer.id).pipe(
          map((residents: Resident[]) => {
            return ResidentActions.getResidentsSuccess({ residents });
          }),
          catchError((error) =>
            of(ResidentActions.getResidentsFailure({ error }))
          )
        )
      )
    )
  );

  createResident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.createResident),
      withLatestFrom(this.store$.select(getCurrentCustomer)),
      concatMap(([action, customer]) => {
        this.notification.showProgressDialog();
        const res = { ...action.resident, customer: customer.id };
        return this.residentService.createResident(res).pipe(
          map((resident: Resident) => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.residentPrefix}CREATED_SUCCESS`]
            );
            return ResidentActions.createResidentSuccess({ resident });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(ResidentActions.createResidentFailure({ error }));
          })
        );
      })
    )
  );

  updateResident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.updateResident),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.residentService.updateResident(action.resident).pipe(
          map(() => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.residentPrefix}UPDATED_SUCCESS`]
            );
            return ResidentActions.updateResidentSuccess({
              resident: action.resident,
            });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(ResidentActions.updateResidentFailure({ error }));
          })
        );
      })
    )
  );

  deleteResident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.deleteResident),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.residentService.deleteResident(action.resident).pipe(
          map(() => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.residentPrefix}DELETED_SUCCESS`]
            );
            return ResidentActions.deleteResidentSuccess({
              resident: action.resident,
            });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(ResidentActions.deleteResidentFailure({ error }));
          })
        );
      })
    )
  );

  createUpdateResidentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ResidentActions.createResidentSuccess,
          ResidentActions.updateResidentSuccess,
          ResidentActions.createHardwareSuccess,
          ResidentActions.deleteHardwareSuccess
        ),
        tap((action) => this.router.navigate(['/manager/residents']))
      ),
    { dispatch: false }
  );

  refreshResident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.refreshResident),
      exhaustMap((action) =>
        this.residentService.getResident(action.id).pipe(
          map((resident: Resident) => {
            return ResidentActions.refreshResidentSuccess({ resident });
          }),
          catchError((error) =>
            of(ResidentActions.refreshResidentFailure({ error }))
          )
        )
      )
    )
  );

  createHardware$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.createHardware),
      withLatestFrom(this.store$.select(getCurrentCustomer)),
      concatMap(([action, customer]) => {
        this.notification.showProgressDialog();
        return this.hardwareService.createHardware(action.hardware).pipe(
          map((hardware: Hardware) => {
            this.notification.closeProgressDialog();
            this.notification.showSnackBar(
              this.translateStrings[`${this.hardwarePrefix}CREATED_SUCCESS`]
            );
            return ResidentActions.createHardwareSuccess({ hardware });
          }),
          catchError((error) => {
            this.notification.closeProgressDialog();
            return of(ResidentActions.createHardwareFailure({ error }));
          })
        );
      })
    )
  );

  deleteHardware$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.deleteHardware),
      concatMap((action) => {
        this.notification.showProgressDialog();
        return this.hardwareService
          .deleteHardware(action.resident.set_top_box.id)
          .pipe(
            map(() => {
              this.notification.closeProgressDialog();
              this.notification.showSnackBar(
                this.translateStrings[`${this.hardwarePrefix}DELETED_SUCCESS`]
              );
              return ResidentActions.deleteHardwareSuccess({
                resident: action.resident,
              });
            }),
            catchError((error) => {
              this.notification.closeProgressDialog();
              return of(ResidentActions.deleteHardwareFailure({ error }));
            })
          );
      })
    )
  );

  getSupportersForResident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.getSupportersForResident),
      concatMap((action) =>
        this.supporterService.getSupportersForResident(action.id).pipe(
          map((supporters: Supporter[]) => {
            return ResidentActions.getSupportersForResidentSuccess({
              id: action.id,
              supporters,
            });
          }),
          catchError((error) =>
            of(ResidentActions.getSupportersForResidentFailure({ error }))
          )
        )
      )
    )
  );

  loadSupportersForResident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.loadSupportersForResident),
      map((action) => action.id),
      concatMap((id) =>
        this.store$.pipe(
          select(residentSupportersLoaded(id)),
          first(),
          map((hasSupporters) => [id, hasSupporters])
        )
      ),
      filter(([id, hasSupporters]) => !hasSupporters),
      map(([id, hasSupporters]: [number, boolean]) => {
        return ResidentActions.getSupportersForResident({
          id,
        });
      })
    )
  );

  getMetricsForResident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.getResidentMetrics),
      concatMap((action) =>
        this.residentService
          .getMetricsForResident(
            action.residentId,
            action.startDate,
            action.endDate
          )
          .pipe(
            map((residentMetric: ResidentMetric) => {
              return ResidentActions.getResidentMetricsSuccess({
                residentMetric,
              });
            }),
            catchError((error) =>
              of(ResidentActions.getResidentMetricsFailure({ error }))
            )
          )
      )
    )
  );

  getMetricsForResidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.getResidentsSuccess),
      mergeMap((action) => {
        const date = new Date();
        return action.residents.map((resident) =>
          ResidentActions.getResidentMetrics({
            residentId: resident.id,
            startDate: new Date(date.getFullYear(), date.getMonth(), 1),
            endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
          })
        );
      })
    )
  );
}
