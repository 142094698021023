import { createReducer, on } from '@ngrx/store';
import { Site } from 'src/app/core/models/site.model';
import * as SiteActions from './site.actions';

export const sitesFeatureKey = 'sites';

export interface SiteMetric {
  site_id: number;
  message_set_count: number;
  conferencing_count: number;
  start_date: Date;
  end_date: Date;
}

export interface SiteState {
  sites: Site[];
  siteMetrics: SiteMetric[];
  permissions: Record<number, string[]>;
  createSiteFailureMessage: string;
  updateSiteFailureMessage: string;
  deleteSiteFailureMessage: string;
}

const initialState: SiteState = {
  sites: [],
  siteMetrics: [],
  permissions: {},
  createSiteFailureMessage: '',
  updateSiteFailureMessage: '',
  deleteSiteFailureMessage: '',
};

export const siteReducer = createReducer<SiteState>(
  initialState,
  on(SiteActions.getSitesSuccess, (state, action) => ({
    ...state,
    sites: action.sites,
  })),
  on(SiteActions.getSitesFailure, (state) => ({
    ...state,
    failureMessage: 'Failed to get sites',
  })),
  on(SiteActions.createSiteSuccess, (state, action) => ({
    ...state,
    sites: [...state.sites, action.site],
  })),
  on(SiteActions.createSiteFailure, (state, action) => ({
    ...state,
    createSiteFailureMessage: action.error.message,
  })),
  on(SiteActions.getMyPermissionsForSiteSuccess, (state, action) => {
    const permissions = { ...state.permissions };
    permissions[action.siteId] = action.permissions;
    return { ...state, permissions };
  }),
  on(SiteActions.getMyPermissionsForSiteFailure, (state, action) => ({
    ...state,
    createSiteFailureMessage: action.error.message,
  })),
  on(SiteActions.updateSiteSuccess, (state, action) => {
    const idx = state.sites.findIndex((site) => site.id === action.site.id);
    const sites = [...state.sites];
    sites[idx] = action.site;
    return { ...state, sites };
  }),
  on(SiteActions.updateSiteFailure, (state, action) => ({
    ...state,
    updateSiteFailureMessage: action.error.message,
  })),
  on(SiteActions.deleteSiteSuccess, (state, action) => ({
    ...state,
    sites: [...state.sites.filter((site) => site.id !== action.site.id)],
  })),
  on(SiteActions.deleteSiteFailure, (state, action) => ({
    ...state,
    deleteSiteFailureMessage: action.error.message,
  })),
  on(SiteActions.deleteSiteSuccess, (state, action) => ({
    ...state,
    sites: [...state.sites.filter((site) => site.id !== action.site.id)],
  })),
  on(SiteActions.getSiteMetricsSuccess, (state, action) => {
    let siteMetrics = [...state.siteMetrics];
    const idx = siteMetrics.findIndex(
      (metric) => metric.site_id === action.siteMetric.site_id
    );
    if (idx === -1) {
      siteMetrics.push(action.siteMetric);
    } else {
      siteMetrics = siteMetrics.map((metric) =>
        metric.site_id === action.siteMetric.site_id
          ? action.siteMetric
          : metric
      );
    }
    return { ...state, siteMetrics };
  })
);
