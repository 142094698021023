<div
  class="message-recipient-status"
  matTooltip="{{ messageRecipient.recipient.first_name }} {{
    messageRecipient.recipient.last_name
  }}"
>
  <div>
    <div class="avatar-container">
      <app-avatar-thumbnail
        [user]="messageRecipient.recipient"
        [size]="40"
      ></app-avatar-thumbnail>

      <div
        class="status"
        [ngClass]="
          {
            read: 'read',
            pending: 'pending',
            delivered: 'delivered',
            revoked: 'revoked'
          }[messageRecipient.status]
        "
      >
        {{ messageRecipient.status }}
      </div>
    </div>

    <div
      class="revoke"
      *ngIf="
        messageRecipient.status == 'pending' ||
        messageRecipient.status == 'unread' ||
        messageRecipient.status == 'delivered'
      "
    >
      <button
        mat-button
        mat-flat-button
        color="accent"
        (click)="revoke(messageRecipient)"
        [disabled]="revoking"
      >
        Revoke
      </button>
    </div>
  </div>
</div>
