import { Component, Input, OnInit } from '@angular/core';
import { UploadOutput } from 'ngx-uploader';

@Component({
  selector: 'cm-media-library-video-upload',
  templateUrl: './media-library-video-upload.component.html',
  styleUrls: ['./media-library-video-upload.component.scss'],
})
export class MediaLibraryVideoUploadComponent implements OnInit {
  @Input() output: UploadOutput;
  @Input() encodingMessage: string;

  constructor() {}

  ngOnInit(): void {}
}
