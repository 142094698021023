import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessageImage } from 'src/app/core/models/message-image.model';
import { ImageViewerComponent } from '../../image-viewer/image-viewer.component';

@Component({
  selector: 'cm-image-thumbnails',
  templateUrl: './image-thumbnails.component.html',
  styleUrls: ['./image-thumbnails.component.scss'],
})
export class ImageThumbnailsComponent implements OnInit {
  @Input() images: Array<MessageImage> = [];

  constructor(
    private dialog: MatDialog,
    private viewContainerReference: ViewContainerRef
  ) {}

  ngOnInit(): void {}

  onImageClicked(image: MessageImage) {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerReference;
    let imageViewerDialog = this.dialog.open(ImageViewerComponent, config);
    imageViewerDialog.componentInstance.dialogRef = imageViewerDialog;
    imageViewerDialog.componentInstance.image = image;
  }
}
