import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { asyncScheduler, EMPTY, of, timer } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Customer } from 'src/app/core/models/customer.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConferencingService } from 'src/app/core/services/conferencing.service';
import { CustomerPresenceService } from 'src/app/core/services/customer-presence.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { GlobalPresenceService } from 'src/app/core/services/global-presence.service';
import { PusherService } from 'src/app/core/services/pusher.service';
import { UserChannelService } from 'src/app/core/services/user-channel.service';
import { State } from 'src/app/state';
import { selectSubscription } from 'src/app/state/conferencing.selectors';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';
import * as PusherActions from '../../state/pusher.actions';
import * as SiteActions from '../../manager/sites/state/site.actions';
import * as ResidentActions from '../../manager/residents/state/resident.actions';
import { LoginResponse } from './user.reducers';
import { ResidentService } from 'src/app/core/services/resident.service';
import { CustomerAdminService } from 'src/app/core/services/customer-admin.service';
import { Administrator } from 'src/app/core/models/administrator.model';
import { HardwareService } from 'src/app/core/services/hardware.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private customerService: CustomerService,
    private residentService: ResidentService,
    private customerAdminService: CustomerAdminService,
    private router: Router,
    private pusher: PusherService,
    private globalPresence: GlobalPresenceService,
    private customerPresence: CustomerPresenceService,
    private userChannel: UserChannelService,
    private conferencing: ConferencingService,
    private translateService: TranslateService,
    private hardwareService: HardwareService,
    private store$: Store<State>,
    private jwtHelper: JwtHelperService
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),
      exhaustMap((action) =>
        this.authService.login(action.username, action.password).pipe(
          map((loginResponse: LoginResponse) => {
            return UserActions.loginSuccess(loginResponse);
          }),
          catchError((error) => of(UserActions.loginFailure({ error })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginSuccess),
        tap((action) => {
          this.store$.dispatch(PusherActions.connectToPusher());
          switch (action.user.user_type) {
            case 'staff':
              this.router.navigate(['/superuser']);
              break;
            case 'customeradmin':
              if (this.router.url === '/login') {
                this.router.navigate(['/manager/dashboard/overview']);
              }
              break;
            case 'supporter':
              alert('Supporter accounts are not yet supported here!');
              break;
          }
        })
      ),
    { dispatch: false }
  );

  $logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        withLatestFrom(this.store$.select(selectSubscription)),
        tap(([action, subscription]) => {
          // do logout stuff here
          // unsubscribe websocket channels?
          this.globalPresence.unsubscribe();
          this.customerPresence.unsubscribe();
          if (subscription) {
            this.conferencing.unsubscribe(subscription.channelName);
          }
          // TODO: Unsubscribe message box channel for all sites
          // this.messageboxService.unsubscribe();

          this.userChannel.unsubscribe();
          this.pusher.disconnect();
          // cleanup local storage
          localStorage.clear();
          // navigate to login
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginRedirect),
        tap((action) => this.router.navigate(['/login']))
      ),
    { dispatch: false }
  );

  setLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginSuccess),
        map((action) =>
          this.translateService.setDefaultLang(action.user.locale)
        )
      ),
    { dispatch: false }
  );

  getCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess),
      exhaustMap((action) =>
        this.customerService.getCustomer(action.user.customer).pipe(
          map((customer: Customer) => {
            return UserActions.getCustomerSuccess(customer);
          }),
          catchError((error) => of(UserActions.getCustomerFailure({ error })))
        )
      )
    )
  );

  getCustomerPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCustomerSuccess),
      exhaustMap((customer: Customer) =>
        this.customerService.getMyPermissionsOnCustomer(customer.id).pipe(
          map((permissions: string[]) => {
            return UserActions.getCustomerPermissionsSuccess({ permissions });
          }),
          catchError((error) =>
            of(UserActions.getCustomerPermissionsFailure({ error }))
          )
        )
      )
    )
  );

  getSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCustomerSuccess),
      map((action) => SiteActions.getSites())
    )
  );

  getResidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCustomerSuccess),
      map((action) => ResidentActions.getResidents())
    )
  );

  getAdministrators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCustomerSuccess),
      exhaustMap((customer: Customer) =>
        this.customerAdminService
          .getAdministratorsForCustomer(customer.id)
          .pipe(
            map((administrators: Administrator[]) => {
              return UserActions.getAdministratorsSuccess({ administrators });
            }),
            catchError((error) =>
              of(UserActions.getAdministratorsFailure({ error }))
            )
          )
      )
    )
  );

  checkTokenAfterLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess),
      map((action) => {
        if (this.jwtHelper.isTokenExpired(action.token)) {
          return UserActions.loginRedirect();
        } else {
          return UserActions.refreshToken();
        }
      })
    )
  );

  refreshToken$ = createEffect(
    () =>
      ({ scheduler = asyncScheduler, stopTimer = EMPTY } = {}) =>
        this.actions$.pipe(
          ofType(UserActions.refreshToken),
          withLatestFrom(
            this.store$.select(UserSelectors.selectTokenRefreshInterval),
            this.store$.select(UserSelectors.selectToken)
          ),
          switchMap(([action, pollingInterval, token]) =>
            timer(0, pollingInterval, scheduler).pipe(
              takeUntil(stopTimer),
              switchMap(() =>
                this.authService.getNewJwt(token).pipe(
                  map((response: LoginResponse) =>
                    UserActions.refreshTokenSuccess({
                      token: response.token,
                    })
                  ),
                  catchError((error: Error) =>
                    of(UserActions.refreshTokenFailure({ error }))
                  )
                )
              )
            )
          )
        )
  );
}
