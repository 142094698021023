import { MessageSet } from '../models/message-set.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from '../models/api-response';

@Injectable()
export class MessageSetService {
  endpointUrl: string = environment.apiUrl + '/message-set';

  constructor(private logger: LoggerService, private http: HttpClient) {}

  getMessageSets(): Observable<MessageSet[]> {
    return this.http
      .get<MessageSet[]>(this.endpointUrl)
      .pipe(catchError(this.handleError));
  }

  getMessageSetsForCustomer(customerId: number, url?: string) {
    const urlToCall = url === undefined || url === '' ? this.endpointUrl : url;
    let params = new HttpParams();
    params = params.set('customer', customerId.toString());
    // don't fetch message sets sent by supporters
    params = params.set('sender_user_type', 'customeradmin');
    return this.http.get<ApiResponse<MessageSet>>(urlToCall, { params }).pipe(
      map((apiResponse: ApiResponse<MessageSet>) => {
        return apiResponse;
      }),
      catchError(this.handleError)
    );
  }

  getMessageSet(messageSetId) {
    return this.http.get<MessageSet>(this.endpointUrl + '/' + messageSetId);
  }

  postMessageSet(messageSet: MessageSet) {
    let params = new HttpParams();
    params = params.set('site', messageSet.site.toString());
    return this.http
      .post<MessageSet>(this.endpointUrl, messageSet, { params })
      .pipe(catchError(this.handleError));
  }

  doSearch(params: HttpParams) {
    return this.http
      .get(this.endpointUrl, { params })
      .pipe(catchError(this.handleError));
  }

  private extractData(data: any) {
    return data || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
