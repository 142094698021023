import { createAction, props } from '@ngrx/store';
import { ApiResponse } from 'src/app/core/models/api-response';
import { Customer } from 'src/app/core/models/customer.model';
import { MessageRecipient } from 'src/app/core/models/message-recipient.model';
import { MessageSet } from 'src/app/core/models/message-set.model';
import { Site } from 'src/app/core/models/site.model';
import { MessageRecipientNotification } from '../messagebox/messagebox.service';
import { MessageBoxSubscription } from './messages.reducer';
import { ScheduledMessageSet } from '../../../core/models/scheduled-message-set.model';
import {RevocationResponse} from '../../../core/models/revocation-response';

export const toggleHeader = createAction('[Messages] Toggle Header');

export const initMessages = createAction('[Messages] INIT');

// Subscriptions

export const subscribeMessageBoxChannel = createAction(
  '[Messages][WS] Subscribe Message Box Channel',
  props<{ customer: Customer; site: Site }>()
);

export const subscribeMessageBoxChannelSuccess = createAction(
  '[Messages][WS] Subscribe Message Box Channel Success',
  props<{ subscription: MessageBoxSubscription }>()
);

export const subscribeMessageBoxChannelFailure = createAction(
  '[Messages][WS] Subscribe Message Box Channel Failure',
  props<Error>()
);

export const pusherMessageRecipientUpdated = createAction(
  '[Messages][WS] Message Recipient Updated',
  props<{ notification: MessageRecipientNotification }>()
);

// Message Sets and Message Recipients

export const getMessageSets = createAction('[Messages] Load MessageSets');

export const getMessageSetsSuccess = createAction(
  '[Messages] Load MessageSets Success',
  props<{ apiResponse: ApiResponse<MessageSet> }>()
);

export const getMessageSetsFailed = createAction(
  '[Messages] Load MessageSets Failed',
  props<Error>()
);

export const getMessageSet = createAction(
  '[Messages] Get Message Set',
  props<{ messageSetId: number }>()
);

export const getMessageSetSuccess = createAction(
  '[Messages] Get Message Set Success',
  props<{ messageSet: MessageSet }>()
);

export const getMessageSetFailure = createAction(
  '[Messages] Get Message Set Failure',
  props<Error>()
);

export const loadNextMessageSets = createAction(
  '[Messages] Load Next MessageSets'
);

export const loadNextMessageSetsSuccess = createAction(
  '[Messages] Load Next MessageSets Success',
  props<{ apiResponse: ApiResponse<MessageSet> }>()
);

export const loadNextMessageSetsFailure = createAction(
  '[Messages] Load Next MessageSets Failure',
  props<Error>()
);

export const endOfMessageSets = createAction('[Messages] End Of MessageSets');

export const getMessageRecipient = createAction(
  '[Messages] Get MessageRecipient',
  props<{ messageRecipientId: number }>()
);

export const getMessageRecipientSuccess = createAction(
  '[Messages] Get MessageRecipient Success',
  props<{ messageRecipient: MessageRecipient }>()
);

export const getMessageRecipientFailure = createAction(
  '[Messages] Get MessageRecipient Failure',
  props<Error>()
);

export const getRecipientsForMessageSet = createAction(
  '[Messages] Get Recipients for Message Set',
  props<{ messageSetId: number }>()
);

export const getRecipientsForMessageSetSuccess = createAction(
  '[Messages] Get Recipients for Message Set Success',
  props<{ messageSetId: number; messageRecipients: MessageRecipient[] }>()
);

export const getRecipientsForMessageSetFailure = createAction(
  '[Messages] Get Recipients for Message Set Failure',
  props<Error>()
);

export const setCurrentMessageSetId = createAction(
  '[Messages] Set Current MessageSet Id',
  props<{ messageSet: MessageSet }>()
);

export const sendMessageSet = createAction(
  '[Messages] Send Message Set',
  props<{ messageSet: MessageSet }>()
);

export const sendMessageSetSuccess = createAction(
  '[Messages] Send Message Set Success',
  props<{ messageSet: MessageSet }>()
);

export const sendMessageSetFailure = createAction(
  '[Messages] Send Message Set Failure',
  props<Error>()
);

export const sendScheduledMessageSet = createAction(
  '[Messages] Send Scheduled Message Set',
  props<{ messageSet: MessageSet }>()
);

export const sendScheduledMessageSetSuccess = createAction(
  '[Messages] Send Scheduled Message Set Success',
  props<{ messageSet: MessageSet }>()
);

export const sendScheduledMessageSetFailure = createAction(
  '[Messages] Send Scheduled Message Set Failure',
  props<Error>()
);

export const getCurrentScheduledMessageSet = createAction(
  '[Messages] Get Current Scheduled Message Set',
  props<{ scheduledMessageSetId: number }>()
);

export const getCurrentScheduledMessageSetSuccess = createAction(
  '[Messages] Get Current Scheduled Message Set Success',
  props<{ scheduledMessageSet: ScheduledMessageSet }>()
);

export const getCurrentScheduledMessageSetFailure = createAction(
  '[Messages] Get Current Scheduled Message Set Failure',
  props<Error>()
);

export const setCurrentScheduledMessageSetEvent = createAction(
  '[Messages] Set Current Scheduled Message Set Event',
  props<any>()
);

export const deleteScheduledMessageSet = createAction(
  '[Messages] Delete Scheduled Message Set',
  props<{ scheduledMessageSetId: number }>()
);

export const deleteScheduledMessageSetSuccess = createAction(
  '[Messages] Delete Scheduled Message Set Success'
);

export const deleteScheduledMessageSetFailure = createAction(
  '[Messages] Delete Scheduled Message Set Failure',
  props<Error>()
);

export const revokeMessageRecipient = createAction(
  '[Messages] Revoke MessageRecipient',
  props<{ messageRecipient: MessageRecipient }>()
);

export const revokeMessageRecipientSuccess = createAction(
  '[Messages] Revoke MessageRecipient Success',
  props<{ revocationResponse: RevocationResponse }>()
);

export const revokeMessageRecipientFailure = createAction(
  '[Messages] Revoke MessageRecipient Failure',
  props<Error>()
);

export const noOp = createAction('[Messages] No Op');
