import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cm-media-library-confirm-delete',
  templateUrl: './media-library-confirm-delete.component.html',
  styleUrls: ['./media-library-confirm-delete.component.scss'],
})
export class MediaLibraryConfirmDeleteComponent implements OnInit {
  dialogRef: MatDialogRef<MediaLibraryConfirmDeleteComponent>;

  constructor() {}

  ngOnInit(): void {}

  delete() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
