import { createReducer, on } from '@ngrx/store';
import * as ConferencingActions from './conferencing.actions';

export const conferencingFeatureKey = 'conferencing';

export enum CallType {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export interface ConferencingSubscription {
  userId: number;
  channelName: string;
}

export interface CallOptions {
  initiator?: any;
  target?: any;
  audioOnly?: boolean;
  declineReason?: string;
  roomName: string;
  roomSid?: string;
  autoAccept?: boolean;
  acceptAfter?: number;
  wakeScreen?: boolean;
}

export interface DeclinedState {
  isDeclined: boolean;
  reason: string;
}

export interface ConferencingState {
  subscription: ConferencingSubscription;
  subscriptionError: string;
  inboundCallOptions: CallOptions;
  outboundCallOptions: CallOptions;
  callAccepted: boolean;
  declinedState: DeclinedState;
  callEnded: boolean;
  acceptCall: boolean;
  declineCall: boolean;
  endCall: boolean;
}

const initialState: ConferencingState = {
  subscription: undefined,
  subscriptionError: '',
  inboundCallOptions: undefined,
  outboundCallOptions: undefined,
  callAccepted: false,
  declinedState: { isDeclined: false, reason: '' },
  callEnded: false,
  acceptCall: false,
  declineCall: false,
  endCall: false,
};

export const conferencingReducer = createReducer<ConferencingState>(
  initialState,
  on(
    ConferencingActions.subscribeConferencingChannelSuccess,
    (state: ConferencingState, action) => ({
      ...state,
      subscription: action.subscription,
    })
  ),
  on(
    ConferencingActions.subscribeConferencingChannelFailure,
    (state, action) => ({ ...state, subscriptionError: action.message })
  ),
  on(ConferencingActions.callRequested, (state: ConferencingState, action) => ({
    ...state,
    inboundCallOptions: action.callOptions,
  })),
  on(ConferencingActions.callAccepted, (state: ConferencingState, action) => ({
    ...state,
    callAccepted: true,
  })),
  on(ConferencingActions.callDeclined, (state: ConferencingState, action) => ({
    ...state,
    declinedState: {
      isDeclined: true,
      reason: action.callOptions.declineReason,
    },
  })),
  on(ConferencingActions.callEnded, (state: ConferencingState, action) => ({
    ...state,
    callEnded: true,
  })),
  on(ConferencingActions.resetState, (state: ConferencingState, action) => ({
    ...state,
    inboundCallOptions: undefined,
    outboundCallOptions: undefined,
    callAccepted: false,
    declinedState: { isDeclined: false, reason: '' },
    callEnded: false,
    acceptCall: false,
    declineCall: false,
    endCall: false,
  })),
  on(ConferencingActions.requestCall, (state: ConferencingState, action) => ({
    ...state,
    outboundCallOptions: action.callOptions,
  }))
);
