import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { MatDialogRef } from '@angular/material/dialog';

import { LoggerService } from '../../../core/services/logger.service';
import { ConferencingService } from '../../../core/services/conferencing.service';
import {
  CallOptions,
  ConferencingState,
} from 'src/app/state/conferencing.reducer';
import { Store } from '@ngrx/store';
import * as ConferencingActions from '../../../state/conferencing.actions';
import { selectCallEnded } from 'src/app/state/conferencing.selectors';

@Component({
  selector: 'app-accept-call-dialog',
  templateUrl: './accept-call-dialog.component.html',
  styleUrls: ['./accept-call-dialog.component.scss'],
})
export class AcceptCallDialogComponent implements OnInit, OnDestroy {
  dialogRef: MatDialogRef<AcceptCallDialogComponent>;
  callOptions: CallOptions;
  callEnded$: Observable<boolean>;
  callEndedSubscription: Subscription;

  @ViewChild('ringingSound') ringingSound: ElementRef;
  @ViewChild('callAcceptedSound') callAcceptedSound: ElementRef;
  @ViewChild('callDeclinedSound') callDeclinedSound: ElementRef;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private logger: LoggerService,
    private conferencing: ConferencingService,
    private store$: Store<ConferencingState>
  ) {}

  ngOnInit() {
    this.playRingingSound();
    this.callEnded$ = this.store$.select(selectCallEnded);
    this.callEndedSubscription = this.callEnded$.subscribe((ended: boolean) => {
      if (ended) this.callEnded();
    });
  }

  ngOnDestroy() {
    if (this.callEndedSubscription !== undefined) {
      this.callEndedSubscription.unsubscribe();
    }
  }

  accept($event?) {
    this.logger.info('accept call clicked');
    this.store$.dispatch(ConferencingActions.acceptCall());
    this.stopRingingSound();
    this.playCallAcceptedSound();
    this.close();
  }

  decline($event?) {
    this.logger.info('decline call clicked');
    this.store$.dispatch(ConferencingActions.declineCall());
    this.stopRingingSound();
    this.playCallDeclinedSound();
    this.close();
  }

  callEnded() {
    this.stopRingingSound();
    this.playCallDeclinedSound();
    this.close();
  }

  close($event?) {
    this.dialogRef.close();
  }

  playRingingSound() {
    const audio = this.ringingSound.nativeElement as HTMLAudioElement;
    audio
      .play()
      .then((value) => console.info('playing ringing sound'))
      .catch((error) => console.error(error));
  }

  stopRingingSound() {
    const audio = this.ringingSound.nativeElement as HTMLAudioElement;
    audio.pause();
    this.renderer.setProperty(
      this.ringingSound.nativeElement,
      'currentTime',
      0
    );
  }

  playCallAcceptedSound() {
    const audio = this.callAcceptedSound.nativeElement as HTMLAudioElement;
    audio
      .play()
      .then((value) => console.info('playing ringing sound'))
      .catch((error) => console.error(error));
  }

  stopCallAcceptedSound() {
    const audio = this.callAcceptedSound.nativeElement as HTMLAudioElement;
    audio.pause();
    this.renderer.setProperty(
      this.callAcceptedSound.nativeElement,
      'currentTime',
      0
    );
  }

  playCallDeclinedSound() {
    const audio = this.callDeclinedSound.nativeElement as HTMLAudioElement;
    audio
      .play()
      .then((value) => console.info('playing ringing sound'))
      .catch((error) => console.error(error));
  }

  stopCallDeclinedSound() {
    const audio = this.callAcceptedSound.nativeElement as HTMLAudioElement;
    audio.pause();
    this.renderer.setProperty(
      this.callDeclinedSound.nativeElement,
      'currentTime',
      0
    );
  }
}
