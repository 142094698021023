<div class="accept-call-dialog">
  <h4>
    <div class="user-header">
      <app-avatar-thumbnail
        [user]="callOptions.initiator"
        size="40"
      ></app-avatar-thumbnail>
      {{ callOptions.initiator.first_name }}
      {{ callOptions.initiator.last_name }}
    </div>
  </h4>

  <div class="phone-ring-container">
    <div class="phone-ring">
      <a href="#">
        <div class="quick-alo-ph-circle"></div>
        <div class="quick-alo-ph-circle-fill"></div>
        <div class="quick-alo-ph-img-circle"></div>
      </a>
    </div>
  </div>

  <div class="message">
    <p>
      is requesting a <span *ngIf="callOptions.audioOnly">audio</span>
      <span *ngIf="!callOptions.audioOnly">video</span> call
    </p>
    <p>Would you like to accept this call?</p>
  </div>

  <div class="call-buttons">
    <button mat-raised-button color="primary" (click)="accept($event)">
      Accept
    </button>
    <button mat-raised-button (click)="decline($event)">Decline</button>
  </div>

  <audio #ringingSound loop="true">
    <source src="assets/sounds/sound_effects.mp3" type="audio/mp3"/>
  </audio>
  <audio #callAcceptedSound>
    <source src="assets/sounds/positive.wav" type="audio/wav"/>
  </audio>
  <audio #callDeclinedSound>
    <source src="assets/sounds/declined.mp3" type="audio/mp3"/>
    <source src="assets/sounds/declined.wav" type="audio/wav"/>
  </audio>
</div>
