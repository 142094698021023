import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Administrator } from '../models/administrator.model';
import { Customer } from '../models/customer.model';
import { Store } from '@ngrx/store';
import {
  getCurrentCustomer,
  UserState,
} from 'src/app/login/state/user.reducers';
import { ApiResponse } from '../models/api-response';
import { AdministratorPermission } from '../models/administrator-permissions.model';

@Injectable()
export class CustomerAdminService {
  endpointUrl: string = environment.apiUrl + '/customer-admin';

  constructor(private http: HttpClient, private store$: Store<UserState>) {}

  getAdministrators(params?: HttpParams): Observable<Administrator[]> {
    return this.http
      .get(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getAdministratorsForCustomer(customerId: Number) {
    let customer: Customer;
    this.store$
      .select(getCurrentCustomer)
      .pipe(take(1))
      .subscribe((c: Customer) => (customer = c));

    let params = new HttpParams();
    params = params.set('customer', customer.id);
    params = params.set('ordering', 'last_name');
    return this.http
      .get<ApiResponse<Administrator>>(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getOnlineAdministrators(customer: Customer): Observable<Administrator[]> {
    let params = new HttpParams();
    params = params.set('online', 'true');
    params = params.set('customer', customer.id);
    return this.http
      .get(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getAdministrator(administratorId) {
    return this.http.get<Administrator>(
      this.endpointUrl + '/' + administratorId
    );
  }

  createAdministrator(administrator: Administrator) {
    let params = new HttpParams();
    params = params.set('customer', administrator.customer);
    return this.http.post<Administrator>(this.endpointUrl, administrator, {
      params,
    });
  }

  updateAdministrator(administrator: Administrator) {
    let params = new HttpParams();
    params = params.set('customer', administrator.customer);
    return this.http
      .put<Administrator>(
        `${this.endpointUrl}/${administrator.id}`,
        administrator,
        { params }
      )
      .pipe(catchError(this.handleError));
  }

  deleteAdministrator(administrator: Administrator) {
    return this.http
      .delete<Administrator>(`${this.endpointUrl}/${administrator.id}`)
      .pipe(catchError(this.handleError));
  }

  getPermissionsForAdministrator(administrator: Administrator) {
    return this.http
      .get<AdministratorPermission>(
        `${this.endpointUrl}/${administrator.id}/get_perms`
      )
      .pipe(catchError(this.handleError));
  }

  updatePermissionsForAdministrator(administrator: Administrator) {
    const url = `${this.endpointUrl}/${administrator.id}/set_perms`;
    return this.http
      .post(url, administrator.permissions)
      .pipe(catchError(this.handleError));
  }

  setAdministratorPassword(administrator: Administrator) {
    const url = `${this.endpointUrl}/${administrator.id}/set_password`;
    return this.http.patch(url, { password: administrator.password });
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
