import { Injectable } from '@angular/core';
import { Channel } from 'pusher-js';
import { LoggerService } from './logger.service';
import { PusherService } from './pusher.service';
import { Store } from '@ngrx/store';
import * as PusherConferencingActions from '../../state/conferencing.actions';
import * as ConferencingActions from '../../manager/conferencing/state/conferencing.actions';
import { Customer } from '../models/customer.model';
import { TwilioVideoRoom } from '../models/twilio-video-room.model';

@Injectable({
  providedIn: 'root',
})
export class TwilioVideoRoomChannelService {
  channelName: string;
  channel: Channel;

  constructor(
    private logger: LoggerService,
    private pusher: PusherService,
    private store: Store
  ) {}

  public subscribe(customer: Customer): void {
    this.channelName = `private-conferencing-room-${customer.id}`;
    this.channel = this.pusher.subscribe(this.channelName);

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_succeeded',
      (data) => {
        this.logger.info(
          'Private conferencing room channel subscription succeeded: ',
          data
        );
        this.store.dispatch(
          PusherConferencingActions.subscribeConferencingRoomChannelSuccess()
        );
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_error',
      (status) => {
        this.logger.error(
          'Private conferencing room channel subscription FAILED: ' +
            this.channel.name
        );
        this.logger.error(status);
        this.store.dispatch(
          PusherConferencingActions.subscribeConferencingRoomChannelFailure()
        );
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'video_room_updated',
      (twilioVideoRoom: TwilioVideoRoom) => {
        this.logger.info(
          `video_room_updated event: ${JSON.stringify(twilioVideoRoom)}`
        );
        this.store.dispatch(
          ConferencingActions.getTwilioVideoRoomSuccess({ twilioVideoRoom })
        );
      }
    );
  }

  public unsubscribe(): void {
    this.pusher.unsubscribe(this.channelName);
    this.logger.info('Unsubscribed private conferencing room channel');
  }
}
