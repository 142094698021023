import { createAction, props } from '@ngrx/store';
import { Customer } from '../core/models/customer.model';
import { Site } from '../core/models/site.model';

export const connectToPusher = createAction('[Pusher] Connect');

export const connectToPusherSuccess = createAction('[Pusher] Connect Success');

export const connectToPusherFailure = createAction('[Pusher] Connect Failure');

// Global Channel
export const subscribeGlobalChannel = createAction(
  '[Global Channel] Subscribe'
);

export const subscribeGlobalChannelSuccess = createAction(
  '[Global Channel] Subscribe Success',
  props<{ channelName: string }>()
);

export const subscribeGlobalChannelFailure = createAction(
  '[Global Channel] Subscribe Failure'
);

// Customer Channel
export const subscribeCustomerChannel = createAction(
  '[Customer Channel] Subscribe'
);

export const subscribeCustomerChannelSuccess = createAction(
  '[Customer Channel] Subscribe Success',
  props<{ channelName: string }>()
);

export const subscribeCustomerChannelFailure = createAction(
  '[Customer Channel] Subscribe Failure'
);

// Site Channel
// export const subscribeSiteChannel = createAction(
//   '[Site Channel] Subscribe',
//   props<{ customer: Customer; site: Site }>()
// );
//
// export const subscribeSiteChannelSuccess = createAction(
//   '[Site Channel] Subscribe Success',
//   props<{ channelName: string }>()
// );
//
// export const subscribeSiteChannelFailure = createAction(
//   '[Site Channel] Subscribe Failure'
// );

// User Channel
export const subscribeUserChannel = createAction('[User Channel] Subscribe');

export const subscribeUserChannelSuccess = createAction(
  '[User Channel] Subscribe Success'
);

export const subscribeUserChannelFailure = createAction(
  '[User Channel] Subscribe Failure'
);

export const userChannelCallRequest = createAction(
  '[User Channel] CallRequest',
  props<{ callRequest: any }>()
);
