<div>
  <h1 mat-dialog-title>
    {{ "COMPONENTS.MEDIA_LIBRARY.CONFIRM_DELETE.TITLE" | translate }}
  </h1>
  <div mat-dialog-content>
    {{ "COMPONENTS.MEDIA_LIBRARY.CONFIRM_DELETE.CONTENT" | translate }}
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-raised-button color="warn" (click)="delete()">
      <mat-icon>delete_forever</mat-icon>
      {{ "COMPONENTS.MEDIA_LIBRARY.CONFIRM_DELETE.DELETE" | translate }}
    </button>
    <button mat-button mat-raised-button mat-dialog-close (click)="cancel()">
      {{ "COMPONENTS.MEDIA_LIBRARY.CONFIRM_DELETE.CANCEL" | translate }}
    </button>
  </div>
</div>
