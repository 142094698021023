import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ConferencingState,
  conferencingFeatureKey,
} from './conferencing.reducer';

export const selectConferencingState = createFeatureSelector<ConferencingState>(
  conferencingFeatureKey
);

export const selectSubscription = createSelector(
  selectConferencingState,
  (state) => state.subscription
);

export const selectInboundCallOptions = createSelector(
  selectConferencingState,
  (state) => state.inboundCallOptions
);

export const selectOutboundCallOptions = createSelector(
  selectConferencingState,
  (state) => state.outboundCallOptions
);

export const selectCallAccepted = createSelector(
  selectConferencingState,
  (state) => state.callAccepted
);
export const selectDeclinedState = createSelector(
  selectConferencingState,
  (state) => state.declinedState
);
export const selectCallEnded = createSelector(
  selectConferencingState,
  (state) => state.callEnded
);
