import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { BaseUser } from '../models/base-user.model';
import { take } from 'rxjs/operators';
import { getCurrentUser, UserState } from 'src/app/login/state/user.reducers';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class TwilioVideoService {
  ACCESS_TOKEN_URL = `${environment.apiUrl.substring(
    0,
    environment.apiUrl.length - 3
  )}/conferencing/video-token/`;

  CREATE_ROOM_URL = `${environment.apiUrl}/conferencing-room`;

  constructor(private http: HttpClient, private store$: Store<UserState>) {}

  getAccessToken(roomName: string): Promise<any> {
    let user: BaseUser;
    this.store$
      .select(getCurrentUser)
      .pipe(take(1))
      .subscribe((u: BaseUser) => (user = u));

    const body = { username: user.username, room_name: roomName };
    return this.http.post(this.ACCESS_TOKEN_URL, body).toPromise();
  }

  createRoom(roomName: string, enableRecording, audioOnly): Promise<any> {
    const body = {
      unique_name: roomName,
      enable_recording: enableRecording,
      audio_only: audioOnly,
    };
    return this.http.post(this.CREATE_ROOM_URL, body).toPromise();
  }
}
