import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getToken, UserState } from 'src/app/login/state/user.reducers';
import { first, flatMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store$: Store<UserState>) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store$.select(getToken).pipe(
      first(),
      flatMap((token) => {
        const authReq = !!token
          ? request.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            })
          : request;
        return next.handle(authReq);
      })
    );
  }
}
