import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UploadOutput } from 'ngx-uploader';
import { MessageImage } from 'src/app/core/models/message-image.model';
import { MessageVideo } from 'src/app/core/models/message-video.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { CustomerAdminService } from 'src/app/core/services/customer-admin.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MessageImageService } from 'src/app/core/services/message-image.service';
import { MessageVideoService } from 'src/app/core/services/message-video.service';
import { PusherService } from 'src/app/core/services/pusher.service';
import { MediaType, ImageOrVideo } from 'src/app/core/types/types';
import {
  getCurrentCustomer,
  UserState,
} from 'src/app/login/state/user.reducers';
import { environment } from 'src/environments/environment';
import { MediaLibraryConfirmDeleteComponent } from './media-library-confirm-delete/media-library-confirm-delete.component';
import { take } from 'rxjs/operators';
import { Customer } from 'src/app/core/models/customer.model';

@Component({
  selector: 'cm-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.scss'],
})
export class MediaLibraryComponent implements OnInit {
  public dialogRef: MatDialogRef<MediaLibraryComponent>;

  @Input() selectedItems: Array<ImageOrVideo> = [];

  uploadOptions = {
    private: true,
    owner: null,
  };

  visibilityFilters = [
    { id: 'all', label: 'Show All' },
    { id: 'private', label: 'Private', bool: true },
    { id: 'public', label: 'Public', bool: false },
  ];

  state = {
    mediaType: MediaType.PHOTOS,
    busy: false,
    deleteDisabled: false,
    visibilityFilter: this.visibilityFilters[0],
    next: `${environment.apiUrl}/message-image?page=1&page_size=25`,
  };

  translateStrings: any;
  images: Array<MessageImage> = [];
  videos: Array<MessageVideo> = [];

  imageUploadOutputs: Array<UploadOutput> = [];
  videoUploadOutputs: Array<UploadOutput> = [];

  constructor(
    private http: HttpClient,
    private customerAdmin: CustomerAdminService,
    private logger: LoggerService,
    private auth: AuthService,
    private pusher: PusherService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private translate: TranslateService,
    private imageService: MessageImageService,
    private videoService: MessageVideoService,
    private store: Store<UserState>
  ) {
    const tokens = [
      'COMPONENTS.MEDIA_LIBRARY.ENCODING.UPLOADING',
      'COMPONENTS.MEDIA_LIBRARY.ENCODING.UPLOAD_COMPLETE',
      'COMPONENTS.MEDIA_LIBRARY.ENCODING.SUBMITTING',
      'COMPONENTS.MEDIA_LIBRARY.ENCODING.SUBMITTED',
      'COMPONENTS.MEDIA_LIBRARY.ENCODING.PROCESSING',
      'COMPONENTS.MEDIA_LIBRARY.ENCODING.COMPLETE',
    ];
    this.translate
      .get(tokens)
      .subscribe((strings) => (this.translateStrings = strings));
  }

  ngOnInit(): void {
    this.getNextPage();
  }

  getNextPage() {
    if (this.state.busy) return;
    this.state.busy = true;

    if (this.state.next) {
      this.http.get(this.state.next).subscribe((response: any) => {
        if (this.state.mediaType === MediaType.PHOTOS) {
          this.images.push.apply(this.images, response.results);
        } else {
          this.videos.push.apply(this.videos, response.results);
        }
        if (response.next) {
          this.state.next = response.next;
        } else {
          this.state.next = null;
        }
        // reset busy flag
        this.state.busy = false;
      });
    } else {
      // no next
      this.state.busy = false;
      return;
    }
  }

  onMediaTypeChanged() {
    if (this.state.mediaType === MediaType.PHOTOS) {
      this.logger.info('Switched to photos');
      this.selectedItems = [];
      this.videos = [];
      this.state.next =
        environment.apiUrl +
        '/message-image?page=1&page_size=25&is_active=True&customer=' +
        this.getCurrentCustomerId();
      this.getNextPage();
    } else {
      this.logger.info('Switched to videos');
      this.selectedItems = [];
      this.images = [];
      this.state.next =
        environment.apiUrl +
        '/message-video?page=1&page_size=6&is_active=True&customer=' +
        this.getCurrentCustomerId();
      this.getNextPage();
    }
  }

  getCurrentCustomerId() {
    let customer: Customer;
    this.store
      .select(getCurrentCustomer)
      .pipe(take(1))
      .subscribe((c: Customer) => (customer = c));
    return customer.id;
  }

  onImageSelected(image: MessageImage) {
    this.selectedItems.push(image);
    console.log(`${this.selectedItems.length} images selected`);
  }

  onImageUnselected(image: MessageImage) {
    const idx = this.selectedItems.indexOf(image);
    if (idx > -1) {
      this.selectedItems.splice(idx, 1);
    }
    console.log(`${this.selectedItems.length} images selected`);
  }

  onVideoSelected(image: MessageImage) {
    this.selectedItems.push(image);
    console.log(`${this.selectedItems.length} videos selected`);
  }

  onVideoUnselected(video: MessageVideo) {
    const idx = this.selectedItems.indexOf(video);
    if (idx > -1) {
      this.selectedItems.splice(idx, 1);
    }
    console.log(`${this.selectedItems.length} videos selected`);
  }

  addSelected() {
    this.dialogRef.close({
      success: true,
      mediaType: this.state.mediaType,
      selectedItems: this.selectedItems,
    });
  }

  cancel() {
    this.dialogRef.close({ success: false, mediaType: '', selectedItems: [] });
  }

  onUploadFilesAdded(output: UploadOutput) {
    console.log(`files added: ${JSON.stringify(output)}`);
    if (this.state.mediaType === MediaType.PHOTOS) {
      this.imageUploadOutputs.push(output);
    } else {
      this.videoUploadOutputs.push(output);
    }
  }

  onUploadProgress(output: UploadOutput) {
    console.log(`progress: ${JSON.stringify(output)}`);
    output.file.progress.data.etaHuman =
      this.translateStrings['COMPONENTS.MEDIA_LIBRARY.ENCODING.UPLOADING'];
    if (this.state.mediaType === MediaType.PHOTOS) {
      const index = this.imageUploadOutputs.findIndex(
        (imageOutput) =>
          typeof output.file !== 'undefined' &&
          imageOutput.file.id === output.file.id
      );
      this.imageUploadOutputs[index] = output;
    } else {
      const index = this.videoUploadOutputs.findIndex(
        (videoOutput) =>
          typeof output.file !== 'undefined' &&
          videoOutput.file.id === output.file.id
      );
      this.videoUploadOutputs[index] = output;
    }
  }

  onUploadComplete(output: UploadOutput) {
    console.log(`complete: ${JSON.stringify(output)}`);
    if (this.state.mediaType === MediaType.PHOTOS) {
      output.file.progress.data.etaHuman =
        this.translateStrings[
          'COMPONENTS.MEDIA_LIBRARY.ENCODING.UPLOAD_COMPLETE'
        ];
      const index = this.imageUploadOutputs.findIndex(
        (imageOutput) =>
          typeof output.file !== 'undefined' &&
          imageOutput.file.id === output.file.id
      );
      this.imageUploadOutputs.splice(index, 1);
      this.images.unshift(output.file.response);
    } else {
      // as for videos....
      output.file.progress.data.etaHuman =
        this.translateStrings['COMPONENTS.MEDIA_LIBRARY.ENCODING.SUBMITTING'];
      const index = this.videoUploadOutputs.findIndex(
        (videoOutput) =>
          typeof output.file !== 'undefined' &&
          videoOutput.file.id === output.file.id
      );

      // subscribe to private encoding channel
      var encodingChannel = this.pusher.subscribe(
        'private-message-video-' + output.file.response.id + '-updates'
      );

      this.pusher.bindChannelEvent(
        encodingChannel,
        'submitted',
        (submissionData) => {
          output.file.progress.data.etaHuman =
            this.translateStrings[
              'COMPONENTS.MEDIA_LIBRARY.ENCODING.SUBMITTED'
            ];
          console.info(submissionData);
        }
      );

      this.pusher.bindChannelEvent(
        encodingChannel,
        'progress',
        (progressData) => {
          var tp = Math.round(progressData.progress);
          tp = isNaN(tp) ? 100 : tp;
          output.file.progress.data.etaHuman =
            this.translateStrings[
              'COMPONENTS.MEDIA_LIBRARY.ENCODING.PROCESSING'
            ] +
            tp +
            '%';
          output.file.progress.data.percentage = tp;
          console.info(output.file.progress.data);
        }
      );

      this.pusher.bindChannelEvent(
        encodingChannel,
        'finished',
        (encodingResult) => {
          output.file.progress.data.etaHuman =
            this.translateStrings['COMPONENTS.MEDIA_LIBRARY.ENCODING.COMPLETE'];
          console.info(encodingResult);

          this.videoUploadOutputs.splice(index, 1);

          // Add newly updated MessageVideo to videos array
          this.videos.unshift(encodingResult.message_video);

          // Unsubscribe encoding channel
          this.pusher.unsubscribe(
            'private-message-video-' + output.file.response.id + '-updates'
          );
        }
      );
    }
  }

  onDeleteSelected() {
    let config = new MatDialogConfig();
    config.viewContainerRef = this.viewContainerRef;
    let confirmDeleteDialog = this.dialog.open(
      MediaLibraryConfirmDeleteComponent,
      config
    );
    confirmDeleteDialog.componentInstance.dialogRef = confirmDeleteDialog;
    let afterClosed = confirmDeleteDialog.afterClosed();
    afterClosed.subscribe((result: any) => {
      if (result) {
        if (this.state.mediaType == MediaType.PHOTOS) {
          let promises = this.selectedItems.map((image: MessageImage) =>
            this.imageService.deleteImage(image).toPromise()
          );
          Promise.all(promises)
            .then((results) => {
              this.logger.info(`images deleted: ${JSON.stringify(results)}`);
              this.selectedItems.forEach((item: MessageImage) => {
                let idx = this.images.indexOf(item);
                if (idx > -1) {
                  this.images.splice(idx, 1);
                }
              });
              this.selectedItems = [];
            })
            .catch((error) => this.logger.error('error deleting images'));
        } else {
          let promises = this.selectedItems.map((video: MessageVideo) =>
            this.videoService.deleteVideo(video).toPromise()
          );
          Promise.all(promises)
            .then((results) => {
              this.logger.info(`videos deleted: ${JSON.stringify(results)}`);
              this.selectedItems.forEach((item: MessageVideo) => {
                let idx = this.videos.indexOf(item);
                if (idx > -1) {
                  this.videos.splice(idx, 1);
                }
              });
              this.selectedItems = [];
            })
            .catch((error) => this.logger.error('error deleting videos'));
        }
      }
    });
  }
}
