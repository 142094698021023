<form
  fxLayout="row wrap"
  novalidate
  [formGroup]="setPasswordForm"
  (ngSubmit)="onSubmit()"
>
  <h3>
    Change password for {{ user.first_name }} {{ user.last_name }} ({{
    user.username
    }})
  </h3>
  <div fxLayout="row" fxFlexFill fxLayout.lt-sm="column" fxLayoutGap="16px">
    <div
      fxLayout="column"
      fxFlexFill
      fxLayout.lt-sm="column"
      fxLayoutGap="16px"
    >
      <!-- Form Fields Column -->
      <div fxLayout="row" fxFlexFill fxLayout.lt-sm="column" fxLayoutGap="16px">
        <mat-form-field fxFlex appearance="outline">
          <mat-label>{{
            "COMPONENTS.ADMINISTRATOR_FORM.PASSWORD.LABEL" | translate
            }}</mat-label>
          <input
            matInput
            type="password"
            placeholder="{{
              'COMPONENTS.ADMINISTRATOR_FORM.PASSWORD.PLACEHOLDER' | translate
            }}"
            formControlName="password"
            required
            autocomplete="new-password"
          />
          <mat-hint>{{
            "COMPONENTS.ADMINISTRATOR_FORM.PASSWORD.HINT" | translate
            }}</mat-hint>
          <mat-error
            *ngIf="
              setPasswordForm.get('password').errors &&
              setPasswordForm.get('password').hasError('required')
            "
          >{{
            "COMPONENTS.ADMINISTRATOR_FORM.PASSWORD.ERRORS.REQUIRED"
              | translate
            }}</mat-error
          >
          <mat-error
            *ngIf="
              setPasswordForm.get('password').errors &&
              setPasswordForm.get('password').hasError('minlength')
            "
          >{{
            "COMPONENTS.ADMINISTRATOR_FORM.PASSWORD.ERRORS.MIN_LENGTH"
              | translate
            }}</mat-error
          >
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline">
          <mat-label>{{
            "COMPONENTS.ADMINISTRATOR_FORM.CONFIRM_PASSWORD.LABEL" | translate
            }}</mat-label>
          <input
            matInput
            type="password"
            placeholder="{{
              'COMPONENTS.ADMINISTRATOR_FORM.CONFIRM_PASSWORD.PLACEHOLDER'
                | translate
            }}"
            formControlName="confirm_password"
            required
            autocomplete="new-password"
          />
          <mat-hint>{{
            "COMPONENTS.ADMINISTRATOR_FORM.CONFIRM_PASSWORD.HINT" | translate
            }}</mat-hint>
          <mat-error
            *ngIf="
              setPasswordForm.get('confirm_password').errors &&
              setPasswordForm.get('confirm_password').hasError('required')
            "
          >{{
            "COMPONENTS.ADMINISTRATOR_FORM.CONFIRM_PASSWORD.ERRORS.REQUIRED"
              | translate
            }}</mat-error
          >
          <mat-error
            *ngIf="
              setPasswordForm.get('confirm_password').errors &&
              setPasswordForm.get('confirm_password').hasError('minlength')
            "
          >{{
            "COMPONENTS.ADMINISTRATOR_FORM.CONFIRM_PASSWORD.ERRORS.MIN_LENGTH"
              | translate
            }}</mat-error
          >
          <mat-error
            *ngIf="
              setPasswordForm.get('confirm_password').errors &&
              setPasswordForm.get('confirm_password').hasError('mustMatch')
            "
          >{{
            "COMPONENTS.ADMINISTRATOR_FORM.CONFIRM_PASSWORD.ERRORS.NO_MATCH"
              | translate
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <button
        class="submit-button"
        fxFlexFill
        mat-button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="setPasswordForm.invalid"
      >
        <span>Set Password</span>
      </button>
    </div>
  </div>
</form>
