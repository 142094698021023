<div id="media-library-video" (click)="toggleSelection()">
  <div id="selected-item" *ngIf="isSelected">
    <mat-icon>check_circle</mat-icon>
  </div>
  <div id="permissions" ng-show="!video.is_private">
    <mat-icon>lock_open</mat-icon>
  </div>

  <vg-player>
    <!-- <vg-overlay-play></vg-overlay-play> -->
    <vg-buffering></vg-buffering>

    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-controls>
      <vg-play-pause></vg-play-pause>
      <vg-playback-button></vg-playback-button>

      <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

      <vg-scrub-bar style="pointer-events: none"></vg-scrub-bar>

      <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
      <!-- <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display> -->

      <vg-mute></vg-mute>
      <vg-volume></vg-volume>

      <vg-fullscreen></vg-fullscreen>
    </vg-controls>

    <video [vgMedia]="media" #media id="singleVideo" preload="auto" crossorigin>
      <source
        [src]="
          video.encodings.length > 0 ? video.encodings[0].file : video.video
        "
        type="video/mp4"
      />
    </video>
  </vg-player>
</div>
