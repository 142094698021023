import { Injectable } from '@angular/core';
import {
  AsyncValidator,
  AbstractControl,
  ValidationErrors,
  AsyncValidatorFn,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, catchError, debounceTime, switchMap, take } from 'rxjs/operators';
import { CheckTakenService } from '../services/check-taken.service';

interface ITakenResponse {
  result: boolean;
}

function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value === null || value.length === 0;
}

@Injectable({ providedIn: 'root' })
export class CheckTakenValidator {
  constructor(private checkTakenService: CheckTakenService) {}

  checkTaken(fieldName: string, initial: string = ''): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initial) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          take(1),
          switchMap((_) =>
            this.checkTakenService
              .isTaken(fieldName, control.value)
              .pipe(
                map((response: ITakenResponse) =>
                  response.result
                    ? { [fieldName]: { value: control.value } }
                    : null
                )
              )
          )
        );
      }
    };
  }
}
