import { Observable, Subscription } from 'rxjs';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { Site } from '../models/site.model';
import { SiteService } from '../services/site.service';

@Injectable()
export class SiteResolve implements Resolve<Site> {
  constructor(
    private siteService: SiteService,
    private router: Router,
    private auth: AuthService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Site | Observable<Site> {
    return this.siteService.getSites();
  }
}
