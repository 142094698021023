import { Component, Input, OnInit } from '@angular/core';
import { Resident } from '../../../core/models/resident.model';
import { ResidentMetric } from '../../../manager/residents/state/resident.reducers';
import { Observable } from 'rxjs';
import * as ResidentSelectors from '../../../manager/residents/state/resident.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'cm-resident-dashboard-card',
  templateUrl: './resident-dashboard-card.component.html',
  styleUrls: ['./resident-dashboard-card.component.scss'],
})
export class ResidentDashboardCardComponent implements OnInit {
  @Input() resident: Resident;

  residentMetric$: Observable<ResidentMetric>;

  constructor(private store$: Store) {}

  ngOnInit(): void {
    this.residentMetric$ = this.store$.select(
      ResidentSelectors.selectResidentMetric(this.resident.id)
    );
  }
}
