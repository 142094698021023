import { Injectable } from '@angular/core';
import { AssistanceProviderService } from '../../../core/services/assistance-provider.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ResidentActions from '../../residents/state/resident.actions';
import * as AssistanceActions from './assistance.actions';
import {
  catchError,
  concatMap,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { getCurrentCustomer } from '../../../login/state/user.reducers';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AssistanceState } from './assistance.reducers';
import { AssistanceProvider } from '../../../core/models/assistance-provider.model';
import { ApiResponse } from '../../../core/models/api-response';
import { Router } from '@angular/router';

@Injectable()
export class AssistanceEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AssistanceState>,
    private assistanceProviderService: AssistanceProviderService,
    private router: Router
  ) {}

  getAssistanceProvidersForCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResidentActions.getResidents),
      withLatestFrom(this.store$.select(getCurrentCustomer)),
      concatMap(([action, customer]) =>
        this.assistanceProviderService
          .getAssistanceProvidersForCustomer(customer.id)
          .pipe(
            map((apiResponse: ApiResponse<AssistanceProvider>) => {
              return AssistanceActions.getAssistanceProvidersForCustomerSuccess(
                {
                  assistanceProviders: apiResponse.results,
                }
              );
            }),
            catchError((error) =>
              of(
                AssistanceActions.getAssistanceProvidersForCustomerFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  createAssistanceProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistanceActions.createAssistanceProvider),
      withLatestFrom(this.store$.select(getCurrentCustomer)),
      concatMap(([action, customer]) => {
        return this.assistanceProviderService
          .createAssistanceProvider(action.assistanceProvider)
          .pipe(
            map((assistanceProvider: AssistanceProvider) => {
              return AssistanceActions.createAssistanceProviderSuccess({
                assistanceProvider,
              });
            }),
            catchError((error) =>
              of(AssistanceActions.createAssistanceProviderFailure({ error }))
            )
          );
      })
    )
  );

  updateAssistanceProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistanceActions.updateAssistanceProvider),
      concatMap((action) =>
        this.assistanceProviderService
          .updateAssistanceProvider(action.assistanceProvider)
          .pipe(
            map(() => {
              return AssistanceActions.updateAssistanceProviderSuccess({
                assistanceProvider: action.assistanceProvider,
              });
            }),
            catchError((error) =>
              of(AssistanceActions.updateAssistanceProviderFailure({ error }))
            )
          )
      )
    )
  );

  deleteAssistanceProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssistanceActions.deleteAssistanceProvider),
      concatMap((action) =>
        this.assistanceProviderService
          .deleteAssistanceProvider(action.assistanceProvider)
          .pipe(
            map(() => {
              return AssistanceActions.deleteAssistanceProviderSuccess({
                assistanceProvider: action.assistanceProvider,
              });
            }),
            catchError((error) =>
              of(AssistanceActions.deleteAssistanceProviderFailure({ error }))
            )
          )
      )
    )
  );

  createUpdateAssistanceProviderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AssistanceActions.createAssistanceProviderSuccess,
          AssistanceActions.updateAssistanceProviderSuccess,
          AssistanceActions.deleteAssistanceProviderSuccess
        ),
        tap((action) => this.router.navigate(['/manager/assistance/list']))
      ),
    { dispatch: false }
  );
}
