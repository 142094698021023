import { Injectable } from '@angular/core';

import { PusherService } from './pusher.service';
import { LoggerService } from './logger.service';

import { PresenceChannel } from 'pusher-js';

import * as PusherActions from '../../state/pusher.actions';
import * as PresenceActions from '../../state/presence.actions';
import { Store } from '@ngrx/store';
import { BaseUser } from '../models/base-user.model';

const GLOBAL_PRESENCE = 'presence-global';

@Injectable()
export class GlobalPresenceService {
  channel: PresenceChannel;

  constructor(
    private logger: LoggerService,
    private pusher: PusherService,
    private store$: Store
  ) {}

  public subscribe() {
    console.log('subscribe global presence');
    this.channel = this.pusher.subscribe(GLOBAL_PRESENCE);

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_succeeded',
      (members) => {
        this.logger.info(
          'Global presence channel subscription succeeded: ' + this.channel.name
        );
        this.logger.info('Global presence members: ' + members.count);
        let users: BaseUser[] = Object.entries(members.members).map(
          (info: any) => info[1]
        );
        this.store$.dispatch(
          PusherActions.subscribeGlobalChannelSuccess({
            channelName: GLOBAL_PRESENCE,
          })
        );
        this.store$.dispatch(PresenceActions.setGlobalUsers({ users }));
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_error',
      (status) => {
        this.logger.error(
          'Global presence channel subscription FAILED: ' + this.channel.name
        );
        this.logger.error(status);
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:member_added',
      (member) =>
        this.store$.dispatch(PresenceActions.globalUserOnline(member.info))
    );
    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:member_removed',
      (member) =>
        this.store$.dispatch(PresenceActions.globalUserOffline(member.info))
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'conferencing:call_request',
      this.handleCallRequest
    );
  }

  public unsubscribe() {
    this.pusher.unsubscribe(GLOBAL_PRESENCE);
    this.logger.info('Unsubscribed global presence channel');
  }

  private handleCallRequest(data: any) {
    this.logger.info('Call request data received: ${data}');
  }
}
