import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ApiResponse } from '../models/api-response';
import { catchError } from 'rxjs/operators';
import { TwilioVideoComposition } from '../models/twilio-video-composition.model';

@Injectable({
  providedIn: 'root',
})
export class TwilioVideoCompositionService {
  endpointUrl: string = environment.apiUrl + '/composition';

  constructor(private http: HttpClient) {}

  getTwilioVideoCompositions(
    filter: string = '',
    sortField: string = 'created',
    sortDirection: string = 'desc',
    pageIndex: number = 1,
    pageSize: number = 200
  ): Observable<ApiResponse<TwilioVideoComposition>> {
    let params = new HttpParams();
    params = params.set('page_size', pageSize.toString());
    params = params.set('ordering', sortField + '__' + sortDirection);
    params = params.set('page', pageIndex.toString());
    params = params.set('search', filter);
    return this.http
      .get<ApiResponse<TwilioVideoComposition>>(this.endpointUrl, { params })
      .pipe(catchError(this.handleError));
  }

  getTwilioVideoCompositionDownload(
    composition: TwilioVideoComposition
  ): Observable<string> {
    return this.http.get<string>(
      `${this.endpointUrl}/${composition.id}/download`
    );
  }

  getTwilioVideoCompositionLink(
    composition: TwilioVideoComposition
  ): Observable<string> {
    return this.http.get<string>(`${this.endpointUrl}/${composition.id}/view`);
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
