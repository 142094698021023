import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoggerService } from './logger.service';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, map, take } from 'rxjs/operators';
import { MessageSet } from '../models/message-set.model';
import {
  getCurrentCustomer,
  UserState,
} from 'src/app/login/state/user.reducers';
import { Customer } from '../models/customer.model';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class MessageSetTemplateService {
  endpointUrl: string = environment.apiUrl + '/template-message-set';

  constructor(
    private logger: LoggerService,
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<UserState>
  ) {}

  getMessageSetTemplates() {
    let params = new HttpParams();

    let customer: Customer;

    this.store
      .select(getCurrentCustomer)
      .pipe(take(1))
      .subscribe((c: Customer) => (customer = c));

    params = params.set('customer', customer.id);
    return this.http
      .get<MessageSet[]>(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
