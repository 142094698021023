import { OrchestratorService } from './services/orchestrator.service';
import { MessageboxService } from '../manager/messages/messagebox/messagebox.service';
import { MessageRecipientService } from './services/message-recipient.service';
import { MessageSetService } from './services/message-set.service';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth-guard.service';
import { StaffOnlyGuard } from './guards/staff-only-guard.service';

import { LoggerService } from './services/logger.service';
import { PusherService, PusherServiceConfig } from './services/pusher.service';
import { GlobalPresenceService } from './services/global-presence.service';
import { CustomerPresenceService } from './services/customer-presence.service';
import { UserChannelService } from './services/user-channel.service';

import { CustomerService } from './services/customer.service';
import { SiteService } from './services/site.service';
import { CustomerAdminService } from './services/customer-admin.service';
import { StaffService } from './services/staff.service';
import { ResidentService } from './services/resident.service';
import { SupporterService } from './services/supporter.service';
import { ConferencingService } from './services/conferencing.service';

import { CustomerResolve } from './resolves/customer-resolve.service';
import { SiteResolve } from './resolves/site-resolve.service';
import { OnlineUserResolve } from './resolves/online-user-resolve.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MessageSetTemplateService } from './services/message-set-template.service';
import { NgxTranslateModule } from './ngx-translate.module';
import { TwilioVideoService } from './service/twilio-video.service';
import { MessageImageService } from './services/message-image.service';
import { MessageVideoService } from './services/message-video.service';
import { PreviousRouteService } from './services/previous-route.service';
import { CheckTakenValidator } from './validators/check-taken.validator';
import { CheckTakenService } from './services/check-taken.service';
import { HardwareService } from './services/hardware.service';
import { TwilioVideoRoomService } from './services/twilio-video-room.service';
import { TwilioVideoCompositionService } from './services/twilio-video-composition.service';
import { SitePresenceService } from './services/site-presence.service';

@NgModule({
  imports: [CommonModule, NgxWebstorageModule.forRoot(), NgxTranslateModule],
  providers: [
    CustomerResolve,
    SiteResolve,
    OnlineUserResolve,
    AuthService,
    AuthGuard,
    StaffOnlyGuard,
    LoggerService,
    PusherService,
    GlobalPresenceService,
    CustomerPresenceService,
    UserChannelService,
    CustomerService,
    SiteService,
    CustomerAdminService,
    ResidentService,
    SupporterService,
    StaffService,
    ConferencingService,
    // ConferencingMultipleService,
    MessageSetService,
    MessageRecipientService,
    MessageboxService,
    OrchestratorService,
    MessageSetTemplateService,
    TwilioVideoService,
    MessageImageService,
    MessageVideoService,
    PreviousRouteService,
    CheckTakenService,
    CheckTakenValidator,
    HardwareService,
    TwilioVideoRoomService,
    TwilioVideoCompositionService,
    SitePresenceService,
  ],
})
export class CoreModule {
  static forRoot(config: PusherServiceConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [{ provide: PusherServiceConfig, useValue: config }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
