import { createAction, props } from '@ngrx/store';
import { BaseUser } from '../core/models/base-user.model';
import { Customer } from '../core/models/customer.model';

import {
  CallOptions,
  CallType,
  ConferencingSubscription,
} from './conferencing.reducer';

export const subscribeConferencingChannel = createAction(
  '[Conferencing] Subscribe Conferencing Channel',
  props<{ customer: Customer; user: BaseUser }>()
);

export const subscribeConferencingChannelSuccess = createAction(
  '[Conferencing] Subscribe Conferencing Channel Success',
  props<{ subscription: ConferencingSubscription }>()
);

export const subscribeConferencingChannelFailure = createAction(
  '[Conferencing] Subscribe Conferencing Channel Failure',
  props<Error>()
);

export const subscribeConferencingRoomChannel = createAction(
  '[Conferencing Room Channel] Subscribe',
  props<{ customer: Customer }>()
);

export const subscribeConferencingRoomChannelSuccess = createAction(
  '[Conferencing Room Channel] Subscribe Success'
);

export const subscribeConferencingRoomChannelFailure = createAction(
  '[Conferencing Room Channel] Subscribe Failure'
);

export const subscribeConferencingCompositionChannel = createAction(
  '[Conferencing Composition Channel] Subscribe',
  props<{ customer: Customer }>()
);

export const subscribeConferencingCompositionChannelSuccess = createAction(
  '[Conferencing Composition Channel] Subscribe Success'
);

export const subscribeConferencingCompositionChannelFailure = createAction(
  '[Conferencing Composition Channel] Subscribe Failure'
);

// Outbound Actions
export const requestCall = createAction(
  '[Conferencing][Outbound] Request Call',
  props<{ callOptions: CallOptions }>()
);

export const acceptCall = createAction('[Conferencing][Outbound] Accept Call');

export const declineCall = createAction(
  '[Conferencing][Outbound] Decline Call'
);

export const endCall = createAction(
  '[Conferencing][Outbound] End Call',
  props<{ callType: CallType }>()
);

// Inbound Actions
export const callRequested = createAction(
  '[Conferencing] Call Requested',
  props<{ callOptions: CallOptions }>()
);

export const callAccepted = createAction('[Conferencing] Call Accepted');

export const callDeclined = createAction(
  '[Conferencing] Call Declined',
  props<{ callOptions: CallOptions }>()
);

export const callEnded = createAction('[Conferencing] Call Ended');

export const showCallViewer = createAction(
  '[Conferencing] Show Call Viewer',
  props<{ callType: CallType; remoteUser: BaseUser }>()
);

export const resetState = createAction('[Conferencing] Reset State');
