import { Model } from './base.model';


export class ReadCounts extends Model {
    pending: number = 0;
    delivered: number = 0;
    read: number = 0;
    revoked: number = 0;
    expired: number = 0;

    total(): number {
        return this.pending + this.delivered + this.read + this.revoked + this.expired;
    }

    reset() {
        this.pending = 0;
        this.delivered = 0;
        this.read = 0;
        this.revoked = 0;
        this.expired = 0;
    }
};
