import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Administrator } from '../models/administrator.model';
import { Resident } from '../models/resident.model';
import { Supporter } from '../models/supporter.model';
import { Staff } from '../models/staff.model';

import { StaffService } from '../services/staff.service';
import { CustomerAdminService } from '../services/customer-admin.service';
import { ResidentService } from '../services/resident.service';
import { SupporterService } from '../services/supporter.service';

@Injectable()
export class OnlineUserResolve
  implements Resolve<Administrator | Resident | Supporter | Staff | boolean>
{
  constructor(
    private router: Router,
    private staff: StaffService,
    private customerAdmin: CustomerAdminService,
    private resident: ResidentService,
    private supporter: SupporterService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Promise<Administrator | Resident | Supporter | Staff>
    | Observable<Administrator | Resident | Supporter | Staff>
    | boolean {
    let userType = route.params['userType'];
    let userId = +route.params['userId'];

    if (userType) {
      switch (userType) {
        case 'staff':
          return this.staff.getStaff(userId);
        case 'customeradmin':
          return this.customerAdmin.getAdministrator(userId);
        case 'resident':
          return this.resident.getResident(userId);
        case 'supporter':
          return this.supporter.getSupporter(userId);
      }
      return false;
    } else {
      //this.router.navigate(['/call/viewer/unknown']);
      return false;
    }
  }
}
