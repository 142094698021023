import { createAction, props } from '@ngrx/store';
import { Customer } from '../../../core/models/customer.model';
import { Administrator } from '../../../core/models/administrator.model';

export const getCustomers = createAction('[Customer] Get Customers');

export const getCustomersSuccess = createAction(
  '[Customer] Get Customers Success',
  props<{ customers: Customer[] }>()
);

export const getCustomersFailure = createAction(
  '[Customer] Get Customers Failure',
  props<{ error: any }>()
);

export const createCustomer = createAction(
  '[Customer] Create Customer',
  props<{ customer: Customer }>()
);

export const createCustomerSuccess = createAction(
  '[Customer] Create Customer Success',
  props<{ customer: Customer }>()
);

export const createCustomerFailure = createAction(
  '[Customer] Create Customer Failure',
  props<{ error: Error }>()
);

export const updateCustomer = createAction(
  '[Customer] Update Customer',
  props<{ customer: Customer }>()
);

export const updateCustomerSuccess = createAction(
  '[Customer] Update Customer Success',
  props<{ customer: Customer }>()
);

export const updateCustomerFailure = createAction(
  '[Customer] Update Customer Failure',
  props<{ error: Error }>()
);

export const deleteCustomer = createAction(
  '[Customer] Delete Customer',
  props<{ customer: Customer }>()
);

export const deleteCustomerSuccess = createAction(
  '[Customer] Delete Customer Success',
  props<{ customer: Customer }>()
);

export const deleteCustomerFailure = createAction(
  '[Customer] Delete Customer Failure',
  props<{ error: Error }>()
);

export const refreshCustomer = createAction(
  '[Customer] Refresh Customer',
  props<{ id: number }>()
);

export const refreshCustomerSuccess = createAction(
  '[Customer] Refresh Customer Success',
  props<{ customer: Customer }>()
);

export const refreshCustomerFailure = createAction(
  '[Customer] Refresh Customer Failure',
  props<{ error: Error }>()
);

export const createAdministrator = createAction(
  '[Customer] Create Administrator',
  props<{ administrator: Administrator }>()
);

export const createAdministratorSuccess = createAction(
  '[Customer] Create Administrator Success',
  props<{ administrator: Administrator }>()
);

export const createAdministratorFailure = createAction(
  '[Customer] Create Administrator Failure',
  props<{ error: any }>()
);
