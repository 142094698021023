import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Supporter } from '../models/supporter.model';
import { map, catchError } from 'rxjs/operators';
import { Customer } from '../models/customer.model';
import { Resident } from '../models/resident.model';

@Injectable()
export class SupporterService {
  endpointUrl: string = environment.apiUrl + '/supporter';

  constructor(private http: HttpClient) {}

  getSupporters(params?: HttpParams): Observable<Supporter[]> {
    return this.http
      .get(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getSupporter(supporterId) {
    return this.http.get<Supporter>(this.endpointUrl + '/' + supporterId);
  }

  getOnlineSupporters(customer: Customer): Observable<Supporter[]> {
    let params = new HttpParams();
    params = params.set('online', 'true');
    params = params.set('customer', customer.id);
    return this.http
      .get(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getSupportersForResident(id: number) {
    let params = new HttpParams();
    params = params.set('residents', id);
    return this.http
      .get(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
