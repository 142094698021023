import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HardwareService } from '../../../core/services/hardware.service';
import * as HardwareActions from './hardware.actions';
import { map } from 'rxjs/operators';

@Injectable()
export class HardwareEffects {
  constructor(
    private actions$: Actions,
    private hardwareService: HardwareService
  ) {}

  reload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HardwareActions.reload),
        map((action) => {
          this.hardwareService.reload(
            action.resident.set_top_box.id,
            action.force
          );
        })
      ),
    { dispatch: false }
  );

  reset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HardwareActions.reset),
        map((action) => {
          this.hardwareService.reset(
            action.resident.set_top_box.id,
            action.force
          );
        })
      ),
    { dispatch: false }
  );

  standby$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HardwareActions.standby),
        map((action) => {
          this.hardwareService.standby(
            action.resident.set_top_box.id,
            action.force
          );
        })
      ),
    { dispatch: false }
  );

  reboot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HardwareActions.reboot),
        map((action) => {
          this.hardwareService.reboot(
            action.resident.set_top_box.id,
            action.force
          );
        })
      ),
    { dispatch: false }
  );

  powerStandby$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HardwareActions.powerStandby),
        map((action) => {
          this.hardwareService.powerStandby(
            action.resident.set_top_box.id,
            action.force
          );
        })
      ),
    { dispatch: false }
  );

  powerNormal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HardwareActions.powerNormal),
        map((action) => {
          this.hardwareService.powerNormal(
            action.resident.set_top_box.id,
            action.force
          );
        })
      ),
    { dispatch: false }
  );

  powerWarm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HardwareActions.powerWarm),
        map((action) => {
          this.hardwareService.powerWarm(
            action.resident.set_top_box.id,
            action.force
          );
        })
      ),
    { dispatch: false }
  );
}
