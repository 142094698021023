<div id="media-library-upload-container">
  <mat-radio-group
    aria-label="Select private of public"
    [(ngModel)]="visibility"
    (change)="onVisibilityChanged()"
  >
    <mat-radio-button value="private" color="accent">
      {{ "COMPONENTS.MEDIA_LIBRARY.UPLOAD.VISIBILITY.PRIVATE" | translate }}
    </mat-radio-button>
    <mat-radio-button value="public" color="accent">
      {{ "COMPONENTS.MEDIA_LIBRARY.UPLOAD.VISIBILITY.PUBLIC" | translate }}
    </mat-radio-button>
    <mat-icon
      matTooltip="{{
        'COMPONENTS.MEDIA_LIBRARY.UPLOAD.VISIBILITY.TOOLTIP' | translate
      }}"
      >help</mat-icon
    >
  </mat-radio-group>

  <div div *ngIf="mediaType === 'photos'">
    <div
      id="drag-and-drop-container"
      ngFileDrop
      [options]="options"
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput"
    >
      <div id="placeholder-container">
        <div id="placeholder-label">
          {{
            "COMPONENTS.MEDIA_LIBRARY.UPLOAD.DROP_AREA" | translate | uppercase
          }}
        </div>
      </div>
    </div>

    <label id="upload-button">
      <input
        ngFileSelect
        type="file"
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
      />

      <input
        ngFileSelect
        type="file"
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
      />
      <mat-icon>file_upload</mat-icon>
      <span>{{
        "COMPONENTS.MEDIA_LIBRARY.UPLOAD.UPLOAD_BUTTON" | translate
      }}</span>
    </label>
  </div>

  <div *ngIf="mediaType === 'videos'">
    <div
      id="drag-and-drop-container"
      ngFileDrop
      [options]="options"
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput"
    >
      <div id="placeholder-container">
        <div id="placeholder-label">
          {{
            "COMPONENTS.MEDIA_LIBRARY.UPLOAD.DROP_AREA" | translate | uppercase
          }}
        </div>
      </div>
    </div>

    <label id="upload-button">
      <input
        ngFileSelect
        type="file"
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
      />

      <input
        ngFileSelect
        type="file"
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
      />
      <mat-icon>file_upload</mat-icon>
      <span>{{
        "COMPONENTS.MEDIA_LIBRARY.UPLOAD.UPLOAD_BUTTON" | translate
      }}</span>
    </label>
  </div>
  <div id="status-message-container">
    <p>{{ statusMessage }}</p>
  </div>
</div>
