<div class="image-upload-container">
  <div
    class="drag-and-drop-container"
    ngFileDrop
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
  >
    <div class="placeholder-container" *ngIf="!previewUrl || previewUrl === ''">
      <div class="placeholder-label">
        {{ "COMPONENTS.IMAGE_UPLOAD.DROP_TO_UPLOAD" | translate }}
      </div>
    </div>

    <div
      class="preview-container"
      *ngIf="previewUrl && previewUrl !== ''"
      [style.background-image]="previewUrl"
    ></div>
  </div>
  <label id="upload">
    <input
      type="file"
      ngFileSelect
      [options]="options"
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput"
    />
    <mat-icon>file_upload</mat-icon>
    <span>{{ "COMPONENTS.IMAGE_UPLOAD.UPLOAD_IMAGE" | translate }}</span>
  </label>
  <progress [value]="progress">{{ progress }}</progress>
</div>
