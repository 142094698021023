import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Hardware } from '../models/hardware.model';

@Injectable({
  providedIn: 'root',
})
export class HardwareService {
  endpointUrl: string = environment.apiUrl + '/set-top-box';

  constructor(private http: HttpClient) {}

  getHardware(id: number) {
    return this.http.get(`${this.endpointUrl}/${id}`);
  }

  createHardware(hardware: Hardware) {
    return this.http.post(this.endpointUrl, hardware);
  }

  updateHardware(hardware: Hardware) {
    return this.http.put(`${this.endpointUrl}/${hardware.id}`, hardware);
  }

  deleteHardware(id: number) {
    return this.http.delete(`${this.endpointUrl}/${id}`);
  }

  reboot(id: number, force: boolean) {
    return this.http
      .patch(`${this.endpointUrl}/${id}/reboot?force=${force}`, {})
      .subscribe();
  }

  powerStandby(id: number, force: boolean) {
    return this.http
      .patch(`${this.endpointUrl}/${id}/power_standby?force=${force}`, {})
      .subscribe();
  }

  powerNormal(id: number, force: boolean) {
    return this.http
      .patch(`${this.endpointUrl}/${id}/power_normal?force=${force}`, {})
      .subscribe();
  }

  powerWarm(id: number, force: boolean) {
    return this.http
      .patch(`${this.endpointUrl}/${id}/power_warm?force=${force}`, {})
      .subscribe();
  }

  standby(id: number, force: boolean) {
    return this.http
      .patch(`${this.endpointUrl}/${id}/standby?force=${force}`, {})
      .subscribe();
  }

  reload(id: number, force: boolean) {
    return this.http
      .patch(`${this.endpointUrl}/${id}/reload?force=${force}`, {})
      .subscribe();
  }

  reset(id: number, force: boolean) {
    return this.http
      .patch(`${this.endpointUrl}/${id}/reset?force=${force}`, {})
      .subscribe();
  }

  toggleLogging(id: number) {
    return this.http.patch(`${this.endpointUrl}/${id}/toggle_logging`, {});
  }

  isTaken(field: string, value: string) {
    const url = `${this.endpointUrl}/check_taken`;
    let params = new HttpParams();
    params = params.append('field', field);
    params = params.append('query', value);
    return this.http.get(url, { params });
  }
}
