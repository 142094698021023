import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CheckTakenService {
  checkUrl: string = environment.apiUrl.slice(0, -3) + '/core/check-taken/';

  constructor(private http: HttpClient) {}

  isTaken(field: string, value: string) {
    let params = new HttpParams();
    params = params.append('field', field);
    params = params.append('query', value);
    return this.http.get(this.checkUrl, { params });
  }
}
