import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { PusherService } from './pusher.service';
import { LoggerService } from './logger.service';
import { CustomerPresenceService } from './customer-presence.service';
import { UserState } from 'src/app/login/state/user.reducers';
import { Customer } from '../models/customer.model';
import { Store } from '@ngrx/store';
import { BaseUser } from '../models/base-user.model';
import * as ConferencingActions from '../../state/conferencing.actions';
import {
  CallOptions,
  ConferencingSubscription,
} from 'src/app/state/conferencing.reducer';

@Injectable()
export class ConferencingService {
  public callRequestedCallOptions: CallOptions; // Call options received via inbound call request

  private callRequestedSource = new Subject();
  private callAcceptedSource = new Subject();
  private callDeclinedSource = new Subject();
  private callEndedSource = new Subject();
  private rtcIceCandidateSource = new Subject();
  private rtcOfferSource = new Subject();
  private rtcAnswerSource = new Subject();

  callRequested$ = this.callRequestedSource.asObservable();
  callAccepted$ = this.callAcceptedSource.asObservable();
  callDeclined$ = this.callDeclinedSource.asObservable();
  callEnded$ = this.callEndedSource.asObservable();

  rtcIceCandidate$ = this.rtcIceCandidateSource.asObservable();
  rtcOffer$ = this.rtcOfferSource.asObservable();
  rtcAnswer$ = this.rtcAnswerSource.asObservable();

  constructor(
    private logger: LoggerService,
    private pusher: PusherService,
    private customerPresence: CustomerPresenceService,
    private store$: Store<UserState>
  ) {}

  subscribe(customer: Customer, user: BaseUser): void {
    const channelName = `private-call-${customer.id}`;
    const channel = this.pusher.subscribe(channelName);

    this.pusher.bindChannelEvent(
      channel,
      'pusher:subscription_succeeded',
      (data) => {
        this.logger.info(
          `Customer call channel subscription succeeded: ${channelName}`
        );
        const subscription: ConferencingSubscription = {
          userId: user.id,
          channelName,
        };
        this.store$.dispatch(
          ConferencingActions.subscribeConferencingChannelSuccess({
            subscription,
          })
        );
      }
    );

    this.pusher.bindChannelEvent(
      channel,
      'pusher:subscription_error',
      (error: Error) => {
        this.logger.error(
          'Customer call channel subscription FAILED: ' + channelName
        );
        this.logger.error(error.message);
        this.store$.dispatch(
          ConferencingActions.subscribeConferencingChannelFailure(error)
        );
      }
    );

    this.logger.debug(
      `Binding to call events with ${user.username} and id ${user.id}`
    );

    // Call requested incoming event
    const callRequestedEventName = `client-conferencing-call-requested-${user.id}`;
    this.logger.debug(`Listening on ${callRequestedEventName}`);
    this.pusher.bindChannelEvent(
      channel,
      callRequestedEventName,
      (callOptions: CallOptions) => {
        this.store$.dispatch(
          ConferencingActions.callRequested({ callOptions })
        );
      }
    );

    // Call accepted incoming event
    const callAcceptedEventName = `client-conferencing-call-accepted-${user.id}`;
    this.logger.debug(`Listening on ${callAcceptedEventName}`);
    this.pusher.bindChannelEvent(
      channel,
      callAcceptedEventName,
      (callOptions: CallOptions) => {
        this.logger.info('called ACCEPTED fired!');
        this.store$.dispatch(ConferencingActions.callAccepted());
      }
    );

    // Call declined incoming event
    const callDeclinedEventName = `client-conferencing-call-declined-${user.id}`;
    this.logger.debug(`Listening on ${callDeclinedEventName}`);
    this.pusher.bindChannelEvent(
      channel,
      callDeclinedEventName,
      (callOptions: CallOptions) => {
        this.logger.info('call DECLINED fired!');
        this.store$.dispatch(ConferencingActions.callDeclined({ callOptions }));
      }
    );

    // Call ended incoming event
    const callEndedEventName = `client-conferencing-call-ended-${user.id}`;
    this.logger.debug(`Listening on ${callEndedEventName}`);
    this.pusher.bindChannelEvent(
      channel,
      callEndedEventName,
      (callOptions: CallOptions) => {
        this.logger.info('call ENDED fired!');
        this.store$.dispatch(ConferencingActions.callEnded());
      }
    );
  }

  unsubscribe(channelName: string): void {
    this.pusher.unsubscribe(channelName);
  }

  /**
   * requestCall, acceptCall, declineCall, endCall are all
   * outbound notifications.
   */

  requestCall(channelName: string, callOptions: CallOptions): boolean {
    this.logger.info(`Requesting call with ${callOptions.target.username}`);
    const eventName = `client-conferencing-call-requested-${callOptions.target.id}`;
    this.logger.info(`eventName: ${eventName}`);
    const channel = this.pusher.channel(channelName);
    return channel.trigger(eventName, callOptions);
  }

  acceptCall(channelName: string, callOptions: CallOptions): boolean {
    this.logger.info(`Accepting call from ${callOptions.initiator.username}`);
    const eventName = `client-conferencing-call-accepted-${callOptions.initiator.id}`;
    this.logger.info(`eventName: ${eventName}`);
    const channel = this.pusher.channel(channelName);
    return channel.trigger(eventName, callOptions);
  }

  declineCall(channelName: string, inboundCallOptions: CallOptions): boolean {
    const callOptions = {
      initiator: inboundCallOptions.initiator,
      roomSid: inboundCallOptions.roomSid,
    };
    const channel = this.pusher.channel(channelName);
    return channel.trigger(
      'client-conferencing-call-declined-' + inboundCallOptions.initiator.id,
      callOptions
    );
  }

  endCall(channelName: string, callOptions: CallOptions): boolean {
    const channel = this.pusher.channel(channelName);
    return channel.trigger(
      'client-conferencing-call-ended-' + callOptions.target.id,
      callOptions
    );
  }
}
