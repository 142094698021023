<div id="media-library-image-upload">
  <!-- <mat-progress-bar
    mode="determinate"
    [value]="output.file.progress.data.percentage"
  ></mat-progress-bar> -->
  <mat-progress-spinner
    mode="determinate"
    [value]="output.file.progress.data.percentage"
  ></mat-progress-spinner>
  <!-- <img [src]="output.file.response?.large_url" alt="" /> -->
</div>
