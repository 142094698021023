import { LoggerService } from './logger.service';
import { Injectable, Optional } from '@angular/core';
import Pusher from 'pusher-js';

import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { UserState } from 'src/app/login/state/user.reducers';
import { Store } from '@ngrx/store';

export class PusherServiceConfig {
  enabled = false;
}

@Injectable()
export class PusherService {
  _enabled = false;
  pusher: any;
  currentState: 'disconnected';
  socketId: string;

  constructor(
    @Optional() config: PusherServiceConfig,
    private http: HttpClient,
    private authService: AuthService,
    private logger: LoggerService,
    private store: Store<UserState>
  ) {
    if (config) {
      this._enabled = config.enabled;
    }
  }

  public init(token: string): void {
    // let token: string;
    // this.store
    //   .select(getToken)
    //   .pipe(take(1))
    //   .subscribe((t) => (token = t));

    const pusherOptions = {
      cluster: 'eu',
      encrypted: true,
      authEndpoint: environment.apiUrl.slice(0, -3) + '/pusher/auth/',
      auth: {
        headers: {
          AUTHORIZATION: `Bearer ${token}`,
        },
      },
    };

    this.pusher = new Pusher(environment.pusherKey, pusherOptions);

    // Setup pusher
    this.pusher.connection.bind('state_change', (states) => {
      this.logger.info('pusher state: ' + states.current);
      this.currentState = states.current;
    });

    this.pusher.connection.bind('connected', () => {
      this.socketId = this.pusher.connection.socket_id;
      this.logger.info(`pusher connected! (${this.socketId})`);
    });
  }

  public disconnect(): void {
    this.pusher.disconnect();
  }

  public connectionBind(eventName, callback) {
    this.pusher.connection.bind(eventName, function() {
      const args = arguments;
      callback.apply(this.pusher, args);
    });
  }

  public subscribe(channelName): any {
    return this.pusher.subscribe(channelName);
  }

  public unsubscribe(channelName): void {
    if (
      this.pusher !== undefined &&
      this.pusher.allChannels().includes(channelName)
    ) {
      return this.pusher.unsubscribe(channelName);
    }
  }

  public unsubscribeAll(): void {
    this.pusher.channels.forEach((channel) => {
      this.pusher.unsubscribe(channel.name);
    });
  }

  public bindChannelEvent(channel, eventName, callback) {
    channel.bind(eventName, function() {
      const args = arguments;
      callback.apply(this.pusher, args);
    });
  }

  public bindAllChannelEvent(channel, callback) {
    channel.bind_global(function() {
      const args = arguments;
      callback.apply(this.pusher, args);
    });
  }

  public unbindChannelEvent(channel, eventName, callback) {
    channel.unbind(eventName, function() {
      const args = arguments;
      callback.apply(this.pusher, args);
    });
  }

  public channelUnbindAll(channel) {
    channel.unbind();
  }

  public channel(channelName) {
    return this.pusher.channel(channelName);
  }

  public allChannels() {
    return this.pusher.allChannels();
  }

  public getSocketId() {
    return this.pusher.connection.socket_id;
  }

  public getCurrentState() {
    return this.currentState;
  }
}
