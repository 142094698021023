import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { BaseUser } from '../core/models/base-user.model';

import * as PusherActions from './pusher.actions';
import * as PresenceActions from './presence.actions';
import { UserType } from '../core/models/user-type.model';
import { selectCurrentUser } from '../login/state/user.selectors';
import * as HardwareActions from '../manager/residents/state/hardware.actions';
import { Resident } from '../core/models/resident.model';

export const presenceFeatureKey = 'presence';

export interface PresenceContainer {
  users: BaseUser[] | Resident[];
  channelName: string;
}

export interface PresenceState {
  global: PresenceContainer;
  customer: PresenceContainer;
}

const globlPresence: PresenceContainer = {
  users: [],
  channelName: '',
};

const customerPresence: PresenceContainer = {
  users: [],
  channelName: '',
};

const initialState: PresenceState = {
  global: globlPresence,
  customer: customerPresence,
};

export const presenceReducer = createReducer<PresenceState>(
  initialState,
  on(
    PusherActions.subscribeGlobalChannelSuccess,
    (state: PresenceState, action) => {
      return {
        ...state,
        global: { ...state.global, channelName: action.channelName },
      };
    }
  ),
  on(PresenceActions.setGlobalUsers, (state: PresenceState, props) => {
    return { ...state, global: { ...state.global, users: props.users } };
  }),
  on(
    PresenceActions.globalUserOnline,
    (state: PresenceState, user: BaseUser) => {
      const users = [...state.global.users];
      const idx = state.global.users.findIndex((u) => u.id === user.id);
      if (idx !== -1) {
        users[idx] = user;
      } else {
        users.push(user);
      }
      return {
        ...state,
        global: { ...state.global, users },
      };
    }
  ),
  on(
    PresenceActions.globalUserOffline,
    (state: PresenceState, user: BaseUser) => {
      return {
        ...state,
        global: {
          ...state.global,
          users: state.global.users.filter((u) => u.id !== user.id),
        },
      };
    }
  ),
  on(
    PusherActions.subscribeCustomerChannelSuccess,
    (state: PresenceState, action) => {
      return {
        ...state,
        customer: { ...state.customer, channelName: action.channelName },
      };
    }
  ),
  on(
    PresenceActions.getOnlineCustomerUsersSuccess,
    (state: PresenceState, props) => {
      return {
        ...state,
        customer: {
          ...state.customer,
          users: [...state.customer.users, ...props.users],
        },
      };
    }
  ),
  on(PresenceActions.customerUserOnline, (state: PresenceState, user) => {
    const idx = state.customer.users.findIndex((u) => u.id === user.id);
    let users = [...state.customer.users];
    if (idx !== -1) {
      users[idx] = user;
    } else {
      users = [...users, user];
    }
    return {
      ...state,
      customer: { ...state.customer, users },
    };
  }),
  on(PresenceActions.customerUserOffline, (state: PresenceState, user) => {
    return {
      ...state,
      customer: {
        ...state.customer,
        users: state.customer.users.filter((u) => u.id !== user.id),
      },
    };
  }),
  on(HardwareActions.updateHardware, (state, action) => {
    const residentId: number = action.hardware.resident as number;
    const idx = state.customer.users.findIndex(
      (user) => user.id === residentId
    );
    const users = [...state.customer.users];
    if (idx !== -1) {
      users[idx] = { ...users[idx], set_top_box: action.hardware } as Resident;
      return { ...state, customer: { ...state.customer, users } };
    } else {
      return { ...state };
    }
  })
);

// selectors

const selectPresenceFeatureState =
  createFeatureSelector<PresenceState>(presenceFeatureKey);

// global
export const selectGlobalPresenceStaff = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'staff')
);

export const selectGlobalPresenceAdmin = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'customer_admin')
);

export const selectGlobalPresenceResident = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'resident')
);

export const selectGlobalPresenceSupporter = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'supporter')
);

export const selectGlobalPresence = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users
);

export const selectGlobalPresenceStaffCount = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'staff').length
);

export const selectGlobalPresenceAdminCount = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.global.users.filter((u) => u.user_type === 'customer_admin').length
);

export const selectGlobalPresenceResidentCount = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'resident').length
);

export const selectGlobalPresenceSupporterCount = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.global.users.filter((u) => u.user_type === 'supporter').length
);

export const selectGlobalPresenceCount = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.length
);

// customer

export const selectCustomerPresenceAdmin = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.customer.users.filter((u) => u.user_type === 'customer_admin')
);

export const selectCustomerPresenceResident = createSelector(
  selectPresenceFeatureState,
  (state) => state.customer.users.filter((u) => u.user_type === 'resident')
);

export const selectCustomerPresenceSupporter = createSelector(
  selectPresenceFeatureState,
  (state) => state.customer.users.filter((u) => u.user_type === 'supporter')
);

export const selectCustomerPresence = createSelector(
  selectPresenceFeatureState,
  (state) => state.customer.users
);

export const selectCustomerPresenceAdminsAndResidents = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.customer.users.filter(
      (u) =>
        u.user_type === UserType.CUSTOMER_ADMIN ||
        u.user_type === UserType.RESIDENT
    )
);

export const selectAvailableConferencingUsers = createSelector(
  selectCurrentUser,
  selectPresenceFeatureState,
  (currentUser, state) =>
    state.customer.users.filter(
      (u) =>
        (u.user_type === UserType.CUSTOMER_ADMIN ||
          u.user_type === UserType.RESIDENT) &&
        u.id !== currentUser.id
    )
);

export const selectAvailableConferencingResidents = createSelector(
  selectCurrentUser,
  selectPresenceFeatureState,
  (currentUser, state) =>
    state.customer.users.filter(
      (u) => u.user_type === UserType.RESIDENT && u.id !== currentUser.id
    ) as Resident[]
);

export const selectCustomerPresenceStaffCount = createSelector(
  selectPresenceFeatureState,
  (state) => state.customer.users.filter((u) => u.user_type === 'staff').length
);

export const selectCustomerPresenceAdminCount = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.customer.users.filter((u) => u.user_type === 'customer_admin').length
);

export const selectCustomerPresenceResidentCount = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.customer.users.filter((u) => u.user_type === 'resident').length
);

export const selectCustomerPresenceSupporterCount = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.customer.users.filter((u) => u.user_type === 'supporter').length
);

export const selectCustomerPresenceCount = createSelector(
  selectPresenceFeatureState,
  (state) => state.customer.users.length
);

export const isCustomerUserOnline = (user: BaseUser) =>
  createSelector(selectPresenceFeatureState, (state) => {
    if (user === null || user === undefined) {
      return false;
    }
    const found = state.customer.users.find((u) => u.id === user.id);
    return found !== undefined;
  });

export const selectCustomerUser = (userId: number) =>
  createSelector(selectPresenceFeatureState, (state) => {
    const found = state.customer.users.find((u) => u.id === userId);
    return found !== undefined ? found : null;
  });
